import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { IIncident } from '../../../../../@types/model/incident';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IncidentFunctions from '../../../../../store/incident/functions';
import moment from 'moment';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker';
import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import { v4 } from 'uuid';
import { IFileUpload } from '../../../../../@types/viewmodel/file';
import MultiFileDropZone from '../../../custom/file/MultiFileDropZone';

interface IIncidentUpdateEditDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    index : number;
}

interface IIncidentUpdateEditDialogState {
    open : boolean;

    comment : string;
    date : moment.Moment;
    files : Array<IFileUpload>;
}

export default class IncidentUpdateEditDialog extends React.Component<IIncidentUpdateEditDialogProps, IIncidentUpdateEditDialogState> {
    constructor(props : IIncidentUpdateEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            comment: '',
            date: moment(),
            files: [],
        };
    }

    public componentDidUpdate = (prevProps : IIncidentUpdateEditDialogProps, prevState : IIncidentUpdateEditDialogState) => {
        if (!prevState.open && this.state.open) {
            const update = this.props.incident.updates[this.props.index];
            this.setState({
                comment: update?.comment ?? '',
                date: update ? moment.utc(update.date).local() : moment(),
                files: [],
            });
        }
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private save = async () => {
        const { index, incident } = this.props;
        const { comment, date, files } = this.state;

        const result = await IncidentFunctions.saveUpdate({
            comment,
            date: moment(date).utc().toISOString(),
            files,
            guid: incident.updates[index]?.guid ?? v4(),
            incidentGuid: incident.guid,
            incidentId: incident.id,
            isActive: incident.updates[index]?.isActive ?? true,
        });

        if (result) {
            this.onClose();
        }
    }

    private onEditClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({
            open: true,
        });
    }

    private onCommentChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            comment: event.currentTarget.value,
        });
    }

    private onDateChange = (momentDate : moment.Moment) => {
        this.setState({
            date: moment(momentDate) ,
        });
    }

    private onFileSelected = (file : IFileUpload) => {
        const files = this.state.files.slice();

        const index = files.findIndex(n => n.name === file.name);

        if (index > -1) {
            files.splice(index, 1, file);
        } else {
            files.push(file);
        }

        this.setState({
            files,
        });
    }

    private onFileRemove = (file : IFileUpload) => {
        const files = this.state.files.slice();

        const index = files.findIndex(n => n.name === file.name);

        if (index > -1) {
            files.splice(index, 1);
        }

        this.setState({
            files,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, index } = this.props;
        const { open, comment, date, files } = this.state;
        return (
        <React.Fragment>
            <Tooltip title={index === -1 ? 'Add' : 'Edit'}>
                <div>
                    <IconButton color={index === -1 ? 'secondary' : 'primary'} size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>{index === -1 ? 'add' : 'edit'}</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='update-info-title'
                aria-describedby='update-info-description'
                className='hfill'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Update Info
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 hfill'>
                    <div className='fdc'>
                        <div className='fdr aifs'>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <KeyboardDateTimePicker disabled={isLoading} fullWidth value={date} onChange={this.onDateChange} format='YYYY-MM-DD HH:mm' label='Date' />
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            </div>
                        </div>
                        <div className='flx1 fdr ais'>
                            <div className={'flx2 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                    <InputLabel required error={!comment} htmlFor='comment-input'>Description</InputLabel>
                                    <Input
                                        id='comment-input'
                                        value={comment}
                                        onChange={this.onCommentChange}
                                        aria-describedby='comment-input-helper-text'
                                        required
                                        multiline
                                        disabled={isLoading}
                                        error={!comment}
                                    />
                                    {
                                        !comment &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                        </div>
                        <div className='fdr aifs'>
                            <div className={'flx1'}>
                                <MultiFileDropZone accept='audio/*,video/*,image/*,application/pdf' files={files}
                                onFileSelected={this.onFileSelected} onFileRemoved={this.onFileRemove}
                                disabled={isLoading}/>
                            </div>
                        </div>
                    </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading || (!date || !comment)} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>);
    }
}
