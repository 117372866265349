import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions, Typography } from '@material-ui/core';
import { IGraveSite } from '../../../../@types/model/graveSite/graveSite';
import { IGrave } from '../../../../@types/model/graveSite/grave';
import StadiumButton from '../../custom/button/StadiumButton';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import DocumentFileUpload from '../../custom/file/DocumentUpload';
import GraveSiteFunctions from '../../../../store/graveSites/functions';
import GeneralFunctions from '../../../../store/general/functions';

interface IGraveSiteFileUploadProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    open : boolean;
    grave : IGrave;
    graveSite : IGraveSite;

    onClose? : () => void;
}

interface IGraveSiteFileUploadState {
    initialApplications : Array<IFileUpload>;
    visitAgreements : Array<IFileUpload>;
    tombstoneAgreement : Array<IFileUpload>;
    burialAgreement : Array<IFileUpload>;
}

export default class GraveSiteFileUpload extends React.PureComponent<IGraveSiteFileUploadProps, IGraveSiteFileUploadState> {
    constructor(props : IGraveSiteFileUploadProps) {
        super(props);
        this.state = {
            initialApplications: [],
            visitAgreements: [],
            tombstoneAgreement: [],
            burialAgreement: [],
        };
    }

    private readonly onInitialFileSelected = (files : Array<IFileUpload>) => {
        this.setState({
            initialApplications: [...files],
        });
    }

    private readonly onVisitFileSelected = (files : Array<IFileUpload>) => {
        this.setState({
            visitAgreements: [...files],
        });
    }

    private readonly onTombstoneFileSelected = (files : Array<IFileUpload>) => {
        this.setState({
            tombstoneAgreement: [...files],
        });
    }

    private readonly onBurialFileSelected = (files : Array<IFileUpload>) => {
        this.setState({
            burialAgreement: [...files],
        });
    }

    private readonly queueFiles = async () => {
        const { grave, graveSite } = this.props;
        const { initialApplications, visitAgreements, tombstoneAgreement, burialAgreement } = this.state;
        await Promise.all([
            GraveSiteFunctions.uploadInitialGraveDocuments(
                graveSite,
                grave,
                initialApplications,
            ),
            GraveSiteFunctions.uploadBurialGraveDocuments(
                graveSite,
                grave,
                burialAgreement,
            ),
            GraveSiteFunctions.uploadTombstoneGraveDocuments(
                graveSite,
                grave,
                tombstoneAgreement,
            ),
            GraveSiteFunctions.uploadVisitGraveDocuments(
                graveSite,
                grave,
                visitAgreements,
            ),
        ]);

        GeneralFunctions.showSuccessSnackbar('Files are being uploaded.');

        this.onClose();
    }

    private readonly onUploadClick = () => {
        this.queueFiles();
    }

    private readonly onClose = () => {
        this.setState({
            initialApplications: [],
            visitAgreements: [],
            tombstoneAgreement: [],
            burialAgreement: [],
        });

        if (this.props.onClose) {
            this.props.onClose();
        }

    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, open } = this.props;
        const { initialApplications, visitAgreements, tombstoneAgreement, burialAgreement } = this.state;
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='grave-site-file-upload-dialog-title'
                aria-describedby='grave-site-file-upload-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h6' color='inherit' className='flx1'>
                            UPLOAD DOCUMENTS
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className='fdr'>
                        <div className='flx1 h256 m10'>
                            <DocumentFileUpload
                                accept='image/*,application/pdf'
                                value={initialApplications}
                                onFileChange={this.onInitialFileSelected}
                                label='Initial Application'
                            />
                        </div>
                        <div className='flx1 h256 m10'>
                            <DocumentFileUpload
                                accept='image/*,application/pdf'
                                value={visitAgreements}
                                onFileChange={this.onVisitFileSelected}
                                label='Grave Visit Agreement'
                            />
                        </div>
                        <div className='flx1 h256 m10'>
                            <DocumentFileUpload
                                accept='image/*,application/pdf'
                                value={tombstoneAgreement}
                                onFileChange={this.onTombstoneFileSelected}
                                label='Tombstone Erection Agreement'
                            />
                        </div>
                        <div className='flx1 h256 m10'>
                            <DocumentFileUpload
                                accept='image/*,application/pdf'
                                value={burialAgreement}
                                onFileChange={this.onBurialFileSelected}
                                label='Burial Agreement'
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StadiumButton
                        icon={(
                            <Icon className={'cp'}>
                                upload
                            </Icon>
                        )}
                        onClick={this.onUploadClick}
                    >
                        <Typography component='div' className={'cp fs16 fwb'}>
                            UPLOAD NEW DOCUMENT
                        </Typography>
                    </StadiumButton>
                </DialogActions>
            </Dialog>
        );
    }
}
