import { Typography } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import React from 'react';
import { IGraveFile } from '../../../../@types/model/graveSite/grave';
import FileCarouselDialog from '../../custom/dialog/FileCarouselDialog';

interface IGraveInfoDocumentProps {
    title : string;
    files : Array<IGraveFile>;
}

interface IGraveInfoDocumentState {
    fileIndex? : number;
}

export default class GraveInfoDocument extends React.PureComponent<IGraveInfoDocumentProps, IGraveInfoDocumentState> {
    constructor(props : IGraveInfoDocumentProps) {
        super(props);
        this.state = {};
    }

    private readonly onOpenFileCarousel = () => {
        const { files } = this.props;

        if (!files.length) return;
        this.setState({
            fileIndex: 0,
        });
    }

    private readonly onImageFileCarouselClose = () => {
        this.setState({
            fileIndex: undefined,
        });
    }

    public readonly render = () => {
        const { fileIndex } = this.state;
        const { title, files } = this.props;
        return (
            <div className='fdc'>
                <div className={`fdr aic ${!files.length ? '' : 'cursorPointer'}`} onClick={this.onOpenFileCarousel}>
                    <Typography className={'fs16 cg2'}>
                        {title}
                    </Typography>
                    {
                        !!files.length &&
                        <AttachmentIcon className='ml15' />
                    }
                </div>
                <FileCarouselDialog
                    fullWidth
                    maxWidth='md'
                    selectedIndex={fileIndex}
                    files={files}
                    open={typeof fileIndex !== 'undefined'}
                    onClose={this.onImageFileCarouselClose} />
            </div>
        );
    }
}
