import { IDivision } from '../../@types/model/division';
import { IRegion } from '../../@types/model/region';
import { MasterDataAction } from '../../@types/redux';
import { getType } from 'typesafe-actions';
import masterDataActions from './actions';
import { IGraveVisitReason } from '../../@types/model/masterData/graveVisitReason';
import { IAccessPointCategory } from '../../@types/model/masterData/accessPointCategory';
import { IPowerSource } from '../../@types/model/masterData/powerSource';

export interface IMasterDataState {
    isLoadingDivisions : boolean;
    divisions : Array<IDivision>;
    isLoadingRegions : boolean;
    regions : Array<IRegion>;
    isLoadingGraveVisitReason : boolean;
    graveVisitReasons : Array<IGraveVisitReason>;
    isLoadingAccessPointCategory : boolean;
    accessPointCategories : Array<IAccessPointCategory>;
    isLoadingDivisionTypes : boolean;
    divisionTypes : Array<IAccessPointCategory>;
    isLoadingPowerSource : boolean;
    powerSources : Array<IPowerSource>;
}

const initialState : IMasterDataState = {
    isLoadingDivisions: false,
    divisions: [],
    isLoadingRegions: false,
    regions: [],
    isLoadingGraveVisitReason: false,
    graveVisitReasons: [],
    isLoadingAccessPointCategory: false,
    accessPointCategories: [],
    isLoadingDivisionTypes: false,
    divisionTypes: [],
    isLoadingPowerSource: false,
    powerSources: [],
};

export default function dataReducer(state : IMasterDataState = initialState, action : MasterDataAction) : IMasterDataState {
    switch (action.type) {
        case getType(masterDataActions.setIsLoadingDivisions):
            return { ...state, isLoadingDivisions: action.payload };
        case getType(masterDataActions.setDivisions):
            return { ...state, divisions: action.payload };
        case getType(masterDataActions.setIsLoadingRegions):
            return { ...state, isLoadingRegions: action.payload };
        case getType(masterDataActions.setRegions):
            return { ...state, regions: action.payload };
        case getType(masterDataActions.setIsLoadingGraveVisitReason):
            return { ...state, isLoadingGraveVisitReason: action.payload };
        case getType(masterDataActions.setGraveVisitReasons):
            return { ...state, graveVisitReasons: action.payload };
        case getType(masterDataActions.setIsLoadingAccessPointCategory):
            return { ...state, isLoadingAccessPointCategory: action.payload };
        case getType(masterDataActions.setAccessPointCategories):
            return { ...state, accessPointCategories: action.payload };
        case getType(masterDataActions.setIsLoadingDivisionTypes):
            return { ...state, isLoadingDivisionTypes: action.payload };
        case getType(masterDataActions.setDivisionTypes):
            return { ...state, divisionTypes: action.payload };
        case getType(masterDataActions.setIsLoadingPowerSource):
            return { ...state, isLoadingPowerSource: action.payload };
        case getType(masterDataActions.setPowerSources):
            return { ...state, powerSources: action.payload };
        default:
            return state;
    }
}
