import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { IIncidentCategory } from '../../../../@types/model/incident';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import DataFunctions from '../../../../store/data/functions';
import { LinearProgress } from '@material-ui/core';

interface IIncidentCategoryDropdownProps {
    id? : string;
    value? : string | number;
    label? : string;

    onChange? : (value? : IIncidentCategory) => void;

    required? : boolean;
    clearable? : boolean;
    autoFocus? : boolean;

    fullWidth? : boolean;
    disabled? : boolean;

    incidentCategories : Array<IIncidentCategory>;
    isLoading : boolean;
}

interface IIncidentCategoryDropdownState {
}

class IncidentCategoryDropdownComponent extends React.Component<IIncidentCategoryDropdownProps, IIncidentCategoryDropdownState> {
    constructor(props : IIncidentCategoryDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = () => {
        DataFunctions.getAllIncidentCategories();
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : string,
        id : number,
    } | null) => {
        if (!this.props.onChange) return;

        const result = this.props.incidentCategories
            .find(category => category.guid === value?.value);

        this.props.onChange(result);
    }

    private getIncidentCategorys = (props : IIncidentCategoryDropdownProps) => props.incidentCategories;
    private getValue = (props : IIncidentCategoryDropdownProps) => props.value;
    private getRequired = (props : IIncidentCategoryDropdownProps) => props.required;

    private getActiveIncidentCategorys = createSelector([
        this.getIncidentCategorys,
    ], incidentCategories => incidentCategories.filter(x => x.isActive));

    private getDropdown = createSelector([
        this.getActiveIncidentCategorys,
        this.getRequired,
    ], (incidentCategories, required) => {
        const result = lodash.map(incidentCategories, (n, k) => ({ label: `${n.name}`, value: n.guid, id: n.id }));

        if (!required) {
            result.unshift({
                label: 'ALL',
                value: '',
                id: 0,
            });
        }

        return result;
    });

    private getSelectedValue = createSelector([
        this.getValue,
        this.getDropdown,
        this.getRequired,
    ], (value, dropdown, required) => {
        if (typeof value === 'string') {
            return dropdown.find(x => x.value === value);
        }

        if (typeof value === 'number') {
            return dropdown.find(x => x.id === value);
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
                id: 0,
            };
        }

        return null;
    });

    public render = () => {
        const { required,  fullWidth, disabled, id, clearable, label, isLoading } = this.props;

        const dropdown = this.getDropdown(this.props);

        const value = this.getSelectedValue(this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    openOnFocus
                    disabled={disabled || isLoading}
                    id={id}
                    options={dropdown}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required && !clearable}
                    renderTags={s => (<span className='fs16 lh21 cpla lsp024'>{s.map(n => n.label).join(', ')}</span>)}
                    renderInput={params => <TextField maxRows={1} error={required && !value} {...params}
                        fullWidth={fullWidth} label={label ?? 'Incident  Category'} InputLabelProps={{
                            className: 'fs16 lh21 cpla lsp024',
                        }} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                {
                    isLoading &&
                    <LinearProgress className='wfill posb0 posa' />
                }
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        incidentCategories: state.data.incidentCategories,
        isLoading: state.data.isLoadingIncidentCategories,
    };
};

// tslint:disable-next-line: variable-name
const IncidentCategoryDropdown = connect(
    mapStateToProps,
)(IncidentCategoryDropdownComponent);

export default IncidentCategoryDropdown;
