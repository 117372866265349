import React from 'react';
import { IMappedRight } from '../../../../../@types/model/right';

interface IRightsChildProps {
    selected : boolean;
    right : IMappedRight;
    onClicked : (right : IMappedRight, checked : boolean) => void;
}

class RightChild extends React.Component<IRightsChildProps> {
    public render() {
        const { selected, right, onClicked } = this.props;
        return (
            <div className={'right-child'} onClick={ev => onClicked(right, !!!selected)}>
                <span className={`${!!!selected ? '' : 'selected'}`}>{right.name}</span>
            </div>
        );
    }
}

export default RightChild;
