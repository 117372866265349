import React from 'react';
import { IDivision } from '../../../@types/model/division';
import { IRootState, RootAction } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import MasterDataFunctions from '../../../store/masterData/functions';
import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import { Transitions } from '../custom/animations/Transitions';
import moment from 'moment';
import EditDivisionDialog from './dialog/Edit';
import Fab from '@material-ui/core/Fab';
import { createSelector } from 'reselect';
import MaterialTable from '../custom/materialTable/Table';

interface IDivisionListProps {
    isLoading : boolean;
    divisions : Array<IDivision>;
}

interface IDivisionListState {
    showAll : boolean;
}

class DivisionList extends React.Component<IDivisionListProps, IDivisionListState> {
    constructor(props : IDivisionListProps) {
        super(props);
        this.state = {
            showAll: false,
        };
    }

    public componentDidMount = () => {
        MasterDataFunctions.getAllDivisions();
    }

    public onRefreshClick = () => {
        MasterDataFunctions.getAllDivisions(true);
    }

    public onPublishAllClick = () => {
        MasterDataFunctions.publishAllDivisions();
    }

    private getDivisions = (props : IDivisionListProps, state : IDivisionListState) => props.divisions;
    private getShowAll = (props : IDivisionListProps, state : IDivisionListState) => state.showAll;

    public getFilteredData = createSelector(
        [this.getDivisions, this.getShowAll],
        (divisions, showAll) => {
            return divisions
            .filter(n => showAll || n.isActive);
        },
    );

    private onShowOnlyActiveClick = () => {
        this.setState({
            showAll: !this.state.showAll,
        });
    }

    public render = () => {
        const { showAll } = this.state;
        const { isLoading } = this.props;

        const divisions = this.getFilteredData(this.props, this.state);

        return (
            <div className={'flx1 fdc p5'}>
                <Card className={'flx1 fdc mb70'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            Divisions
                        </Typography>
                        <IconButton color='primary' onClick={this.onRefreshClick}>
                            <Tooltip title='Refresh'>
                                <Icon>
                                    refresh
                                </Icon>
                            </Tooltip>
                        </IconButton>
                        <span className={'flx1'} />
                        <div className='mr15'>
                            <Tooltip title='Publish All'>
                                <div>
                                    <Fab color='primary' size='small' aria-label='Publish All' disabled={isLoading} onClick={this.onPublishAllClick}>
                                        <PublishIcon />
                                    </Fab>
                                </div>
                            </Tooltip>
                        </div>
                        <Tooltip title='Show only Active'>
                            <Fab color={!showAll ? 'primary' : 'default'} size='small' onClick={this.onShowOnlyActiveClick}>
                                <Icon>
                                    <img src={`${ASSET_BASE}/assets/images/icons/active.svg`} />
                                </Icon>
                            </Fab>
                        </Tooltip>
                    </Toolbar>
                    <MaterialTable<IDivision>
                        id={'divisionsTable'}
                        data={divisions}
                        isLoading={isLoading}
                        rowsPerPage={100}
                        columns={[
                            {
                                header: '',
                                width: '60px',
                                renderCell: row => (
                                    <div className='aic'>
                                        <EditDivisionDialog division={row} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} />
                                    </div>
                                ),
                            },
                            {
                                header: 'Code',
                                field: 'code',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Description',
                                field: 'description',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => row.description.toLocaleUpperCase(),
                            },
                            {
                                header: 'Types',
                                field: 'divisionTypes',
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <span>
                                    {
                                        row.divisionTypes?.filter(x => x.isActive)
                                            .map(x => x.divisionType.description)
                                            .join(', ') ?? ''
                                    }
                                    </span>),
                            },
                            {
                                header: 'Region',
                                field: 'regionName',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Province',
                                field: 'province',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Active',
                                field: 'isActive',
                                enableSort: true,
                                renderCell: row => (<Icon className={`${row.isActive ? 'cp' : 'cpr'}`}>{row.isActive ? 'check' : 'close' }</Icon>),
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={moment.utc(row.createdOn).local().format('YYYY-MM-DD HH:mm')}>
                                        <span>{row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                            {
                                header: 'Updated By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={moment.utc(row.updatedOn).local().format('YYYY-MM-DD HH:mm')}>
                                        <span>{row.updatedByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                        ]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <EditDivisionDialog transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        divisions: state.masterData.divisions,
        isLoading: state.masterData.isLoadingDivisions,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => {
    return bindActionCreators({}, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DivisionList);
