import { getType } from 'typesafe-actions';
import { GeneralAction, ISnackbarNotification } from '../../@types/redux';
import generalActions from '../../store/general/actions';
import { IWebNotification } from '../../@types/notification';

export interface IGeneralState {
    company : string;
    snakcbarNotifications : Array<ISnackbarNotification>;
    webNotifications : Array<IWebNotification>;
    isLoadingMap : boolean;
}

const initialState : IGeneralState = {
    company: '',
    snakcbarNotifications: [],
    webNotifications: [
    ],
    isLoadingMap : true,
};

export default function generalReducer(state : IGeneralState = initialState, action : GeneralAction) : IGeneralState {
    switch (action.type) {
        case getType(generalActions.enqueueSnackbar):
            return { ...state, snakcbarNotifications: [...state.snakcbarNotifications, { ...action.payload }] };
        case getType(generalActions.removeSnackbar):
            return {
                ...state,
                snakcbarNotifications: state.snakcbarNotifications.filter(notification => notification.key !== action.payload),
            };
        case getType(generalActions.enqueueWebNotification):
            return { ...state, webNotifications: [{ ...action.payload }, ...state.webNotifications] };
        case getType(generalActions.removeWebNotification):
            return {
                ...state,
                webNotifications: state.webNotifications.filter(notification => notification.key !== action.payload),
            };
        case getType(generalActions.setIsLoadingMap):
            return {
                ...state,
                isLoadingMap: action.payload,
            };
        default:
            return state;
    }
}
