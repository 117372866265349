import { createAction } from 'typesafe-actions';
import { IGraveSite } from '../../@types/model/graveSite/graveSite';

const graveSiteActions = {
    setGraveSites: createAction('DATA_SET_GRAVESITES', resolve => (graveSites : Array<IGraveSite>) => resolve(graveSites)),
    setGraveSite: createAction('DATA_SET_GRAVESITE', resolve => (graveSites? : IGraveSite) => resolve(graveSites)),
    setLoadingGraveSites: createAction('DATA_SET_GRAVESITES_LOADING', resolve => (isLoading : boolean) => resolve(isLoading)),
};

export default graveSiteActions;
