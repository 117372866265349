import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';

interface INotificationMapVerticalExpanderProps {
    initiallyExpanded? : boolean;
    title : string;
    subTitle? : string;
    className? : string | undefined;

    location? : 'left' | 'right';

    disabled? : boolean;
}

interface INotificationMapVerticalExpanderState {
    collapsed : boolean;
}

class NotificationMapVerticalExpander extends React.Component<INotificationMapVerticalExpanderProps, INotificationMapVerticalExpanderState> {

    constructor(props : INotificationMapVerticalExpanderProps) {
        super(props);

        this.state = {
            collapsed: !!props.disabled || !!!props.initiallyExpanded,
        };
    }

    public componentDidUpdate = (prevProps : INotificationMapVerticalExpanderProps) => {
        if (prevProps.disabled && !this.props.disabled && this.state.collapsed) {
            this.setState({
                collapsed: false,
            });
        }
    }

    public onToggleExpander = () => {
        this.setState({
            collapsed: !this.props.disabled && !this.state.collapsed,
        });
    }

    public render = () => {
        const collapsed = this.state.collapsed || !!this.props.disabled;
        const { title, subTitle, children, className, location, disabled } = this.props;

        // TODO: This looks weird, ask Steffen what to do...
        return (
            <div className={`fdc flx1 mh0 ${className}`}>
                <motion.div animate={{ width: !collapsed ? 300 : 40 }} transition={{ type: 'tween' }} className='fdc flx1' key={`expander_${title}`}>
                {
                    collapsed &&
                    <div className='flx1' />
                }
                <Typography className={`pl10 pr10 aic fdr rbt pt10 ${collapsed ? 'VerticalText' : ''} fs18`} variant={'subtitle1'}>
                    {title.toUpperCase()}{!subTitle ? '' : ` - ${subTitle.toUpperCase()}`}
                </Typography>
                {
                    collapsed &&
                    <IconButton disabled={disabled} color='inherit' style={{ height: 40, width: 40, paddingTop: 8 }} onClick={this.onToggleExpander}>
                        {
                            location === 'left' &&
                            <Icon>chevron_right</Icon>
                        }
                        {
                            location !== 'left' &&
                            <Icon>chevron_left</Icon>
                        }
                    </IconButton>
                }
                {
                    !collapsed &&
                    children
                }
                {
                    !collapsed &&
                    <div className='fdr'>
                        {
                            location === 'left' &&
                            <span className='flx1' />
                        }
                        <IconButton disabled={disabled} color='inherit' onClick={this.onToggleExpander}>
                            {
                                location === 'left' &&
                                <Icon>chevron_left</Icon>
                            }
                            {
                                location !== 'left' &&
                                <Icon>chevron_right</Icon>
                            }
                        </IconButton>
                        {
                            location === 'right' &&
                            <span className={'flx1'}/>
                        }
                    </div>
                }
                </motion.div>
            </div>
        );
    }
}

export default NotificationMapVerticalExpander;
