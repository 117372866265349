import React from 'react';
import { IAccessPoint } from '../../../../@types/model/access';
import AccessPointEventList from '../list/AccessPointEventList';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import { motion } from 'framer-motion';

interface IAccessPointEventExpanderProps {
    isInbound? : boolean;

    accessPoint? : IAccessPoint;
}

interface IAccessPointEventExpanderState {
    open : boolean;
}

export default class AccessPointEventExpander extends React.Component<IAccessPointEventExpanderProps, IAccessPointEventExpanderState> {

    constructor(props : IAccessPointEventExpanderProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    private onClick = () => {
        this.setState({
            open: !this.state.open,
        });
    }

    public render = () => {
        const { open } = this.state;
        const { isInbound, accessPoint } = this.props;
        return (
            <React.Fragment>
                <ListItem button onClick={this.onClick} alignItems='center'>
                    {isInbound ? (<span className='icon-direction-in cw p5' style={{ fontSize: 24 }} />) : (<span className='icon-direction-out cw p5' style={{ fontSize: 24 }} />)}
                    <Divider orientation='vertical' className='bcw mr15 ml15' />
                    <ListItemText primary={isInbound ? 'IN' : 'OUT'} />
                    <motion.div animate={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }} className='mh0'>
                        <Icon>expand_more</Icon>
                    </motion.div>
                </ListItem>
                <motion.div animate={{ height: open ? '100%' : '0px' }} transition={{ type: 'tween' }} className='fdc oya bcw scrolly'>
                    {
                        open && !!accessPoint &&
                        <AccessPointEventList accessPoint={accessPoint} isInbound={isInbound} />
                    }
                </motion.div>
            </React.Fragment>
        );
    }
}
