import { RightsAction } from '../../@types/redux';
import rightsActions from './actions';
import { getType } from 'typesafe-actions';
import { INotificationType, IRole, IRight, IUserListView } from '../../@types/model/right';

export interface IRightsState {
    isLoading : boolean;
    notificationTypes : Array<INotificationType>;
    roles : Array<IRole>;
    isLoadingRoles : boolean;
    rights : Array<IRight>;
    isLoadingUsers : boolean;
    users : Array<IUserListView>;
}

const initialState = {
    isLoading: false,
    isLoadingRoles: false,
    isLoadingUsers: false,
    notificationTypes: [],
    roles: [],
    rights: [],
    accessPoints: [],
    users: [],
};

export default function dataReducer(state : IRightsState = initialState, action : RightsAction) : IRightsState {
    switch (action.type) {
        case getType(rightsActions.setIsLoading):
            return { ...state, isLoading: action.payload };
        case getType(rightsActions.setNotificationTypes):
            return { ...state, notificationTypes: action.payload };
        case getType(rightsActions.setRoles):
            return { ...state, roles: action.payload };
        case getType(rightsActions.setRights):
            return { ...state, rights: action.payload };
        case getType(rightsActions.setRolesLoading):
            return { ...state, isLoadingRoles: action.payload };
        case getType(rightsActions.setUserLoading):
            return { ...state, isLoadingUsers: action.payload };
        case getType(rightsActions.setUsers):
            return { ...state, users: action.payload };
        default:
            return state;
    }
}
