import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import { createSelector } from 'reselect';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '@material-ui/core';
import AccessPointFilterPopper from '../../popper/AccessPointPopper';

interface IAccessPointFilterSelectorProps {
    id ?: string;
    fullWidth ?: boolean;
    code : string;
    name : string;
    division : string;
    category : Array<number>;
    onChange? : (code : string, name : string, division : string, category : Array<number>) => void;    
}

interface IAccessPointFilterSelectorState {
    open : boolean;
}

export default class AccessPointFilterSelector extends React.PureComponent<IAccessPointFilterSelectorProps, IAccessPointFilterSelectorState> {
    private filterRef : React.RefObject<HTMLButtonElement>;
    constructor(props : IAccessPointFilterSelectorProps) {
        super(props);

        this.state = {
            open: false,
        };
        
        this.filterRef = React.createRef<HTMLButtonElement>();
    }

    private toggleOpen = (open : boolean) => {
        this.setState({ open, });
    }

    private onFilterIconButtonClick = () => {
        this.toggleOpen(!this.state.open);
    }

    private onFilterFocusClick = (event : React.FocusEvent<HTMLInputElement>) => {
        this.toggleOpen(true);
    }

    private onClearClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.onChange('', '', '', []);
    }

    private onChange = (code : string, name : string, division : string, category : Array<number>) => {
        this.setState({ open: false, });

        if (!this.props.onChange) return;
        this.props.onChange(code, name, division, category);
    }

    private getCode = (props: IAccessPointFilterSelectorProps) => props.code;
    private getName = (props: IAccessPointFilterSelectorProps) => props.name;
    private getDivision = (props : IAccessPointFilterSelectorProps) => props.division;
    private getCategory = (props : IAccessPointFilterSelectorProps) => props.category;

    private getLabel = createSelector(
        [
            this.getCode,
            this.getName,
            this.getDivision,
            this.getCategory,
        ],
        (
            code,
            name,
            division,
            category,
        ) => {
            const result = [];

            if (code) result.push(code);
            if (name) result.push(name);
            if (division) result.push(division);
            if (category.length) result.push('Category');

            return result.join(' - ');
        }
    )

    public render = () => {
        const { fullWidth, id, code, name, division, category } = this.props;
        const { open } = this.state;

        const value = this.getLabel(this.props);
        
        return (
            <FormControl fullWidth={fullWidth}>
                <InputLabel htmlFor={id}>Filter By...</InputLabel>
                <Input
                    onFocus={this.onFilterFocusClick}
                    onChange={undefined}
                    value={value}
                    readOnly
                    endAdornment={
                        <InputAdornment position={'end'}>
                            {
                                (code || name || division || !!category.length) &&
                                <IconButton
                                    aria-label={'clear filter'}
                                    onClick={this.onClearClick}
                                >
                                    <CloseIcon className={'cr'}/>
                                </IconButton>
                            }
                            <Tooltip title={'Filter'}>
                                <IconButton
                                    ref={this.filterRef}
                                    aria-label={'toggle filter menu'}
                                    onClick={this.onFilterIconButtonClick}
                                >
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                            <AccessPointFilterPopper 
                                id={id}
                                anchorEl={this.filterRef.current}
                                open={open}
                                code={code}
                                name={name}
                                division={division}
                                category={category}
                                onCancelClick={this.onFilterIconButtonClick}
                                onChange={this.onChange}
                            />
                        </InputAdornment>
                    }
                />
            </FormControl>
        );
    }
}