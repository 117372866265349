import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { EnumIncidentCourtCaseAccusedVerdict, IncidentCourtCaseAccusedVerdict } from '../../../../../@types/model/incident';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import lodash from 'lodash';

interface IIncidentCourtCaseAccusedVerdictAutocompleteProps {
    label ?: string;
    value : EnumIncidentCourtCaseAccusedVerdict | null;
    disabled ?: boolean;
    required ?: boolean;
    onChange : (value : EnumIncidentCourtCaseAccusedVerdict | null) => void;
}

interface IIncidentCourtCaseAccusedVerdictAutocompleteState {}

export default class IncidentCourtCaseAccusedVerdictAutocomplete extends React.PureComponent<IIncidentCourtCaseAccusedVerdictAutocompleteProps, IIncidentCourtCaseAccusedVerdictAutocompleteState> {
    constructor(props : IIncidentCourtCaseAccusedVerdictAutocompleteProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null) => {
        this.props.onChange(value?.value ?? null);
    }

    private readonly getValue = (state : IIncidentCourtCaseAccusedVerdictAutocompleteState, props : IIncidentCourtCaseAccusedVerdictAutocompleteProps) => props.value;
    private readonly getRequired = (state : IIncidentCourtCaseAccusedVerdictAutocompleteState, props : IIncidentCourtCaseAccusedVerdictAutocompleteProps) => props.required;

    private getAccusedVerdictes = createSelector([], () => {
        return lodash
            .map(EnumIncidentCourtCaseAccusedVerdict, (n) => EnumIncidentCourtCaseAccusedVerdict[n])
            .map((n) => Number(n))
            .filter((n) => !isNaN(n));
    });

    private readonly getAccusedVerdictesForDropdown = createSelector([
        this.getRequired,
        this.getAccusedVerdictes,
    ], (
        required,
        AccusedVerdictes,
    ) => {
        const dropdownData : Array<{
            label : string;
            value : number | null;
        }> = lodash.map(
            AccusedVerdictes,
            (element) => ({
                label: IncidentCourtCaseAccusedVerdict[element as EnumIncidentCourtCaseAccusedVerdict],
                value: element,
            }));

        if (!required) {
            dropdownData.unshift({
                label: 'NONE',
                value: null,
            });
        }
        
        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue,
    ], (
        value,
    ) => {
        if (value != null) {
            return {
                label: IncidentCourtCaseAccusedVerdict[value],
                value : value
            };
        }

        return {
            label: 'None',
            value: null,
        };
    });

    public render = () => {
        const { required, disabled, label } = this.props;

        const dropdownData = this.getAccusedVerdictesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <Autocomplete 
                disabled={disabled}
                options={dropdownData}
                value={value}
                getOptionSelected={(option, val) => option.value === val.value}
                getOptionLabel={option => option.label}
                onChange={this.onChange}
                disableClearable={required}
                openOnFocus
                renderInput={params => <TextField error={required && !value} {...params} fullWidth label={label ?? 'Verdict'} />}  
            />
        );
    }
}
