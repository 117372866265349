import React, { ChangeEvent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DialogContent from '@material-ui/core/DialogContent';
import RightsFunctions from '../../../../../store/right/functions';
import { IRole, IUserListView } from '../../../../../@types/model/right';
import AppFunctions from '../../../../../service/appFunctions';
import GeneralFunctions from '../../../../../store/general/functions';
import PrimaryTabs from '../../../custom/tab/PrimaryTabs';
import PrimaryTab from '../../../custom/tab/PrimaryTab';
import TabViews from '../../../custom/tab/TabViews';
import UserDetailTab from '../tabs/Detail';
import { IRootState } from '../../../../../@types/redux';
import { connect } from 'react-redux';
import { RIGHTS } from '../../../../../appConstants';
import DivisionSelector from '../../../custom/selector/DivisionSelector';
import AccessPointSelector from '../../../custom/selector/AccessPointSelector';
import UserRightTab from '../tabs/Rights';

interface IUserEditDialogProps {
    onClose : () => void;

    open : boolean;

    user : IUserListView;

    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    hasDetails : boolean;
    hasRights : boolean;
    hasDivisions : boolean;
    hasAccessPoints : boolean;
}

interface IUserEditDialogState {
    isLoading : boolean;
    isSaving : boolean;

    selectedRightIds : Array<number>;
    selectedDivisions : Array<string>;
    selectedAccessPoints : Array<number>;
    roleId : number | null;

    employeeNr : string;
    isActive : boolean;

    tabIndex : number;
}

class UserEditDialogComponent extends React.Component<IUserEditDialogProps, IUserEditDialogState> {

    private readonly detailIndex = 0;
    private readonly rightsIndex = 1;
    private readonly divisionIndex = 2;
    private readonly accessIndex = 3;

    constructor(props : IUserEditDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
            isSaving: false,
            selectedRightIds: [],
            selectedDivisions: [],
            selectedAccessPoints: [],
            employeeNr: '',
            roleId: null,
            isActive: true,
            tabIndex: this.detailIndex,
        };
    }

    public componentDidMount() : void {
        this.setIndex();
    }

    public componentDidUpdate(prevProps : IUserEditDialogProps, prevState : IUserEditDialogState) {
        if (!prevProps.open && this.props.open) {
            this.setState({
                isLoading: true,
            });
            this.setIndex();
            this.loadUser();
        }
    }

    private readonly setIndex = () => {
        const {
            hasAccessPoints,
            hasDivisions,
            hasRights,
            hasDetails,
        } = this.props;

        if (hasDetails) {
            this.setState({
                tabIndex: this.detailIndex,
            });
        } else if (hasRights) {
            this.setState({
                tabIndex: this.rightsIndex,
            });
        } else if (hasDivisions) {
            this.setState({
                tabIndex: this.divisionIndex,
            });
        } else if (hasAccessPoints) {
            this.setState({
                tabIndex: this.accessIndex,
            });
        }
    }

    private loadUser = async () => {
        if (this.props.user) {
            const user = await RightsFunctions.getUser(this.props.user.id);

            if (user) {
                this.setState({
                    selectedRightIds: user.userRights.filter(x => x.isActive).map(x => x.rightId),
                    employeeNr: user.employeeNr ? user.employeeNr : '',
                    roleId: user.roleId,
                    selectedDivisions: user.userDivisions.filter(x => x.isActive).map(x => x.divisionGL),
                    selectedAccessPoints: user.userAccessPoints.filter(x => x.isActive).map(x => x.accessPointId),
                    isActive: user.isActive,
                });
            }
            this.setState({
                isLoading: false,
            });
        }
    }

    public close = (save : boolean) => {
        if (save) {
            this.save();
        } else {
            this.props.onClose();
        }
    }

    public onSaveClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.close(true);
    }

    public onCloseClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.close(false);
    }

    private submitForm = async (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }

    private onEmployeeNumberChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            employeeNr: event.target.value,
        });
    }

    private onRoleChange = (role : IRole) => {
        this.setState({
            roleId: role.id,
            selectedRightIds: role.roleRights.map(n => n.rightId),
        });
    }

    private onDivisionsChange = (divisions : Array<string>) => {
        this.setState({
            selectedDivisions: divisions,
        });
    }

    private onAccessPointChange = (accessPoints : Array<number>) => {
        this.setState({
            selectedAccessPoints: accessPoints,
        });
    }

    private readonly onTabChange = (event : React.ChangeEvent, value : any) => {
        this.setState({
            tabIndex: value,
        });
    }

    private onIsActiveChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            isActive: checked,
        });
    }

    private onRightsChange = (selectedRightIds : Array<number>) => {
        this.setState({
            selectedRightIds,
        });
    }

    public save = async () => {
        const {
            hasDetails,
        } = this.props;

        if (hasDetails && !this.state.employeeNr) {
            GeneralFunctions.showErrorSnackbar('Employee Number Required.');
            return;
        }
        if (hasDetails && !this.state.roleId) {
            GeneralFunctions.showErrorSnackbar('Role Required.');
            return;
        }
        if (!this.props.user) {
            GeneralFunctions.showErrorSnackbar('User Required.');
            return;
        }

        try {
            this.setState({
                isSaving: true,
            });

            await RightsFunctions.saveUser(
                this.props.user.id,
                this.state.roleId ?? 0,
                this.state.employeeNr,
                this.state.selectedRightIds,
                this.state.selectedDivisions,
                this.state.selectedAccessPoints,
                this.state.isActive,
            );
            this.props.onClose();
        } finally {
            this.setState({
                isSaving: false,
            });
        }
    }

    public render() {
        const {
            isLoading,
            isSaving,
            selectedRightIds,
            employeeNr,
            roleId,
            selectedDivisions,
            selectedAccessPoints,
            tabIndex,
            isActive,
        } = this.state;
        const {
            open,
            onClose,
            transition,
            hasAccessPoints,
            hasDetails,
            hasDivisions,
            hasRights,
        } = this.props;
        return (
                <Dialog
                    open={open}
                    aria-labelledby='user-edit-dialog-title'
                    aria-describedby='user-edit-dialog-description'
                    TransitionComponent={transition}
                    transitionDuration={500}
                    fullScreen
                    onClose={onClose}
                >
                    <AppBar className='posr'>
                        <Toolbar>
                            <IconButton color='inherit' onClick={this.onCloseClick} aria-label='Close'>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant='h6' color='inherit' className='flx1'>
                                EDIT USER : {!this.props.user ? '' : this.props.user.name}
                            </Typography>
                            <Button color='inherit' disabled={isSaving || isLoading} onClick={this.onSaveClick}>
                                {!isSaving && <SaveIcon style={{ marginRight: '10px' }}></SaveIcon>}
                                {isSaving && <CircularProgress color='secondary' size={24} style={{ marginRight: '10px' }}></CircularProgress>}
                                Save
                            </Button>
                        </Toolbar>
                        <PrimaryTabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                            {
                                hasDetails &&
                                <PrimaryTab label='DETAILS' value={this.detailIndex} />
                            }
                            {
                                hasRights &&
                                <PrimaryTab label='RIGHTS' value={this.rightsIndex} />
                            }
                            {
                                hasDivisions &&
                                <PrimaryTab label='DIVISIONS' value={this.divisionIndex} />
                            }
                            {
                                hasAccessPoints &&
                                <PrimaryTab label='ACCESS POINTS' value={this.accessIndex} />
                            }
                        </PrimaryTabs>
                    </AppBar>
                    <DialogContent className={'fdc hfill bcg1'}>
                        {
                            isLoading &&
                            <div className={'fdc flx1 aic jcc'}>
                                <CircularProgress />
                            </div>
                        }
                        {
                            !isLoading &&
                            <div className={'fdc flx1 ais'}>
                                <form autoComplete='off' onSubmit={this.submitForm} className={'fdc flx1'}>
                                    <TabViews index={tabIndex}>
                                        {
                                            hasDetails &&
                                            <UserDetailTab
                                                employeeNr={employeeNr}
                                                isActive={isActive}
                                                roleId={roleId}
                                                onEmployeeNumberChange={this.onEmployeeNumberChange}
                                                onIsActiveChange={this.onIsActiveChange}
                                                onRoleChange={this.onRoleChange}
                                            /> || <div />
                                        }
                                        <div>
                                            {
                                                hasRights &&
                                                <UserRightTab
                                                    value={selectedRightIds}
                                                    onRightsChange={this.onRightsChange}
                                                /> || <div />
                                            }
                                        </div>
                                        {
                                            hasDivisions &&
                                            <DivisionSelector
                                                selectedDivisions={selectedDivisions}
                                                onSelectionChange={this.onDivisionsChange}
                                            /> || <div />
                                        }
                                        {
                                                hasAccessPoints &&
                                                <AccessPointSelector
                                                    selectedAccessPoints={selectedAccessPoints}
                                                    onSelectionChange={this.onAccessPointChange}
                                                /> || <div />
                                        }
                                    </TabViews>
                                </form>
                            </div>
                        }
                    </DialogContent>
                </Dialog>
        );
    }
}


const mapStateToProps = (state : IRootState) => {
    return {
        hasDetails: !!state.auth.session?.user.userRights.some(x => x.rightId === RIGHTS.USER_DETAILS),
        hasRights: !!state.auth.session?.user.userRights.some(x => x.rightId === RIGHTS.USER_RIGHTS),
        hasDivisions: !!state.auth.session?.user.userRights.some(x => x.rightId === RIGHTS.USER_DIVISIONS),
        hasAccessPoints: !!state.auth.session?.user.userRights.some(x => x.rightId === RIGHTS.USER_ACCESS_POINT),
    };
};

const UserEditDialog = connect(
    mapStateToProps,
)(UserEditDialogComponent);

export default UserEditDialog;