import { IIncidentCategory } from '../../../@types/model/incident';
import axios from 'axios';

export default class IncidentCategoryHttpService {
    public static getAll(isActive? : boolean) {
        return axios.get<Array<IIncidentCategory>>(`${API_URL}/api/MasterData/IncidentCategory/GetAll`, {
            params : {
                isActive,
            },
        });
    }
    public static save(incidentCategory : IIncidentCategory) {
        return axios.post<IIncidentCategory>(`${API_URL}/api/MasterData/IncidentCategory/Save`, incidentCategory, {
            params : {
            },
        });
    }
    public static publishAll() {
        return axios.post<IIncidentCategory>(`${API_URL}/api/MasterData/IncidentCategory/PublishAll`, {
        }, {
            params : {
            },
        });
    }
}
