import React from 'react';
import Dropzone from 'react-dropzone';
import PhotoCameraSvg from '@material-ui/icons/PhotoCamera';
import { fileTypeToEnumFileType } from '../../../../service/helper/functionHelperService';
import { EnumFileType } from '../../../../@types/model/base';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import ImageFileView from './ImageFileView';

interface IImageFileUploadProps {
    value : Array<IFileUpload>;

    onFileChange? : (files : Array<IFileUpload>) => void;

    disabled? : boolean;

    accept : string;

    single? : boolean;
}

interface IImageFileUploadState {
}

export default class ImageFileUpload extends React.Component<IImageFileUploadProps, IImageFileUploadState> {
    constructor(props : IImageFileUploadProps) {
        super(props);
        this.state = {
        };
    }

    private onFileDrop = (acceptedFiles : Array<File>) => {
        if (!this.props.onFileChange) return;
        const files = !this.props.single ? this.props.value.slice() : [];

        acceptedFiles.forEach((file) => {
            switch (fileTypeToEnumFileType(file.type)) {
                case EnumFileType.Audio:
                case EnumFileType.Video:
                    break;
                default:
                    files.push({
                        name: file.name,
                        file,
                        type: fileTypeToEnumFileType(file.type),
                        duration: null,
                    });
            }
        });

        this.props.onFileChange(files);
    }

    private readonly onRemoveFile = (file : IFileUpload, index : number) => {
        if (!this.props.onFileChange) return;
        const files = this.props.value.slice();
        files.splice(index, 1);
        this.props.onFileChange(files);
    }

    public render = () => {
        const { disabled, accept, value, single, onFileChange } = this.props;
        const { } = this.state;
        return (
            <div className={`fdc hfill wfill ${!onFileChange ? '' : 'cursorPointer'}`}>
                <Dropzone onDrop={!onFileChange ? undefined : this.onFileDrop} accept={accept}>
                    {({ getRootProps, getInputProps }) => (
                        <section className='fdc flx1 bcg1'>
                            {
                                !disabled &&
                                !!value.length &&
                                <div className='fdr hfill wfill' {...getRootProps()}>
                                    <input disabled={disabled} multiple={!single} {...getInputProps()} />
                                    {
                                        value.map((n, i) => (
                                            <div key={`${i}_${n.name}`} className='fdc flx1 hfill aic jcc'>
                                                <ImageFileView
                                                    index={i}
                                                    value={n}
                                                    onRemoveFile={this.onRemoveFile}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                !disabled &&
                                !value.length &&
                                <div className='fdr flx1 aic' {...getRootProps()}>
                                    <input disabled={disabled || !onFileChange} multiple={!single} {...getInputProps()} />
                                    <div className='fdr flx1 jcc'>
                                        <PhotoCameraSvg className='cg2 h120 w120' />
                                    </div>
                                </div>
                            }
                        </section>
                    )}
                </Dropzone>
            </div>
        );
    }
}
