import * as React from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import moment, { Moment } from 'moment';
import { DatePicker } from '@material-ui/pickers/DatePicker';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import AwayClicker from './AwayClicker';

// tslint:disable-next-line:no-var-requires
const dateRangePicker = require('react-date-range');

interface IDateRangeProps {
    onChange : (start : Moment, end : Moment) => void;
    start : Moment;
    end : Moment;
}

interface IDateRangeState {
    showPicker : boolean;
}

interface IDateRange {
    selection : {
        startDate : Date,
        endDate : Date,
    };
}

export default class DateRange extends React.Component<IDateRangeProps, IDateRangeState> {

    constructor(props : IDateRangeProps) {
        super(props);

        this.state = {
            showPicker: false,
        };

    }

    private selectedToDateChanged = (momentDate : Moment) => {
        this.props.onChange(this.props.start, momentDate);
    }

    private selectedFromDateChanged = (momentDate : Moment) => {
        this.props.onChange(momentDate, this.props.end);
    }

    private handleRangeClick = () => {
        this.setState({
            showPicker: !this.state.showPicker,
        });
    }

    private handleRangeChange = (ranges : IDateRange) => {
        const start = moment.utc({ y: ranges.selection.startDate.getFullYear(), M: ranges.selection.startDate.getMonth(), d: ranges.selection.startDate.getDate() });
        const end = moment.utc({ y: ranges.selection.endDate.getFullYear(), M: ranges.selection.endDate.getMonth(), d: ranges.selection.endDate.getDate() });
        this.props.onChange(start, end);
    }

    public render() {
        const selectionRange = {
            startDate: this.props.start.toDate(),
            endDate: this.props.end.toDate(),
            key: 'selection',
        };

        return (
            <div className={'fdr flx1 aic'}>
                <div className={'pr20'}>
                    <DatePicker value={this.props.start} onChange={this.selectedFromDateChanged} format='YYYY-MM-DD' label='From Date'
                        className={'TextField w140'} margin={'normal'} />
                </div>
                <div>
                    <DatePicker value={this.props.end} onChange={this.selectedToDateChanged} format='YYYY-MM-DD' label='To Date'
                        className={'TextField w140'} margin={'normal'} />
                </div>
                <div className={'pr20 posr posb0 aic'}>
                    <IconButton onClick={this.handleRangeClick}>
                        <Icon>
                            date_range
                        </Icon>
                    </IconButton>
                    {
                        this.state.showPicker &&
                        <AwayClicker onClickAway={() => this.setState({
                            showPicker: false,
                        })}>
                            <dateRangePicker.DateRangePicker
                                ranges={[selectionRange]}
                                onChange={this.handleRangeChange}
                                className={'zi2 posa bw1 ml10'}
                            />
                        </AwayClicker>
                    }
                </div>
            </div>
        );
    }
}
