import React from 'react';
import { IIncident } from '../../../../@types/model/incident';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IncidentSuspectEditDialog from '../dialog/edit/SuspectEditDialog';
import IncidentSuspectDeleteButton from '../../custom/button/IncidentSuspectDeleteButton';

interface IIncidentSuspectsViewProps {
    incident : IIncident;
    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentSuspectsViewState {}

export default class IncidentSuspectsView extends React.PureComponent<IIncidentSuspectsViewProps, IIncidentSuspectsViewState> {
    constructor(props : IIncidentSuspectsViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const {} = this.state;
        const { isEditAdmin, incident, isSaving } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg1'>
                <span className='flx1' />
                <Paper className='fdc flx4' elevation={3}>
                    <AppBar position='static' className='pbr bcpl' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                SUSPECTS
                            </Typography>
                            <span className='flx1' />
                            {
                                isEditAdmin &&
                                <div className='posa posr10'>
                                    <IncidentSuspectEditDialog index={-1} maxWidth='md' fullWidth incident={incident} isLoading={isSaving}/>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    {
                        !incident.suspects?.length &&
                        <div className='fdc pt10 mb10 aic jcc'>
                            <Typography className={'flx1 fs16 lh37 fwm'} variant='subtitle1'>
                                Unknown
                            </Typography>
                        </div>
                    }
                    {
                         incident.suspects.map((n, i) =>
                         <div key={`suspect_${n.id}`} className='fdr bbw1 bocg1'>
                            <div className='fdc aic jcfs pt10 ml15 mr15'>
                                <Typography className='h42 w42 aic jcc bcp standard-box fs23 fwm cw brh' variant='subtitle1'>
                                    {i + 1}
                                </Typography>
                            </div>
                            <div className='fdc flx1'>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Name
                                    </Typography>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Surame
                                    </Typography>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Employee No / ID No
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                        {n.name}
                                    </Typography>
                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                        {n.surname}
                                    </Typography>
                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                        {n.employeeNr}
                                    </Typography>
                                </div>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Address
                                    </Typography>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Telephone No
                                    </Typography>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Cellphone No
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 aic fs16 lh37 fwm pre-wrap'} variant='subtitle1'>
                                        {n.address ?? '-'}
                                    </Typography>
                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                        {n.tel ?? '-'}
                                    </Typography>
                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                        {n.cel ?? '-'}
                                    </Typography>
                                </div>
                                {
                                    n.vehicleOwnership &&
                                    <div className='fdc flx1'>
                                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                Vehicle Ownership
                                            </Typography>
                                            <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                {
                                                    n.vehicleOwnership === 'Private' &&
                                                    'Vehicle Reg. Nr'
                                                }
                                                {
                                                    n.vehicleOwnership === 'Company' &&
                                                    'Fleet Nr'
                                                }
                                            </Typography>
                                            {
                                                n.vehicleOwnership === 'Private' &&
                                                <>
                                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                        Vehicle Make
                                                    </Typography>
                                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                        Vehicle Colour
                                                    </Typography>
                                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                        Insurance Company
                                                    </Typography>
                                                </>
                                            }
                                            {
                                                n.vehicleOwnership === 'Company' &&
                                                <>
                                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                    </Typography>
                                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                    </Typography>
                                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                                    </Typography>
                                                </>
                                            }
                                        </div>
                                        <div className={'fdr mb10 pl10 pr10'}>
                                            <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                {n.vehicleOwnership}
                                            </Typography>
                                            <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                {n.vehicleRegistrationNumber}
                                            </Typography>
                                            {
                                                n.vehicleOwnership === 'Private' &&
                                                <>
                                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                        {n.vehicleMake ?? '-'}
                                                    </Typography>
                                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                        {n.vehicleColour ?? '-'}
                                                    </Typography>
                                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                        {n.insuranceCompany ?? '-'}
                                                    </Typography>
                                                </>
                                            }
                                            {
                                                n.vehicleOwnership === 'Company' &&
                                                <>
                                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                    </Typography>
                                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                    </Typography>
                                                    <Typography className={'flx1 aic fs16 lh37 fwm'} variant='subtitle1'>
                                                    </Typography>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <Typography className={'fdr w100 aic jcc fs16 lh37 fwm'} variant='subtitle1'>
                                <IncidentSuspectEditDialog index={i} maxWidth='md' incident={incident} isLoading={isSaving} />
                                <IncidentSuspectDeleteButton index={i} maxWidth='sm' incident={incident} isLoading={isSaving} />
                            </Typography>
                         </div>)
                    }
                </Paper>
                <span className='flx1' />
            </div>
        );
    }
}
