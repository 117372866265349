import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { IIncident } from '../../../../@types/model/incident';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IncidentFunctions from '../../../../store/incident/functions';
import moment from 'moment';

interface IIncidentRemoveDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IIncidentRemoveDialogState {
    open : boolean;
}

export default class IncidentRemoveDialog extends React.Component<IIncidentRemoveDialogProps, IIncidentRemoveDialogState> {
    constructor(props : IIncidentRemoveDialogProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    private save = async () => {
        await IncidentFunctions.remove(this.props.incident);
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onOkClick = () => {
        this.save();
    }

    private onClick = () => {
        this.setState({
            open: true,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, incident } = this.props;
        const { open } = this.state;
        return (<React.Fragment>
            <Tooltip title='Delete'>
                <div>
                    <IconButton color='inherit' size='small' onClick={this.onClick} aria-label='Delete' disabled={isLoading}>
                        <Icon>delete</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='complainant-info-title'
                aria-describedby='complainant-info-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Delete Incident {incident.number}
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className='flx1 fdr aifs'>
                        <Typography>
                            Remove Incident {incident.number} dated {moment.utc(incident.date).local().format('ddd YYYY-MM-DD')}?
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={this.onOkClick} type='button' variant='contained' color='primary'>
                        OK
                    </Button>
                    <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>);
    }
}
