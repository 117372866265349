import { IBaseImportModel, ILatLng, EnumGender, IFile } from './base';
import { IDivision } from './division';
import { IAccessPointAccessPointCategory } from './masterData/accessPointAccessPointCategory';
import { IPowerSource } from './masterData/powerSource';
import { IUserAccessPoints } from './right';

export enum EnumEntryType {
    Vehicle = 0,
    Person = 1,
    Virus = 2,
}

export enum EnumPriority {
    Low = 0,
    Medium = 1,
    High = 2,
}

export interface IAccessPoint extends IBaseImportModel  {
    guid : string;
    code : string;
    name : string;
    defaultReason : string | null;
    geo : ILatLng;
    isControlled : boolean;
    accessPointType : number;
    controlIdentifier : string | null;

    division : IDivision | null;
    divisionId : number | null;

    priority : EnumPriority;
    readonly priorityDescription : string;

    files : Array<IAccessPointFile>;
    userAccessPoints : Array<IUserAccessPoints> | null;
    accessPointPowerSources : Array<IAccessPointPowerSource> | null;
    accessPointCategories : Array<IAccessPointAccessPointCategory> | null;
}

export interface IAccessPointFile extends IBaseImportModel  {
    accessPointId : number;
    fileId : number;
    file : IFile;
}

export interface IAccessPointPowerSource extends IBaseImportModel  {
    accessPointId : number;
    powerSourceId : number;
    powerSource : IPowerSource | null;
}

export interface IAccessPointEvent extends IBaseImportModel  {
    guid : string;
    date : string;
    deviceId : string;
    accessPointId : number;
    accessPoint : IAccessPoint | null;
    accessPointCode : string;
    accessPointName : string;
    geo : ILatLng;
    isInbound : boolean;
    personId : number | null;
    person : IPerson | null;
    vehicleAccessId : number | null;
    vehicleAccess : IVehicleAccess | null;
    vehicleId : number | null;
    vehicle : IVehicle | null;
    personAccess : IPersonAccess | null;
    personnelAccessPointEvents : Array<IPersonnelAccessPointEvent> | null;
}

export interface IAccessPointEventList  {
    guid : string | null;
    date : string;
    accessPointCode : string;
    accessPointName : string;
    isInbound : boolean | null;
    vehicleReg : string;
    vehicleMake : string;
    vehicleModel : string;
    vehicleColour : string;
    init : string;
    name : string;
    surname : string;
    identifier : string;
    employeeNumber : string;
    reason : string;
    createdBy : string;
    createdByName : string;
}

export interface IPerson extends IBaseImportModel  {
    guid : string;
    initials : string | null;
    name : string | null;
    surname : string | null;
    idNumber : string | null;
    countryOfBirth : string | null;
    employeeNumber : string | null;
    gender : EnumGender;

    driverLicences : Array<IDriverLicence>;
}

export interface IDriverLicence extends IBaseImportModel  {
    init : string;
    surname : string;
    idNumber : string;
    dateOfBirth : string;
    codes : Array<string>;
    countryOfBirth : string;
    countryOfIssue : string;
    driverRestrictions : string;
    validFrom : string;
    expiryDate : string;
    gender : EnumGender;
    licenceType : string;
    issueNumber : string;
    licenceNumber : string;
    vehicleRestrictions : Array<string>;
    issueDate : string;
    issueDate2 : string | null;
    issueDate3 : string | null;
    issueDate4 : string | null;
}

export interface IVehicle extends IBaseImportModel  {
    colour : string | null;
    description : string | null;
    engineNumber : string | null;
    licenceNumber : string;
    licenceDoE : string | null;
    make : string | null;
    model : string | null;
    number : string | null;
    registrationNumber : string | null;
    vin : string | null;
}

export interface IVehicleAccess extends IBaseImportModel  {
    guid : string;
    date : string;
    reason : string;
    odoReading : number | null;
    destination : string | null;
    accessPointEventId : number;
}

export interface IPersonAccess extends IBaseImportModel  {
    guid : string;
    date : string;
    reason : string;
    accessPointEventId : number;
}

export interface IPersonnelAccessPointEvent extends IBaseImportModel  {
    accessPointEventId : number;
    personnel : IPersonnel | null;
}

export interface IPersonnel extends IBaseImportModel  {
    guid : string;
    initials : string | null;
    name : string | null;
    surname : string | null;
    idNumber : string | null;
    countryOfBirth : string | null;
    employeeNumber : string | null;
    gender : EnumGender;

    driverLicences : Array<IPersonnelDriverLicence>;
}

export interface IPersonnelDriverLicence extends IBaseImportModel  {
    init : string;
    surname : string;
    idNumber : string;
    dateOfBirth : string;
    codes : Array<string>;
    countryOfBirth : string;
    countryOfIssue : string;
    driverRestrictions : string;
    validFrom : string;
    expiryDate : string;
    gender : EnumGender;
    licenceType : string;
    issueNumber : string;
    licenceNumber : string;
    vehicleRestrictions : Array<string>;
    issueDate : string;
    issueDate2 : string | null;
    issueDate3 : string | null;
    issueDate4 : string | null;
}
