import { IWebNotification } from '../../../../@types/notification';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import generalFunctions from '../../../../store/general/functions';
import SnoozeIcon from '@material-ui/icons/Snooze';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';

interface IWebNotificationItemProps {
    webNotification : IWebNotification;
    onClick? : (webNotification : IWebNotification) => void;
}

class WebNotificationItem extends React.Component<IWebNotificationItemProps> {

    public onSnoozeClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        generalFunctions.removeWebNotification(this.props.webNotification.key);
    }

    public onClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.webNotification);
        }
    }

    public render = () => {
        return (
            <React.Fragment>
                <ListItem button onClick={this.onClick}>
                    <div className='mr10'>
                        <Avatar src={this.props.webNotification.notification.data.iconURL} />
                    </div>
                    <ListItemText className='cw'
                        secondaryTypographyProps={{
                            className: 'cw',
                        }}
                        primary={this.props.webNotification.notification.title}
                        secondary={this.props.webNotification.notification.body}
                    />
                    <Tooltip title='Snooze'>
                        <IconButton  className='cw' onClick={this.onSnoozeClick} aria-label='Snooze'>
                            <SnoozeIcon />
                        </IconButton>
                    </Tooltip>
                </ListItem>
                <Divider className='bcw' />
            </React.Fragment>
        );
    }
}

export default WebNotificationItem;
