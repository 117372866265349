import React from 'react';
import {  IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IWebNotification, NotificationType } from '../../../@types/notification';
import 'firebase/messaging';
import NotificationMapVerticalExpander from './expander/VerticalExpander';
import NotificationMarker from '../custom/mapItem/NotificationMarker';
import WebNotificationItem from '../custom/listItem/WebNotificationItem';
import GoogleMap from '../custom/mapItem/GoogleMap';
import IncidentDialog from '../incidents/dialog/IncidentDialog';
import { Transitions } from '../custom/animations/Transitions';
import ObservationDialog from '../observations/dialog/ObservationDialog';
import { IAccessPoint } from '../../../@types/model/access';
import DataFunctions from '../../../store/data/functions';
import AccessPointMarker from '../custom/mapItem/AccessPointMarker';
import AccessPointEventExpander from './expander/AccessPointEventExpander';
import EntryInfoDialog from '../accessPointEvent/dialog/EntryInfo';

interface INotificationMapProps {
    webNotifications : Array<IWebNotification>;
    accessPoints : Array<IAccessPoint>;

    hasAccessEventRight : boolean;
    showMap : boolean;
}

interface INotificationMapState {
    selectedObservationGuid? : string;
    selectedIncidentGuid? : string;

    selectedAccessPoint? : IAccessPoint;
}

class NotificationMap extends React.Component<INotificationMapProps, INotificationMapState> {
    private zoom = 6.15;

    constructor(props : INotificationMapProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        DataFunctions.getAllAccessPoints(true);
    }

    public onObservationDialogClose = () => {
        this.setState({
            selectedObservationGuid: undefined,
        });
    }

    public onIncidentDialogClose = () => {
        this.setState({
            selectedIncidentGuid: undefined,
        });
    }

    public onWebnotificationClick = (n : IWebNotification) => {
        switch (n.notification.data.type) {
            case NotificationType.OBS:
                this.setState({
                    selectedObservationGuid: n.notification.data.reference,
                });
                break;
            case NotificationType.INS:
                this.setState({
                    selectedIncidentGuid: n.notification.data.reference,
                });
                break;
        }
    }

    private onAccessPointClick = (accessPoint? : IAccessPoint) => {
        this.setState({
            selectedAccessPoint: accessPoint,
        });
    }

    public render = () => {
        const { selectedObservationGuid, selectedIncidentGuid, selectedAccessPoint } = this.state;
        const { webNotifications, hasAccessEventRight, showMap } = this.props;
        return (
            <div className={'fdr hfill mh0'}>
                <div className={'fdc mh0'}>
                    <NotificationMapVerticalExpander location='left' className={'FillHeader'} initiallyExpanded title='Notifications'>
                        <div className={'fdc ais flx1 mh0 oya scrolly'}>
                            {
                                webNotifications.map((n, i) => (
                                    <WebNotificationItem key={`list_${i}`} webNotification={n} onClick={this.onWebnotificationClick} />
                                ))
                            }
                        </div>
                    </NotificationMapVerticalExpander>
                </div>
                <div className={'PaperBorder fdc flx1 p5 m5'}>
                    <GoogleMap
                        id='main-map'
                        showMap={showMap}
                        center={{
                            lat: -28.7792625,
                            lng: 25.6727135,
                        }}
                        zoom={this.zoom}
                    >
                        {
                            this.props.webNotifications.map((n, i) => <NotificationMarker key={`marker_${i}`} webNotification={n} onInfoClick={this.onWebnotificationClick} />)
                        }
                        {
                            this.props.accessPoints.map(n => <AccessPointMarker key={`marker_${n.guid}`} selected={n.guid === selectedAccessPoint?.guid} accessPoint={n} onInfoClick={this.onAccessPointClick} />)
                        }
                    </GoogleMap>
                </div>
                {
                    hasAccessEventRight &&
                    <div className={'fdc mh0'}>
                        <NotificationMapVerticalExpander disabled={!selectedAccessPoint} location='right' className={'FillHeader'}
                        title='Recent Events' subTitle={!selectedAccessPoint ? '' : selectedAccessPoint.code}>
                            <div className={'fdc flx1 mh0'}>
                                <AccessPointEventExpander isInbound accessPoint={selectedAccessPoint} />
                                <AccessPointEventExpander accessPoint={selectedAccessPoint} />
                            </div>
                        </NotificationMapVerticalExpander>
                    </div>
                }
                <ObservationDialog guid={selectedObservationGuid} maxWidth='md' fullWidth onClose={this.onObservationDialogClose} transition={Transitions.Down} />
                <IncidentDialog guid={selectedIncidentGuid} maxWidth='lg' fullWidth onClose={this.onIncidentDialogClose} transition={Transitions.Down} />
                <EntryInfoDialog maxWidth='md' fullWidth fullScreen transition={Transitions.Down}/>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        webNotifications: state.general.webNotifications,
        accessPoints: state.data.accessPoints.filter(x => x.isActive),
        hasAccessEventRight: state.auth.session && state.auth.session.user.userRights.some(n => n.rightId === 9),
        showMap: !state.general.isLoadingMap,
    };
};

export default connect(
    mapStateToProps,
)(NotificationMap);
