import { goBack, push } from 'connected-react-router';
import { dispatch } from '..';

function navBack() {
    return dispatch(goBack());
}

function navUrl(url : string, state : any = {}) {
    return dispatch(push({
        pathname: url,
        state,
    }));
}

const navActions = {
    navBack,
    navUrl,
};

export default navActions;
