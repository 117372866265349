import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { IIncident } from '../../../../@types/model/incident';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { PDFViewer } from '@react-pdf/renderer';
import IncidentPDF from '../pdf/IncidentPDF';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StandardFab from '../../custom/button/StandardFab';
import { CircularProgress } from '@material-ui/core';

interface IIncidentPrintDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
}

interface IIncidentPrintDialogState {
    open : boolean;

    isLoading : boolean;
}

export default class IncidentPrintDialog extends React.Component<IIncidentPrintDialogProps, IIncidentPrintDialogState> {
    constructor(props : IIncidentPrintDialogProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
        };
    }

    private onClose = () => {
        this.setState({
            open: false,
        });
    }

    private onPrintClick = () => {
        this.setState({
            open: true,
            isLoading: true,
        });
    }

    private onRender = () => {
        this.setState({
            isLoading: false,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, incident } = this.props;
        const { open, isLoading } = this.state;
        return (
            <>
                <Tooltip title='Print'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onPrintClick} aria-label='Print'>
                            <Icon>print</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='print-incident-info-title'
                    aria-describedby='print-incident-info-description'>
                        {
                            isLoading &&
                            <div className='fdc flx1 jcc aic'>
                                <CircularProgress />
                            </div>
                        }
                        <PDFViewer style={{
                            flexGrow: 1,
                            borderWidth: 0,
                            flexDirection: 'column',
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                        }}>
                            <IncidentPDF
                                onRender={this.onRender}
                                incident={incident}
                            />
                        </PDFViewer>
                    <StandardFab style={{
                        bottom: 15,
                        left: 15,
                    }} aria-label='Back' onClick={this.onClose}>
                        <ArrowBackIcon />
                    </StandardFab>
                </Dialog>
            </>
        );
    }
}
