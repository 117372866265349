import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { EnumPriority, IAccessPoint, IAccessPointPowerSource } from '../../../../@types/model/access';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { EnumAccessPointType, ILatLng, IFileUpload } from '../../../../@types/model/base';
import FormHelperText from '@material-ui/core/FormHelperText';
import AccessPointTypeDropdown from '../../custom/dropdowns/AccessPointTypeDropdown';
import { v4 } from 'uuid';
import DataFunctions from '../../../../store/data/functions';
import LocationInput from '../../custom/input/LocationInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FileDropZone from '../../custom/FileDropZone';
import { blobToBase64 } from '../../../../service/helper/functionHelperService';
import AccessPointReasonDropdown from '../../custom/dropdowns/AccessPointReasonDropdown';
import lodash from 'lodash';
import { IDivision } from '../../../../@types/model/division';
import DivisionDropdown from '../../custom/dropdowns/DivisionDropdown';
import { connect } from 'react-redux';
import { IRootState } from '../../../../@types/redux';
import QRCode from 'qrcode.react';
import PrimaryTabs from '../../custom/tab/PrimaryTabs';
import PrimaryTab from '../../custom/tab/PrimaryTab';
import AccessPointHttpService from '../../../../service/http/master/accessPointHttpService';
import GeneralFunctions from '../../../../store/general/functions';
import { CircularProgress } from '@material-ui/core';
import TabViews from '../../custom/tab/TabViews';
import UserSelector from '../../custom/selector/UserSelector';
import { IUserAccessPoints } from '../../../../@types/model/right';
import { IAccessPointAccessPointCategory } from '../../../../@types/model/masterData/accessPointAccessPointCategory';
import AccessPointCategorySelector from '../../custom/selector/AccessPointCategorySelector';
import PowerSourcesSelector from '../../custom/selector/masterData/PowerSourcesSelector';
import PriorityDropdown from '../../custom/dropdowns/PriorityDropdown';

interface IEditAccessPointProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    accessPoint? : IAccessPoint;

    accessPoints : Array<IAccessPoint>;
}

interface IEditAccessPointState {
    open : boolean;
    code : string;
    name : string;
    accessPointType : EnumAccessPointType;
    geo : ILatLng;
    isControlled : boolean;
    isActive : boolean;
    reason : string | null;
    identifier : string | null;
    selectedFile? : File;
    isIdentifierValid : boolean;
    division? : IDivision;
    selectedUsers : Array<number>;
    selectedCategories : Array<number>;
    selectedPowerSources : Array<number>;
    priority : EnumPriority | null;

    tabIndex : number;
    isLoading : boolean;
}

class EditAccessPoint extends React.Component<IEditAccessPointProps, IEditAccessPointState> {
    constructor(props : IEditAccessPointProps) {
        super(props);
        this.state = {
            open: false,
            code: '',
            name: '',
            reason: null,
            accessPointType: EnumAccessPointType.Unknown,
            geo: {
                latitude: 0,
                longitude: 0,
            },
            isControlled: false,
            isActive: true,
            identifier : '',
            isIdentifierValid : true,
            tabIndex: 0,
            isLoading: false,
            selectedUsers: [],
            selectedCategories: [],
            selectedPowerSources: [],
            priority: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditAccessPointProps, prevState : IEditAccessPointState) => {
        if (!prevState.open && this.state.open) {
            this.load();
        }

        if (prevState.open && !this.state.open) {
            if (AccessPointHttpService.cancelGet) {
                AccessPointHttpService.cancelGet();
            }
        }
    }

    public readonly componentWillUnmount = () => {
        if (AccessPointHttpService.cancelGet) {
            AccessPointHttpService.cancelGet();
        }
    }

    private readonly setNew = () => {
        let next = 0;

        const max = lodash.maxBy(this.props.accessPoints, n => parseInt(n.code.substring(2)))?.code.substring(2);

        if (max) {
            next = parseInt(max);
        }
        next++;
        this.setState({
            code: `SH${next?.toString()?.padStart(4, '0')}`,
            name: '',
            reason: null,
            accessPointType: EnumAccessPointType.Unknown,
            geo: {
                latitude: 0,
                longitude: 0,
            },
            isControlled: false,
            identifier : '',
            isIdentifierValid : true,
            tabIndex: 0,
            isLoading: false,
            selectedUsers: [],
            selectedCategories: [],
            selectedPowerSources: [],
            priority: null,
        });

    }

    private readonly load = async () => {
        const { accessPoint } = this.props;

        if (!accessPoint) {
            this.setNew();
            return;
        }

        try {
            this.setState({
                isLoading: true,
            });

            const result = await AccessPointHttpService.get(this.props.accessPoint?.id);

            this.setState({
                code: result.data.code,
                name: result.data.name,
                accessPointType: result.data.accessPointType,
                identifier : result.data.controlIdentifier ?? '',
                geo: result.data.geo,
                isControlled: result.data.isControlled,
                isActive: result.data.isActive,
                reason: result.data.defaultReason,
                division: result.data.division ?? undefined,
                priority: result.data.priority,
                selectedUsers: result.data.userAccessPoints?.map(x => x.userId) ?? [],
                selectedCategories: result.data.accessPointCategories?.filter(x => x.isActive).map(x => x.accessPointCategoryId) ?? [],
                selectedPowerSources: result.data.accessPointPowerSources?.filter(x => x.isActive).map(x => x.powerSourceId) ?? [],
            });

            this.setState({
                isLoading: false,
            });
        } catch (ex) {
            if (ex) {
                GeneralFunctions.showErrorSnackbar('Error Loading Access Point', ex);
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    private readonly validMAC = new RegExp(
        '^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$',
    );

    private readonly save = async () => {
        const {
            code,
            name,
            accessPointType,
            geo,
            isControlled,
            selectedFile,
            reason,
            division,
            identifier,
            selectedUsers,
            selectedCategories,
            selectedPowerSources,
            isActive,
            priority,
        } = this.state;

        if (!code) return;
        if (!name) return;

        const accessPoint = lodash.cloneDeep(!this.props.accessPoint ? {} : this.props.accessPoint) as IAccessPoint;
        if (accessPoint) {
            accessPoint.code = code;
            accessPoint.name = name;
            accessPoint.accessPointType = accessPointType;
            accessPoint.defaultReason = reason;
            accessPoint.geo = geo;
            accessPoint.isControlled = isControlled;
            accessPoint.isActive = isActive;
            accessPoint.divisionId = division?.id ?? null;
            accessPoint.controlIdentifier = identifier;
            accessPoint.priority = priority ?? 0;
            accessPoint.userAccessPoints = selectedUsers.slice().map(userId => ({
                userId,
            } as IUserAccessPoints));
            accessPoint.accessPointCategories = selectedCategories.slice().map(accessPointCategoryId => ({
                accessPointCategoryId,
            } as IAccessPointAccessPointCategory));
            accessPoint.accessPointPowerSources = selectedPowerSources.slice().map(powerSourceId => ({
                powerSourceId,
            } as IAccessPointPowerSource));

            if (!accessPoint.guid) accessPoint.guid = v4();

            let file : IFileUpload | undefined;
            if (selectedFile) {
                const base64 = await blobToBase64(selectedFile);

                file = {
                    name: selectedFile.name,
                    base64,
                };
            }

            const result = await DataFunctions.saveAccessPoint(accessPoint, file);

            if (result) {
                this.onClose();
            }
        }
    }

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private readonly onCodeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            code: event.currentTarget.value,
        });
    }

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    }

    private readonly onAccessPointChange = (value : EnumAccessPointType) => {
        this.setState({
            accessPointType: value,
        });
    }

    private readonly onReasonChange = (value : string) => {
        this.setState({
            reason: value,
        });
    }

    private onIdentifierChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        this.setState({
            identifier : event.currentTarget.value,
            isIdentifierValid : this.validMAC.test(event.currentTarget.value),
        });
    }

    private onLocationChange = (geo : ILatLng) => {
        this.setState({
            geo,
        });
    }

    private onIsControlledChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            isControlled: checked,
        });
    }

    private onIsActiveChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            isActive: checked,
        });
    }

    private onFileSelect = (file : File) => {
        this.setState({
            selectedFile: file,
        });
    }

    private onDivisionChange = (division? : IDivision | undefined) => {
        this.setState({
            division,
        });
    }

    private onLatitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, latitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) },
        });
    }

    private onLongitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, longitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) ?? null },
        });
    }

    private readonly onTabChange = (event : React.ChangeEvent, value : any) => {
        this.setState({
            tabIndex: value,
        });
    }

    private onUserChange = (selectedUsers : Array<number>) => {
        this.setState({
            selectedUsers,
        });
    }

    private onCategoryChange = (selectedCategories : Array<number>) => {
        this.setState({
            selectedCategories,
        });
    }

    private onPowerSourcesChange = (selectedPowerSources : Array<number>) => {
        this.setState({
            selectedPowerSources,
        });
    }

    private readonly onPriorityChange = (value : EnumPriority) => {
        this.setState({
            priority: value,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, accessPoint } = this.props;
        const {
            open,
            code,
            name,
            accessPointType,
            geo,
            isControlled,
            reason,
            division,
            identifier,
            isIdentifierValid,
            tabIndex,
            isLoading,
            selectedUsers,
            selectedCategories,
            isActive,
            selectedPowerSources,
            priority,
        } = this.state;

        const file = !accessPoint || !accessPoint.files || !accessPoint.files.length ? undefined : accessPoint.files[accessPoint.files.length - 1].file;

        const disabled = this.props.isLoading || isLoading;
        return (
            <React.Fragment>
                <Tooltip title={!accessPoint ? 'Add' : 'Edit'}>
                    <div>
                        <IconButton color='primary' onClick={this.onEditClick} aria-label={!accessPoint ? 'Add' : 'Edit'} disabled={disabled}>
                            <Icon>{!accessPoint ? 'add' : 'edit'}</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='access-point-edit-dialog-title'
                    aria-describedby='access-point-edit-description'>
                    <AppBar className='posr'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {!accessPoint ? 'New' : 'Edit'} Access Point - {!accessPoint ? code : accessPoint.code}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={disabled} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                        <PrimaryTabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                            <PrimaryTab label='DETAIL' value={0} />
                            <PrimaryTab label='POWER SOURCES' value={1} />
                            <PrimaryTab label='CATEGORIES' value={2} />
                            <PrimaryTab label='USERS' value={3} />
                        </PrimaryTabs>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className={'fdc hfill bcg1'}>
                        <DialogContent className={'fdc flx1'}>
                            {
                                isLoading &&
                                <div className={'fdc flx1 aic jcc'}>
                                    <CircularProgress />
                                </div>
                            }
                            {
                                !isLoading &&
                                <div className={'fdc flx1 ais'}>
                                        <TabViews index={tabIndex}>
                                            <div className='fdc flx1 hfill'>
                                                <div className={'fdr aifs'}>
                                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                autoComplete='off'
                                                                id='code'
                                                                label='Code'
                                                                value={code}
                                                                onChange={this.onCodeChange}
                                                                margin='normal'
                                                                className={'TextField'}
                                                                required
                                                                error={!!!code}
                                                            />
                                                            {
                                                                !!!code &&
                                                                <FormHelperText error>Required</FormHelperText>
                                                            }
                                                        </FormControl>
                                                    </div>
                                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                autoComplete='off'
                                                                id='name'
                                                                label='Name'
                                                                value={name}
                                                                onChange={this.onNameChange}
                                                                margin='normal'
                                                                className={'TextField'}
                                                                required
                                                                error={!!!name}
                                                            />
                                                            {
                                                                !!!name &&
                                                                <FormHelperText error>Required</FormHelperText>
                                                            }
                                                        </FormControl>
                                                    </div>
                                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                                        <AccessPointTypeDropdown value={accessPointType} onChange={this.onAccessPointChange} />
                                                    </div>
                                                </div>
                                                <div className={'fdr aifs'}>
                                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                                        <AccessPointReasonDropdown value={reason} onChange={this.onReasonChange} />
                                                    </div>
                                                    <div className={'flx1 aic p5 mb10 pr20'}>
                                                        <DivisionDropdown fullWidth required onChange={this.onDivisionChange} value={division} />
                                                    </div>
                                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                                        <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox color='primary' checked={isControlled} onChange={this.onIsControlledChanged}  />
                                                                }
                                                                label='Guarded?'
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <div className={'fdr'}>
                                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            autoComplete='off'
                                                            id='identifier'
                                                            label='Identifier (Mac Address)'
                                                            value={identifier}
                                                            onChange={this.onIdentifierChange}
                                                            margin='normal'
                                                            className={'TextField'}
                                                            error={!isIdentifierValid}
                                                        />
                                                        {
                                                        !isIdentifierValid &&
                                                            <FormHelperText error>Enter a Valid MAC Address</FormHelperText>
                                                        }
                                                    </FormControl>
                                                    </div>
                                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                                        <PriorityDropdown
                                                            value={priority}
                                                            onChange={this.onPriorityChange}
                                                            required
                                                            error={priority === null}
                                                        />
                                                    </div>
                                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                                        <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox color='primary' checked={isActive} onChange={this.onIsActiveChanged}  />
                                                                }
                                                                label='Active?'
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <div className={'fdr flx1'}>
                                                    <div className={'flx1 fdc'}>
                                                        <div className='fdr'>
                                                            <div className={'flx1 ais mb10 pr20'}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        autoComplete='off'
                                                                        id='lat'
                                                                        label='Latitude'
                                                                        value={geo?.latitude ?? null}
                                                                        onChange={this.onLatitudeChange}
                                                                        margin='normal'
                                                                        className={'TextField'}
                                                                        required
                                                                        error={!geo?.latitude && !!geo?.longitude}
                                                                        type='number'
                                                                    />
                                                                    {
                                                                        !geo?.latitude && !!geo?.longitude &&
                                                                        <FormHelperText error>Required</FormHelperText>
                                                                    }
                                                                </FormControl>
                                                            </div>
                                                            <div className={'flx1 ais mb10'}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        autoComplete='off'
                                                                        id='long'
                                                                        label='Longitude'
                                                                        value={geo?.longitude}
                                                                        onChange={this.onLongitudeChange}
                                                                        margin='normal'
                                                                        className={'TextField'}
                                                                        required
                                                                        error={!!geo?.latitude && !geo?.longitude}
                                                                        type='number'
                                                                    />
                                                                    {
                                                                        !!geo?.latitude && !geo?.longitude &&
                                                                        <FormHelperText error>Required</FormHelperText>
                                                                    }
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className='h220'>
                                                            <LocationInput geo={geo} onChange={this.onLocationChange} />
                                                        </div>
                                                    </div>
                                                    <div className={'flx1 jcc asc p20'}>
                                                        <FileDropZone file={file} onFileSelected={this.onFileSelect} />
                                                    </div>
                                                    <div className={'flx1 jcc asc p20'}>
                                                        <QRCode value={code}
                                                        size={256}
                                                        renderAs='canvas'
                                                        level='H'
                                                        imageSettings={{
                                                            src: `${ASSET_BASE}/assets/images/zz2-logo.svg`,
                                                            height: 18,
                                                            width: 35,
                                                            excavate: true,
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <PowerSourcesSelector selectedPowerSources={selectedPowerSources} onSelectionChange={this.onPowerSourcesChange} />
                                            <AccessPointCategorySelector selectedAccessPointCategories={selectedCategories} onSelectionChange={this.onCategoryChange} />
                                            <UserSelector selectedUsers={selectedUsers} onSelectionChange={this.onUserChange} />
                                        </TabViews>
                                </div>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={disabled} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={disabled} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        accessPoints: state.data.accessPoints,
    };
};

export default connect(
    mapStateToProps,
)(EditAccessPoint);
