import React, { ChangeEvent } from 'react';
import DivisionDropdown from '../../custom/dropdowns/DivisionDropdown';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { IIncidentGeneralInfoCapture } from '../../../../@types/viewmodel/incident';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import { IDivision } from '../../../../@types/model/division';
import IncidentCategoryGroupDropdown from '../../custom/autocomplete/IncidentCategoryGroupDropdown';
import { ILatLng } from '../../../../@types/model/base';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LocationInput from '../../custom/input/LocationInput';
import { IIncidentGroup } from '../../../../@types/model/masterData/incidentGroup';

interface IIncidentGeneralInfoCaptureProps {
    value? : IIncidentGeneralInfoCapture;

    onSubmit? : (value : IIncidentGeneralInfoCapture) => void;
}

interface IIncidentGeneralInfoCaptureState {
    division? : IDivision;
    farmObNumber : string;
    group : string;
    geo : ILatLng;
}

export default class IncidentGeneralInfoCapture extends React.Component<IIncidentGeneralInfoCaptureProps, IIncidentGeneralInfoCaptureState> {
    constructor(props : IIncidentGeneralInfoCaptureProps) {
        super(props);
        this.state = {
            farmObNumber: !props.value ? '' : props.value.farmObNumber,
            division: !props.value ? undefined : props.value.division,
            group: !props.value ? '' : props.value.group,
            geo: !props.value ? {
                latitude: 0,
                longitude: 0,
            } : props.value.geo,
        };
    }

    private onDivisionChange = (division? : IDivision | undefined) => {
        this.setState({
            division,
            geo: division?.geo ?? this.state.geo,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!this.state.division) return;

        if (!!this.props.onSubmit) {
            this.props.onSubmit({
                division: this.state.division,
                farmObNumber: this.state.farmObNumber,
                group: this.state.group,
                geo: this.state.geo,
            });
        }
    }

    private onFarmObNumberChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            farmObNumber: event.target.value,
        });
    }

    private onGroupsChange = (group : IIncidentGroup) => {
        this.setState({
            group: group.name,
        });
    }

    private readonly onLatitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, latitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) },
        });
    }

    private readonly onLongitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, longitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) ?? null },
        });
    }

    private readonly onLocationChange = (geo : ILatLng) => {
        this.setState({
            geo,
        });
    }

    public render = () => {
        const { division, farmObNumber, group, geo } = this.state;
        return (
            <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
                <Card className='fdr aifs mb10 sbs'>
                    <CardContent className='fdc flx1 ais'>
                        <div className='fdr flx1 aifs'>
                            <div className={`flx1 aic p5 mb10 pr20 ${!group ? '' : 'mt4'}`}>
                                <IncidentCategoryGroupDropdown fullWidth required value={group} onChange={this.onGroupsChange} label='Groups' />
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'}>
                                <DivisionDropdown fullWidth required onChange={this.onDivisionChange} value={division} />
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='farm-ob-input'>Farm OB Number</InputLabel>
                                    <Input
                                        id='farm-ob-input'
                                        value={farmObNumber}
                                        onChange={this.onFarmObNumberChange}
                                        aria-describedby='farm-ob-input-helper-text'
                                        fullWidth
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='fdr flx1 aifs'>
                            <div className='fdc flx1'>
                                <div className='fdr'>
                                    <div className={'flx1 ais mb10 pr20'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete='off'
                                                id='lat'
                                                label='Latitude'
                                                value={geo?.latitude ?? null}
                                                onChange={this.onLatitudeChange}
                                                margin='normal'
                                                className={'TextField'}
                                                required
                                                error={!geo?.latitude && !!geo?.longitude}
                                                type='number'
                                            />
                                            {
                                                !geo?.latitude && !!geo?.longitude &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 ais mb10'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete='off'
                                                id='long'
                                                label='Longitude'
                                                value={geo?.longitude}
                                                onChange={this.onLongitudeChange}
                                                margin='normal'
                                                className={'TextField'}
                                                required
                                                error={!!geo?.latitude && !geo?.longitude}
                                                type='number'
                                            />
                                            {
                                                !!geo?.latitude && !geo?.longitude &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='h300'>
                                    <LocationInput id='edit-dialog-map' geo={geo} onChange={this.onLocationChange} />
                                </div>
                            </div>
                            <div className='fdc flx1' />
                        </div>
                        <div className={'flx1 aic p5 mb10 pr20'}>
                        </div>
                    </CardContent>
                </Card>
                <div className='fdr mr15 ais'>
                    <span className='flx1' />
                    <div>
                        <Fab className={`${!division || !group ? 'cg0 bcg1' : 'cp bcy'} pl40 pr40`}
                            variant='extended'
                            size='medium'
                            disabled={!division || !group}
                            type='submit' aria-label='add'>
                            Next
                        </Fab>
                    </div>
                </div>
            </form>
        );
    }
}
