import { IFileUpload } from '../../viewmodel/file';
import { IBaseImportModel, IFile } from '../base';
import { IGraveVisit } from './graveVisit';

export interface IGrave extends IBaseImportModel {
    guid : string;
    graveSiteId : number;
    number : number;
    registerDate : string;
    nameOfDeceased : string;
    applicantName : string;
    applicantContact : string;
    fileNumber : string;
    identified : boolean | null;
    dateOfBurial : string | null;
    dateOfDeath : string | null;
    files : Array<IGraveFile>;
    graveVisits : Array<IGraveVisit>;
}

export interface IGraveFile extends IBaseImportModel {
    fileId : number;
    file : IFile | null;
    graveId : number;
    type : EnumGraveFileType;
    readonly typeDescription : string;
}

export interface IGraveUpsert extends IGrave {
    file? : IFileUpload;
}

export enum EnumGraveFileType {
    Image = 0,
    InitialApplication = 1,
    GraveSiteAgreement = 2,
    TombstoneErectionApplication = 3,
    BurialAgreement = 4,
}
