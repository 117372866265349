import React from 'react';
import { IIncidentCourtCaseAccusedCreate } from '../../../../@types/viewmodel/incident';
import { Button, FormControl, Input, InputLabel } from '@material-ui/core';
import IncidentCourtCaseAccusedVerdictAutocomplete from '../../custom/autocomplete/incident/IncidentCourtCaseVerdictDropdown';
import { EnumIncidentCourtCaseAccusedVerdict } from '../../../../@types/model/incident';

interface IIncidentCourtCaseAccusedCaptureProps {
    index : number;
    value : IIncidentCourtCaseAccusedCreate;

    onChange? : (value : IIncidentCourtCaseAccusedCreate, index : number) => void;
    onDelete? : (index : number) => void;
}

interface IIncidentCourtCaseAccusedCaptureState {}

export default class IncidentCourtCaseAccusedCapture extends React.PureComponent<IIncidentCourtCaseAccusedCaptureProps, IIncidentCourtCaseAccusedCaptureState> {
    constructor(props : IIncidentCourtCaseAccusedCaptureProps) {
        super(props);
        this.state = {};
    }

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.name = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private readonly onSurnameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.surname = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private readonly onRepresentativeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.representative = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private readonly onSentenceChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = {
            ...this.props.value,
        };

        if (this.props.onChange) {
            value.sentence = event.currentTarget.value;
            this.props.onChange(value, this.props.index);
        }
    }

    private readonly onChargesChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.onChange) {
            this.props.onChange({
                ...this.props.value,
                charges: event.currentTarget.value,
            }, this.props.index);
        }
    }

    private readonly onVerdictChange = (verdict : EnumIncidentCourtCaseAccusedVerdict | null) => {
        const value = {
            ...this.props.value,
            verdict,
        };

        if (this.props.onChange) {
            this.props.onChange(value, this.props.index);
        }
    }

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.index);
        }
    }

    public render = () => {
        const {
            name,
            surname,
            representative,
            sentence,
            verdict,
            charges,
        } = this.props.value;
        return (
            <div className='fdr flx1'>
                <div className={'aic p5 mb10 pr20 flx1'}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='description-input'>Name</InputLabel>
                        <Input
                            autoFocus
                            id='name-input'
                            value={name ?? ''}
                            onChange={this.onNameChange}
                            aria-describedby='name-input-helper-text'
                            fullWidth
                        />
                    </FormControl>
                </div>
                <div className={'aic p5 mb10 pr20 flx1'}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='description-input'>Surname</InputLabel>
                        <Input
                            id='surname-input'
                            value={surname ?? ''}
                            onChange={this.onSurnameChange}
                            aria-describedby='surname-input-helper-text'
                            fullWidth
                        />
                    </FormControl>
                </div>
                <div className={'aic p5 mb10 pr20 flx1'}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='representative-input'>Representative</InputLabel>
                        <Input
                            id='representative-input'
                            value={representative ?? ''}
                            onChange={this.onRepresentativeChange}
                            aria-describedby='representative-input-helper-text'
                            fullWidth
                        />
                    </FormControl>
                </div>
                <div className={'aic p5 mb10 pr20 flx1'}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='charges-input'>Charges</InputLabel>
                        <Input
                            id='charges-input'
                            value={charges ?? ''}
                            onChange={this.onChargesChange}
                            aria-describedby='charges-input-helper-text'
                            fullWidth
                        />
                    </FormControl>
                </div>
                <div className={'aic p5 mb10 pr20 flx1'}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='sentence-input'>Sentence</InputLabel>
                        <Input
                            id='sentence-input'
                            value={sentence ?? ''}
                            onChange={this.onSentenceChange}
                            aria-describedby='sentence-input-helper-text'
                            fullWidth
                        />
                    </FormControl>
                </div>
                <div className={'aic p5 mb10 pr20 flx1'}>
                    <FormControl fullWidth>
                        <IncidentCourtCaseAccusedVerdictAutocomplete
                            value={verdict}
                            onChange={this.onVerdictChange}
                        />
                    </FormControl>
                </div>
                {
                    !!this.props.onDelete &&
                    <div className='fdc jcc'>
                        <div>
                            <Button
                                type='button'
                                variant='contained'
                                className='bcpr'
                                color='primary'
                                onClick={this.onDeleteClick}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
