import { Typography } from '@material-ui/core';
import React from 'react';
import { IGraveSite } from '../../../../@types/model/graveSite/graveSite';
import { IGrave } from '../../../../@types/model/graveSite/grave';
import GraveInfoExpansionTile from '../components/GraveInfoExpansionTile';
import GraveAddDialog from '../dialog/GraveAddDialog';
import { Transitions } from '../../custom/animations/Transitions';
import GraveEditDialog from '../dialog/EditGraveDialog';
import { createSelector } from 'reselect';
import GraveDeleteDialog from '../dialog/DeleteGraveDialog';

interface IGraveSiteGraveViewProps {
    graveSite : IGraveSite;
    isGraveSiteAdmin : boolean;
}

interface IGraveSiteGraveViewState {
    selectedGrave : IGrave | null;
    deleteGrave : IGrave | null;
}

export default class GraveSiteGraveView extends React.PureComponent<IGraveSiteGraveViewProps, IGraveSiteGraveViewState> {
    constructor(props : IGraveSiteGraveViewProps) {
        super(props);
        this.state = {
            selectedGrave: null,
            deleteGrave: null,
        };
    }

    private readonly getData = (state : IGraveSiteGraveViewState, props : IGraveSiteGraveViewProps) => props.graveSite.graves;
    private readonly getGraves = createSelector(
        [this.getData],
        (graves) => {
            return graves.filter(x => x.isActive);
        },
    );

    private readonly setSelected = (selectedGrave : IGrave | null) => {
        this.setState({
            selectedGrave,
        });
    }

    private readonly onEditClick = (grave : IGrave) => {
        this.setSelected(grave);
    }

    private readonly onClose = () => {
        this.setSelected(null);
    }

    private readonly onDeleteClick = (deleteGrave : IGrave) => {
        this.setState({
            deleteGrave,
        });
    }

    private readonly onDeleteClose = () => {
        this.setState({
            deleteGrave: null,
        });
    }

    public render = () => {
        const { isGraveSiteAdmin, graveSite } = this.props;
        const { selectedGrave, deleteGrave } = this.state;

        const graves = this.getGraves(this.state, this.props);

        return (
        <div className={'flx1 fdc bcg1 pt15 pl30 pr30'}>
            <div className={'fdr'}>
                <Typography className={'fs26 cp fwb'}>
                    Number of Graves : { graves.length }
                </Typography>
            </div>
            <div className={'flx1 fdc'}>
                {
                    graves.map(e => <GraveInfoExpansionTile
                        isGraveSiteAdmin={isGraveSiteAdmin}
                        key={e.id}
                        grave={e}
                        graveSite={graveSite}
                        onEditClick={this.onEditClick}
                        onDeleteClick={this.onDeleteClick}
                    />)
                }
            </div>
            <GraveAddDialog graveSite={graveSite} fullWidth maxWidth='md' transition={Transitions.Up}/>
            <GraveEditDialog
                graveSite={graveSite}
                grave={selectedGrave}
                fullWidth maxWidth='md'
                transition={Transitions.Up}
                onClose={this.onClose}
            />
            <GraveDeleteDialog
                onClose={this.onDeleteClose}
                grave={deleteGrave}
                graveSite={graveSite}
            />
        </div>
        );
    }
}
