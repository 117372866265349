import React from 'react';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createSelector } from 'reselect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { IDivision } from '../../../../@types/model/division';
import MasterDataFunctions from '../../../../store/masterData/functions';

interface IDivisionDropdownProps {
    value? : IDivision | null;

    onChange : (value? : IDivision) => void;

    divisions : Array<IDivision>;

    required? : boolean;
    autoFocus? : boolean;

    width? : string | number;
    fullWidth? : boolean;
    isLoading : boolean;
    label? : string;
}

interface IDivisionDropdownState {
}

class DivisionDropdown extends React.Component<IDivisionDropdownProps, IDivisionDropdownState> {
    constructor(props : IDivisionDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        await MasterDataFunctions.getAllDivisions();
    }

    private onChanged = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : string,
    }) => {
        const result = this.props.divisions.find(n => n.code === value?.value);

        this.props.onChange(result);
    }

    private getData = (state : IDivisionDropdownState, props : IDivisionDropdownProps) => props.divisions;
    private getValue = (state : IDivisionDropdownState, props : IDivisionDropdownProps) => props.value;
    private getRequired = (state : IDivisionDropdownState, props : IDivisionDropdownProps) => props.required;
    private getDivisionsDropdown = createSelector([
        this.getData, this.getRequired,
    ], (divisions, required) => {
        const divisionDrop = divisions.filter(x => x.isActive).map(x => ({ label: x.description.toTitleCase(), value: x.code }));

        if (!required) {
            divisionDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return divisionDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired,
    ], (value, required) => {
        if (value) {
            return {
                label: value.description.toTitleCase(),
                value: value.code,
            };
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const {  } = this.state;
        const { required, isLoading, autoFocus, width, fullWidth, label } = this.props;
        const divisions = this.getDivisionsDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required} >
                <Autocomplete
                    id='division_select'
                    options={divisions}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChanged}
                    disableClearable={required}
                    openOnFocus
                    style={{ width: width ? width : undefined }}
                    renderInput={params => <TextField autoFocus={autoFocus} {...params}
                        fullWidth={fullWidth} label={label ?? 'Division'}
                        error={required && !value}
                        InputLabelProps={{
                            className: 'fs16 lh21 cpla lsp024',
                        }}
                    />}
                />
                    {
                        required && !value &&
                        <FormHelperText error>Required</FormHelperText>
                    }
                    {
                        isLoading &&
                        <LinearProgress className='wfill posb0 posa' />
                    }
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        divisions: state.masterData.divisions,
        isLoading: state.masterData.isLoadingDivisions,
    };
};

export default connect(
    mapStateToProps,
)(DivisionDropdown);
