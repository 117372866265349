import axios, { AxiosPromise } from 'axios';
import { IUserToken } from '../../@types/model/right';
import GeneralFunctions from '../../store/general/functions';

export default class AuthHttpService {

    public static googleLogIn(code : string, employeeNr : string) : AxiosPromise<IUserToken> {
        return axios.post(`${API_URL}/api/Rights/Authorisation/GoogleAuth`, {}, {
            params: {
                code,
                employeeNr,
            },
        });
    }

    public static logout() : AxiosPromise {
        return axios.get(`${API_URL}/api/Rights/Authorisation/Logout`);
    }

    public static async refreshSession() : Promise<IUserToken | null> {
        try {
            const result = await axios.get<IUserToken | null>(`${API_URL}/api/Rights/Authorisation/GetSession`, {
                params : {
                },
            });

            return result.data;
        } catch (ex) {
            if (!!ex && ex.status !== 401 && ex.status !== 403) GeneralFunctions.showErrorSnackbar('Error refreshing session', ex);

            throw ex;
        }
    }
}
