import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PlayIcon from '@material-ui/icons/PlayArrow';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import moment from 'moment';

interface IAudioThumbnailProps {
    title? : string;
    url : string;
    duration : number;

    onOpenFileCarousel? : () => void;
}

interface IAudioThumbnailState {
}

class AudioThumbnail extends React.Component<IAudioThumbnailProps, IAudioThumbnailState> {
    constructor(props : IAudioThumbnailProps) {
        super(props);

        this.state = {
        };

    }

    public render() {

        return (
            <Card className='fdc m10 w100 jcc aic'>
                <CardActionArea onClick={this.props.onOpenFileCarousel}>
                    <CardMedia className='w100 h100 fdc aic jcc'>
                        <PlayIcon />
                        {
                            moment.utc(this.props.duration).format('HH:mm:ss')
                        }
                    </CardMedia>
                    {
                        this.props.title &&
                        <CardContent>
                            <Typography variant='subtitle1' align='center'>
                                {this.props.title}
                            </Typography>
                        </CardContent>
                    }
                </CardActionArea>
            </Card>
        );
    }
}

export default AudioThumbnail;
