import { IVirusIncident } from '../../../../@types/model/incident';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import React from 'react';
import incidentActions from '../../../../store/incident/actions';
import { RootAction, DispatchCall, IRootState } from '../../../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import { SYMPTOM_TYPE } from '../../../../appConstants';
import ReactToPrint from 'react-to-print';
import generalFunctions from '../../../../store/general/functions';
import { setPageTitle, resetPageTitle } from '../../../../service/helper/functionHelperService';
import { connect } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AvatarIcon from '../../custom/AvatarIcon';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import lodash from 'lodash';

interface IVirusIncidentDialogProps {
    onClose? : () => void;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    incident? : IVirusIncident;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    guid? : string;

    setIncident : DispatchCall<IVirusIncident | undefined>;
}

interface IVirusIncidentDialogState {
}

class VirusIncidentDialog extends React.PureComponent<IVirusIncidentDialogProps, IVirusIncidentDialogState> {
    private componentRef : any;
    constructor(props : IVirusIncidentDialogProps) {
        super(props);

        this.state = {
        };
    }

    public onClose = () => {
        this.setState({
            isLoading: false,
        });

        this.props.setIncident(undefined);

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    public render = () => {
        const { } = this.state;
        const { incident, fullScreen, transition, guid } = this.props;

        return (
            <Dialog
                    open={!!incident || !!guid}
                    onClose={this.onClose}
                    aria-labelledby='incident-info-dialog-title'
                    aria-describedby='incident-info-description'
                    fullWidth={this.props.fullWidth}
                    maxWidth={this.props.maxWidth}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    fullScreen={fullScreen}
                    scroll='paper'
                >
                    <div
                    ref={el => (this.componentRef = el)}>
                        <AppBar position='sticky'>
                            <Toolbar>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <CloseIcon />
                                </IconButton>
                                {
                                    incident &&
                                    <Typography variant='h6' color='inherit' className='flx1'>
                                        {SYMPTOM_TYPE[incident.symptomType]} - {incident.identifier} - {moment.utc(incident.date).format('YYYY/MM/DD HH:mm')}
                                    </Typography>
                                }
                                {
                                    incident &&
                                    <ReactToPrint
                                        trigger={() =>
                                            <div>
                                                <Tooltip title='Print'>
                                                    <IconButton color='inherit'>
                                                        <Icon>
                                                            print
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }
                                        onPrintError={generalFunctions.showPrintError}
                                        content={() => this.componentRef}
                                        onBeforePrint={() => setPageTitle(`${incident.identifier}}`)}
                                        onAfterPrint={resetPageTitle}
                                    />
                                }
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <div className='pt15'>
                                {
                                    !!incident &&
                                    <div className='fdr'>
                                        <div className='fdc flx1 pr15'>
                                            <div className='fdc bcpd cw'>
                                                <div className='fdc p5'>
                                                    <div className='fdr'>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Created By:</Typography>
                                                            <Typography variant='body1'>{!incident.createdByName ? incident.createdBy : incident.createdByName.toTitleCase()}</Typography>
                                                        </div>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Updated By:</Typography>
                                                            <Typography variant='body1'>{!incident.updatedByName ? incident.updatedBy : incident.updatedByName.toTitleCase()}</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='fdc p5'>
                                                    <Divider className='bcw mb5' />
                                                    <div className='fdr'>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Gate:</Typography>
                                                            <Typography variant='body1'>{incident.accessPointCode}</Typography>
                                                        </div>
                                                        <div className='flx1 p5'>
                                                            <Typography variant='body1' className='w150 fw500'>Identifier:</Typography>
                                                            {
                                                                !!incident.person &&
                                                                <Typography variant='body1'>{!incident.person.idNumber ? (!incident.person.employeeNumber ? incident.person.name : incident.person.employeeNumber) : incident.person.idNumber}</Typography>
                                                            }
                                                            {
                                                                !!incident.person &&
                                                                (!incident.person.idNumber || incident.person.idNumber.length > 13) &&
                                                                <Typography variant='body1'>{!incident.person.employeeNumber ? incident.person.name : incident.person.employeeNumber}</Typography>
                                                            }
                                                            {
                                                                !!incident.person &&
                                                                !!incident.person.idNumber &&
                                                                incident.person.idNumber.length < 14 &&
                                                                <Typography variant='body1'>{incident.person.idNumber}</Typography>
                                                            }
                                                            {
                                                                !incident.person &&
                                                                <Typography variant='body1'>{incident.identifier}</Typography>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='fdc bcg1 cpd'>
                                                <div className='fdr aic m5'>
                                                    <Typography variant='h5' className='fw500'>SYMPTOMS</Typography>
                                                </div>
                                            </div>
                                            <div className='fdc'>
                                                <div className='fdr p5'>
                                                    <div className='flx1 p5'>
                                                        <Typography variant='body1' className='w150 fw500'>Temperature:</Typography>
                                                        <Typography variant='body1'>
                                                            {
                                                                !!incident.temperature &&
                                                                `${(incident.temperature).toFixed(2)} C`
                                                            }
                                                            {
                                                                !incident.temperature &&
                                                                `UNKNOWN`
                                                            }
                                                        </Typography>
                                                    </div>
                                                    <div className='flx1 p5'>
                                                    </div>
                                                </div>
                                                <div className='grid2 p5'>
                                                {
                                                    lodash.map(incident.symptoms, (n, key) => (
                                                        <div key={`symptom_${key}`} className='fdr p5'>
                                                            <Typography variant='body1' className='w150 fw500'>{key.toTitleCase()}:</Typography>
                                                            <Typography variant='body1'>{n ? 'YES' : 'NO'}</Typography>
                                                        </div>
                                                    ))
                                                }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='fdc flx1'>
                                            <Accordion defaultExpanded className='m0 bn nbs incident-expansion'>
                                                <AccordionSummary className={`aic pl5 pr5 bcg1 ${!incident.person ? 'cpr' : 'cpd'} m0 mh48`}
                                                    expandIcon={<ExpandMoreIcon className={`cpd`} />}>
                                                    <div className={`aic`}>
                                                        <AvatarIcon iconClass='icon-suspect' iconSize={42} iconClassName={`${!incident.person ? 'cpr' : 'cpd'}`} className='mr10 bcg1'/>
                                                        <Typography variant='h5' className='fw500'>PERSON</Typography>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails className='flx1 p0'>
                                                    {
                                                        !!incident.person &&
                                                        <div className='fdc flx1'>
                                                            <div className='fdr p5'>
                                                                <div className='flx1 p5'>
                                                                    <Typography variant='body1' className='w150 fw500'>Name:</Typography>
                                                                    <Typography variant='body1'>{incident.person.name} {incident.person.surname}</Typography>
                                                                </div>
                                                                <div className='flx1 p5'>
                                                                    <Typography variant='body1' className='w150 fw500'>ID:</Typography>
                                                                    <Typography variant='body1'>{incident.person.idNumber}</Typography>
                                                                </div>
                                                            </div>
                                                            <div className='fdr p5'>
                                                                <div className='flx1 p5'>
                                                                    <Typography variant='body1' className='w150 fw500'>Gender:</Typography>
                                                                    <Typography variant='body1'>{{ 1 : 'Male', 2 : 'Female', 3 : 'Unknown' }[incident.person.gender]}</Typography>
                                                                </div>
                                                                <div className='flx1 p5'>
                                                                    <Typography variant='body1' className='w150 fw500'>Country of Birth:</Typography>
                                                                    <Typography variant='body1'>{incident.person.countryOfBirth}</Typography>
                                                                </div>
                                                            </div>
                                                            <div className='fdr p5'>
                                                                <div className='flx1 p5'>
                                                                    <Typography variant='body1' className='w150 fw500'>Employee Nr:</Typography>
                                                                    <Typography variant='body1'>{incident.person.employeeNumber}</Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        !incident.person &&
                                                        <div className='fdc flx1'>
                                                            <span>UNKNOWN</span>
                                                        </div>
                                                    }
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </div>
                                }
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    setIncident: incidentActions.setSelectedVirusIncident,
}, dispatch);

const mapStateToProps = (state : IRootState) => {
    return {
        incident: state.incident.selectedVirusIncident,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VirusIncidentDialog);
