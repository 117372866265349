import { Button } from '@material-ui/core';
import React from 'react';
import { GoogleSignInProps, withGoogleSignIn } from '../google/WithGoogleSignIn';

interface IGoogleLoginButtonProps extends GoogleSignInProps {}

interface IGoogleLoginButtonState {}

class GoogleLoginButtonComponent extends React.PureComponent<IGoogleLoginButtonProps, IGoogleLoginButtonState> {
    constructor(props : IGoogleLoginButtonProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        return (
            <Button variant='contained' color='primary' onClick={this.props.onLoginClick}>
                Continue
            </Button>
        );
    }
}

// tslint:disable-next-line: variable-name
const GoogleLoginButton = withGoogleSignIn(GoogleLoginButtonComponent);
export default GoogleLoginButton;
