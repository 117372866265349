import React from 'react';
import { IIncidentEvidence } from '../../../../@types/viewmodel/incident';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MultiFileDropZone from '../../custom/file/MultiFileDropZone';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

interface IIncidentEvidenceCaptureProps {
    value? : IIncidentEvidence;

    onSubmit? : (value : IIncidentEvidence, back? : boolean) => void;
}

interface IIncidentEvidenceCaptureState {
    hasEvidance : boolean;
    description : string;
    exhibitNumber : string;
    result : string;
    ownerReceived : boolean;

    evidenceFiles : Array<IFileUpload>;
}

export default class IncidentEvidenceCapture extends React.Component<IIncidentEvidenceCaptureProps, IIncidentEvidenceCaptureState> {
    private readonly maxFiles = 5;

    constructor(props : IIncidentEvidenceCaptureProps) {
        super(props);
        this.state = {
            hasEvidance: !props.value ? true : props.value.hasEvidance,
            description: !props.value ? '' : props.value.description,
            exhibitNumber: !props.value ? '' : props.value.exhibitNumber,
            result: !props.value ? '' : props.value.result,
            ownerReceived: !props.value ? false : props.value.ownerReceived,
            evidenceFiles: !props.value ? [] : props.value.evidenceFiles,
        };
    }

    private submit = (back? : boolean) => {
        if (!!this.props.onSubmit) {
            const { hasEvidance, description, exhibitNumber, result, ownerReceived, evidenceFiles } = this.state;

            this.props.onSubmit({
                hasEvidance,
                description,
                exhibitNumber,
                result,
                ownerReceived,
                evidenceFiles,
            }, back);
        }
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.submit();
    }

    private onBack = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.submit(true);
    }

    private onHasEvidanceChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            hasEvidance: checked,
        });

        if (!checked) {
            this.setState({
                description: '',
                exhibitNumber: '',
                result: '',
                ownerReceived: false,
                evidenceFiles: [],
            });
        }
    }

    private onOwnerReceivedChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            ownerReceived: checked,
        });
    }

    private onDescriptionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            description: event.currentTarget.value,
        });
    }

    private onExhibitNumberChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            exhibitNumber: event.currentTarget.value,
        });
    }

    private onResultChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            result: event.currentTarget.value,
        });
    }

    private onFileSelected = (file : IFileUpload) => {
        const evidenceFiles = this.state.evidenceFiles.slice();

        const index = evidenceFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            evidenceFiles.splice(index, 1, file);
        } else {
            evidenceFiles.push(file);
        }

        this.setState({
            evidenceFiles,
        });
    }

    private onFileRemove = (file : IFileUpload) => {
        const evidenceFiles = this.state.evidenceFiles.slice();

        const index = evidenceFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            evidenceFiles.splice(index, 1);
        }

        this.setState({
            evidenceFiles,
        });
    }

    public render = () => {
        const { hasEvidance, description, exhibitNumber, result, ownerReceived, evidenceFiles } = this.state;
        return (
        <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
            <Card className='fdr aifs mb10 sbs mnh242'>
                <CardContent className='flx1 fdr aifs'>
                    <div className='flx1 fdr aifs'>
                        <div className='fdc flx1'>
                            <div className='fdr aic m5'>
                                <FormControlLabel
                                    labelPlacement='start'
                                    className='cp'
                                    control={
                                        <Switch
                                            checked={hasEvidance}
                                            color='primary'
                                            onChange={this.onHasEvidanceChange}/>
                                    }
                                    label={`Has Evidence?`}/>
                            </div>
                            {
                                hasEvidance &&
                                <div className='fdc'>
                                    <div className='fdr aifs flx1'>
                                        <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                            <FormControl fullWidth>
                                                <InputLabel required error={!description} htmlFor='description-input'>Description</InputLabel>
                                                <Input
                                                    autoFocus
                                                    id='description-input'
                                                    value={description}
                                                    onChange={this.onDescriptionChange}
                                                    aria-describedby='station-input-helper-text'
                                                    required
                                                    error={!description}
                                                />
                                                {
                                                    !description &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor='exhibitNumber-input'>ZZ2 Exhibit Number</InputLabel>
                                                <Input
                                                    id='exhibitNumber-input'
                                                    value={exhibitNumber}
                                                    onChange={this.onExhibitNumberChange}
                                                    aria-describedby='exhibitNumber-input-helper-text'
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor='result-input'>Result</InputLabel>
                                                <Input
                                                    id='result-input'
                                                    value={result}
                                                    onChange={this.onResultChange}
                                                    aria-describedby='result-input-helper-text'
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='fdr aic m5'>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={ownerReceived}
                                                    color='primary'
                                                    onChange={this.onOwnerReceivedChange}/>
                                            }
                                            label={`Owner Received?`}/>
                                    </div>
                                    <div className='fdr aifs'>
                                        <div className={'flx1'}>
                                            <MultiFileDropZone accept='audio/*,video/*,image/*,application/pdf' files={evidenceFiles} disabled={evidenceFiles.length >= this.maxFiles}
                                            onFileSelected={this.onFileSelected} onFileRemoved={this.onFileRemove} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </CardContent>
            </Card>
            <div className='fdr mr15 ais'>
                <span className='flx1' />
                <div>
                    <Fab className={`nbs bct cp pl40 pr40 mr15`}
                        variant='extended'
                        onClick={this.onBack}
                        size='medium'
                        type='button'>
                        Back
                    </Fab>
                </div>
                <div>
                    <Fab className={`${hasEvidance && !description ? 'cg0 bcg1' : 'cp bcy'} pl40 pr40`}
                        variant='extended'
                        size='medium'
                        disabled={hasEvidance && !description}
                        type='submit'>
                        Next
                    </Fab>
                </div>
            </div>
        </form>
        );
    }
}
