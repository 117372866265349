import React from 'react';
import { IIncidentSuspects, IIncidentSuspect } from '../../../../@types/viewmodel/incident';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IncidentSuspectCapture from './IncidentSuspectCapture';
import MultiFileDropZone from '../../custom/file/MultiFileDropZone';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

interface IIncidentSuspectsCaptureProps {
    value : IIncidentSuspects;

    onSubmit? : (value : IIncidentSuspects, back? : boolean) => void;
}

interface IIncidentSuspectsCaptureState {
    suspectKnown : boolean;
    suspects : Array<IIncidentSuspect>;
    suspectFiles : Array<IFileUpload>;
}

export default class IncidentSuspectsCapture extends React.Component<IIncidentSuspectsCaptureProps, IIncidentSuspectsCaptureState> {
    private readonly maxFiles = 2;
    constructor(props : IIncidentSuspectsCaptureProps) {
        super(props);
        this.state = {
            suspectKnown: !props.value ? true : props.value.suspectKnown,
            suspects: props.value.suspects,
            suspectFiles: !props.value ? [] : props.value.suspectFiles,
        };
    }

    private submit = (back? : boolean) => {
        if (!!this.props.onSubmit) {
            const { suspectKnown, suspects, suspectFiles } = this.state;

            this.props.onSubmit({
                ...this.props.value,
                suspectKnown,
                suspects,
                suspectFiles,
            }, back);
        }
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.submit();
    }

    private onBack = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.submit(true);
    }

    private onKnownSuspectChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            suspectKnown: checked,
        });

        if (!checked) {
            this.setState({
                suspects: [],
                suspectFiles: [],
            });
        } else if (checked && this.state.suspects.length === 0) {
            this.setState({
                suspects: [{
                    address: '',
                    cel: '',
                    employeeNr: '',
                    name: '',
                    surname: '',
                    tel: '',
                    workplace: '',
                    language: '',
                    vehicleColour: '',
                    vehicleMake: '',
                    insuranceCompany: '',
                    vehicleOwnership: null,
                    vehicleRegistrationNumber: '',
                }],
                suspectFiles: [],
            });
        }
    }

    private onSuspectChange = (suspect : IIncidentSuspect, index : number) => {
        const suspects = this.state.suspects.slice();
        suspects.splice(index, 1, suspect);

        this.setState({
            suspects,
        });
    }

    private onSuspectDelete = (index : number) => {
        if (this.state.suspects.length > 1) {
            const suspects = this.state.suspects.slice();
            suspects.splice(index, 1);

            this.setState({
                suspects,
            });
        }
    }

    private onAddSuspectClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const suspects = this.state.suspects.slice();
        suspects.push({
            address: '',
            cel: '',
            employeeNr: '',
            name: '',
            surname: '',
            tel: '',
            workplace: '',
            language: '',
            vehicleColour: '',
            vehicleMake: '',
            insuranceCompany: '',
            vehicleOwnership: null,
            vehicleRegistrationNumber: '',
        });
        this.setState({
            suspects,
        });
    }

    private onFileSelected = (file : IFileUpload) => {
        const suspectFiles = this.state.suspectFiles.slice();

        const index = suspectFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            suspectFiles.splice(index, 1, file);
        } else {
            suspectFiles.push(file);
        }

        this.setState({
            suspectFiles,
        });
    }

    private onFileRemove = (file : IFileUpload) => {
        const suspectFiles = this.state.suspectFiles.slice();

        const index = suspectFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            suspectFiles.splice(index, 1);
        }

        this.setState({
            suspectFiles,
        });
    }

    public render = () => {
        const { suspectKnown, suspects, suspectFiles } = this.state;
        return (
        <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
            <Card className='fdr flx1 aifs mb10 sbs mnh242 oys'>
                <CardContent className='flx1 fdr aifs'>
                    <div className='flx1 fdr aifs'>
                        <div className='fdc flx1'>
                            <div className='fdr aic m5'>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={suspectKnown}
                                            color='primary'
                                            onChange={this.onKnownSuspectChange}/>
                                    }
                                    label={`Known Suspect?`}/>
                            </div>
                            {
                                suspectKnown &&
                                <div className='fdc flx1'>
                                    <div className='fdr fww'>
                                        {
                                            suspects.map((n, i) => (
                                                <IncidentSuspectCapture key={`${i}_suspect`} value={n} index={i} onChange={this.onSuspectChange}
                                                    onDelete={this.state.suspects.length > 1 ? this.onSuspectDelete : undefined}
                                                    isMotorVehicleAccident={this.props.value.isMotorVehicleAccident}
                                                />
                                            ))
                                        }
                                    </div>
                                    <div className='fdr aife'>
                                            <div>
                                                <Fab className={`pl40 pr40 mr15 mt15 mb15`}
                                                    variant='extended'
                                                    color='primary'
                                                    onClick={this.onAddSuspectClick}
                                                    size='medium'
                                                    type='button'>
                                                    ADD SUSPECT
                                                </Fab>
                                            </div>
                                    </div>
                                    <div className='fdr aifs'>
                                        <div className={'flx1'}>
                                            <MultiFileDropZone accept='audio/*,video/*,image/*,application/pdf' files={suspectFiles} disabled={suspectFiles.length >= this.maxFiles}
                                            onFileSelected={this.onFileSelected} onFileRemoved={this.onFileRemove} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </CardContent>
            </Card>
            <div className='fdr ais mr15 mb10'>
                <span className='flx1' />
                <div>
                    <Fab className={`nbs bct cp pl40 pr40 mr15`}
                        variant='extended'
                        onClick={this.onBack}
                        size='medium'
                        type='button'>
                        Back
                    </Fab>
                </div>
                <div>
                    <Fab className={`${suspectKnown && suspects.some(n => !n.name || !n.surname || !n.address || !n.language) ? 'cg0 bcg1' : 'cp bcy'} pl40 pr40`}
                        variant='extended'
                        size='medium'
                        disabled={suspectKnown && suspects.some(n => !n.name || !n.surname || !n.address || !n.language)}
                        type='submit'>
                        Next
                    </Fab>
                </div>
            </div>
        </form>
        );
    }
}
