import React from 'react';
import IconButton from '@material-ui/core/IconButton';

interface IFileCountButtonProps {
    onClick : (guid : string) => void;
    guid : string;
    count : number;
}

export default class FileCountButton extends React.Component<IFileCountButtonProps> {
    private onClick = () => {
        if (this.props.count) {
            this.props.onClick(this.props.guid);
        }
    }
    public render = () => {
        return (
            <IconButton size='small' disabled={!this.props.count} color='primary' onClick={this.onClick}>
                <span style={{
                    fontSize: '1.25em',
                    width: '1em',
                    height: '1em',
                }}>{this.props.count}</span>
            </IconButton>
        );
    }
}
