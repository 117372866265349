import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { v4 } from 'uuid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { IDivisionType } from '../../../../@types/model/masterData/divisionType';
import MasterDataFunctions from '../../../../store/masterData/functions';

interface IEditDivisionTypeProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    divisionType? : IDivisionType;

    initialCode? : string;
}

interface IEditDivisionTypeState {
    open : boolean;
    code : string;
    description : string;
    guid : string;
    isActive : boolean;
}

export default class EditDivisionType extends React.Component<IEditDivisionTypeProps, IEditDivisionTypeState> {
    constructor(props : IEditDivisionTypeProps) {
        super(props);
        this.state = {
            open: false,
            code: '',
            description: '',
            guid: v4(),
            isActive: true,
        };
    }

    public componentDidUpdate = (prevProps : IEditDivisionTypeProps, prevState : IEditDivisionTypeState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                code: '',
                description: '',
                guid: v4(),
                isActive: true,
            });
        }

        if (!prevState.open && this.state.open && this.props.divisionType) {
            this.setState({
                code: this.props.divisionType.code,
                description: this.props.divisionType.description,
                guid: !this.props.divisionType.guid ? v4() : this.props.divisionType.guid,
                isActive: this.props.divisionType.isActive,
            });
        } else if (!prevState.open && this.state.open) {
            this.setState({
                code: this.props.initialCode ?? '',
            });
        }
    }

    private save = async () => {
        const { code, description, guid, isActive } = this.state;

        if (!description) return;
        if (!guid) return;

        const divisionType = {
            ...this.props.divisionType,
            code,
            description,
            guid,
            isActive,
        } as IDivisionType;

        const result = await MasterDataFunctions.saveDivisionType(divisionType);

        if (result) {
            this.onClose();
        }
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onCodeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            description: event.currentTarget.value,
        });
    }

    private onDescriptionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            description: event.currentTarget.value,
        });
    }

    private onIsActiveChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            isActive: checked,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, divisionType } = this.props;
        const { open, code, description, isActive } = this.state;

        return (

            <React.Fragment>
                <Tooltip title={!divisionType ? 'Add' : 'Edit'}>
                    <div>
                        <IconButton color='primary' onClick={this.onEditClick} aria-label={!divisionType ? 'Add' : 'Edit'} disabled={isLoading}>
                            <Icon>{!divisionType ? 'add' : 'edit'}</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='grave-visit-reason-edit-dialog-title'
                    aria-describedby='grave-visit-reason-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {!divisionType ? 'New' : 'Edit'} Division Type
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='code'
                                            label='Code'
                                            value={code}
                                            onChange={this.onCodeChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!code}
                                        />
                                        {
                                            !code &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='description'
                                            label='Type'
                                            value={description}
                                            onChange={this.onDescriptionChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!description}
                                        />
                                        {
                                            !description &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color='primary' checked={isActive} onChange={this.onIsActiveChanged}  />
                                            }
                                            label='IsActive?'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}
