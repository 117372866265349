import { Tooltip } from '@material-ui/core';
import React from 'react';

interface ILongDescriptionColumnProps {
    text : string;
    maxLength? : number;
}

interface ILongDescriptionColumnState {}

export default class LongDescriptionColumn extends React.PureComponent<ILongDescriptionColumnProps, ILongDescriptionColumnState> {
    constructor(props : ILongDescriptionColumnProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { text, maxLength = 50 } = this.props;

        if (this.props.text.length > maxLength) {
            return (
                <Tooltip title={text}>
                    <span>
                    {
                        `${text.substring(0, 50)}...`
                    }
                    </span>
                </Tooltip>
            );
        }
        return (this.props.text);
    }
}
