import React from 'react';
import { AccessPointEventFunctions } from '../../../store/accessPointEvent/functions';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import Accordion from '@material-ui/core/Accordion';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DateRange from '../custom/DateRange';
import MaterialTable from '../custom/materialTable/Table';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import Toolbar from '@material-ui/core/Toolbar';
import { orange } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Dispatch, bindActionCreators } from 'redux';
import vehicleActions from '../../../store/accessPointEvent/actions';
import { CustomMouseEvent } from '../../../@types/helpers';
import { Transitions } from '../custom/animations/Transitions';
import EntryInfoDialog from './dialog/EntryInfo';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IAccessPoint, IAccessPointEventList } from '../../../@types/model/access';
import { CSVLink } from 'react-csv';
import { RouteComponentProps, withRouter } from 'react-router';
import { RIGHTS } from '../../../appConstants';
import AccessPointCodeSelector from '../custom/dropdowns/AccessPointCodeSelector';
import AccessPointNameSelector from '../custom/dropdowns/AccessPointNameSelector';

interface IVehicleEntryProps extends RouteComponentProps<{}, {}, {
    vehicleLicence : string,
}> {
    vehicleEntries : Array<IAccessPointEventList>;
    isLoading : boolean;
    setGuid : DispatchCall<string | undefined>;

    hasViewRight? : boolean;
}

interface IVehicleEntryState {
    expandSearch : boolean;

    startDate : moment.Moment;
    endDate : moment.Moment;
    vehicleMake : string;
    vehicleColour : string;
    vehicleModel : string;
    vehicleLicence : string;
    driver : string;
    accessPoint ? : IAccessPoint;
}

class VehicleEntryComponent extends React.Component<IVehicleEntryProps, IVehicleEntryState> {
    private csvLink : any;
    constructor(props : IVehicleEntryProps) {
        super(props);
        this.state = {
            expandSearch: true,
            startDate: moment.utc().startOf('day'),
            endDate: moment.utc().startOf('day'),
            vehicleMake: '',
            vehicleColour: '',
            vehicleModel: '',
            vehicleLicence: !props.location.state || !props.location.state.vehicleLicence ? '' : props.location.state.vehicleLicence,
            driver: '',
            accessPoint: undefined,
        };
    }

    public componentDidMount = () => {
        AccessPointEventFunctions.getAll(this.state.startDate.valueOf(), this.state.endDate.valueOf());
    }

    public componentWillUnmount = () => {
        this.props.setGuid(undefined);
    }

    private onSearchExpansionChange = (e : React.ChangeEvent<{}>, expanded : boolean) => {
        this.setState({
            expandSearch: expanded,
        });
    }

    private onDateRangeChanged = (start : moment.Moment, end : moment.Moment) => {
        this.setState({
            startDate: start,
            endDate: end,
        });
        AccessPointEventFunctions.getAll(start.valueOf(), end.valueOf());
    }

    private onSearch = () => {
        AccessPointEventFunctions.getAll(this.state.startDate.valueOf(), this.state.endDate.valueOf());
    }

    private onClearSearch = () => {
        this.setState({
            startDate: moment.utc().startOf('day'),
            endDate: moment.utc().startOf('day'),
            vehicleMake: '',
            vehicleColour: '',
            vehicleModel: '',
            vehicleLicence: '',
            driver: '',
            accessPoint: undefined,
        }, () => {
            this.onSearch();
        });
    }

    public onVehicleEntryClick = (e : CustomMouseEvent) => {
        this.props.setGuid(e.currentTarget.value);
    }

    private onVehicleMakeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            vehicleMake: event.currentTarget.value,
        });
    }

    private onVehicleColourChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            vehicleColour: event.currentTarget.value,
        });
    }

    private onVehicleModelChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            vehicleModel: event.currentTarget.value,
        });
    }

    private onVehicleLicenceChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            vehicleLicence: event.currentTarget.value,
        });
    }

    private onDriverChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            driver: event.currentTarget.value,
        });
    }

    private onAccessPointChange = (accessPoint? : IAccessPoint | undefined) => {
        this.setState({
            accessPoint,
        });
    }

    private onFormSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.setState({
            expandSearch: false,
        });
    }

    public getEntries = (state : IVehicleEntryState, props : IVehicleEntryProps) => props.vehicleEntries;
    public getVehicleMake = (state : IVehicleEntryState, props : IVehicleEntryProps) => state.vehicleMake.toLowerCase();
    public getVehicleColour = (state : IVehicleEntryState, props : IVehicleEntryProps) => state.vehicleColour.toLowerCase();
    public getVehicleModel = (state : IVehicleEntryState, props : IVehicleEntryProps) => state.vehicleModel.toLowerCase();
    public getVehicleLicence = (state : IVehicleEntryState, props : IVehicleEntryProps) => state.vehicleLicence.toLowerCase();
    public getDriver = (state : IVehicleEntryState, props : IVehicleEntryProps) => state.driver.toLowerCase();
    public getAccessPoint = (state : IVehicleEntryState, props : IVehicleEntryProps) => state.accessPoint;

    public getFilteredData = createSelector([
        this.getEntries,
        this.getVehicleMake,
        this.getVehicleColour,
        this.getVehicleModel,
        this.getVehicleLicence,
        this.getDriver,
        this.getAccessPoint],
        (entries, vehicleMake, vehicleColour, vehicleModel, vehicleLicence, driver, accessPoint) => {
            return entries
            .filter(n => !vehicleMake || (n.vehicleMake && n.vehicleMake.toLowerCase().includes(vehicleMake)))
            .filter(n => !vehicleColour || (n.vehicleColour && n.vehicleColour.toLowerCase().includes(vehicleColour)))
            .filter(n => !vehicleModel || (n.vehicleModel && n.vehicleModel.toLowerCase().includes(vehicleModel)))
            .filter(n => !vehicleLicence || (n.vehicleReg && n.vehicleReg.toLowerCase().includes(vehicleLicence)))
            .filter(n => !driver || `${n.init} ${n.surname}`.toLowerCase().includes(driver)
                                 || (n.name && n.name.toLowerCase().includes(driver))
                                 || (n.employeeNumber && n.employeeNumber.toLowerCase().includes(driver)))
            .filter(n => !accessPoint || n.accessPointCode === accessPoint.code);
        },
    );

    public getUniqueVehiclesCount = createSelector(
        [this.getFilteredData],
        (entries) => {
            return lodash.size(lodash.groupBy(entries.filter(n => !!n.vehicleReg), 'vehicleReg'));
        },
    );

    public getUniqueGatesCount = createSelector(
        [this.getFilteredData],
        (entries) => {
            return lodash.size(lodash.groupBy(entries, 'accessPointCode'));
        },
    );

    public getInsCount = createSelector(
        [this.getFilteredData],
        (entries) => {
            return entries.filter(n => n.isInbound).length;
        },
    );

    public getOutsCount = createSelector(
        [this.getFilteredData],
        (entries) => {
            return entries.filter(n => !n.isInbound).length;
        },
    );

    public getCSVData = createSelector([
        this.getFilteredData,
    ],
        (entries) => {
            return entries.map(n => ({
                date: moment.utc(n.date).local().format('ddd YYYY-MM-DD HH:mm'),
                accessPointCode: n.accessPointCode,
                accessPointName: n.accessPointName,
                direction: n.isInbound ? `IN` : 'OUT',
                vehicleReg: n.vehicleReg,
                vehicleMake: n.vehicleMake,
                vehicleModel: n.vehicleModel,
                vehicleColour: n.vehicleColour,
                driver: !n.identifier ? 'Unknown' : !n.name ? `${n.init} ${n.surname}` : `${n.name} ${!!!n.surname ? '' : n.surname}`,
                driverIdentifier: !n.identifier ? 'Unknown' : n.identifier,
                reason: n.reason,
                createdByName: !n.createdByName ? n.createdBy : n.createdByName.toTitleCase(),
                createdBy: n.createdBy,
            }));
        },
    );

    public onCSVClick = () => {
        if (this.csvLink) {
            this.csvLink.link.click();
        }
    }

    public render = () => {
        const { expandSearch, startDate, endDate, vehicleMake, vehicleColour, vehicleModel, vehicleLicence, driver, accessPoint } = this.state;
        const { isLoading, hasViewRight } = this.props;

        const vehicleEntries = this.getFilteredData(this.state, this.props);
        const uniqueVehiclesCount = this.getUniqueVehiclesCount(this.state, this.props);
        const uniqueGatesCount = this.getUniqueGatesCount(this.state, this.props);
        const insCount = this.getInsCount(this.state, this.props);
        const outsCount = this.getOutsCount(this.state, this.props);
        const csvData = this.getCSVData(this.state, this.props);
        const csvName = moment().valueOf();
        return (
            <div className={'flx1 fdc p5'}>
                <Card className={'flx1 fdc'}>
                    <form autoComplete='off' onSubmit={this.onFormSubmit}>
                        <Accordion expanded={expandSearch} onChange={this.onSearchExpansionChange} >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h6'>SEARCH</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <div className='fdc flx1'>
                                        <div className='fdr flx1'>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <DateRange onChange={this.onDateRangeChanged} start={startDate} end={endDate} />
                                            </div>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='vehicleMake'
                                                        label='Vehicle Make'
                                                        value={vehicleMake}
                                                        onChange={this.onVehicleMakeChange}
                                                        margin='normal'
                                                        className={'TextField'}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='vehicleColour'
                                                        label='Vehicle Colour'
                                                        value={vehicleColour}
                                                        onChange={this.onVehicleColourChange}
                                                        margin='normal'
                                                        className={'TextField'}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='vehicleModel'
                                                        label='Vehicle Model'
                                                        value={vehicleModel}
                                                        onChange={this.onVehicleModelChange}
                                                        margin='normal'
                                                        className={'TextField'}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='vehicleLicence'
                                                        label='Vehicle Registration'
                                                        value={vehicleLicence}
                                                        onChange={this.onVehicleLicenceChange}
                                                        margin='normal'
                                                        className={'TextField'}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr flx1'>
                                            <div className={'aic p5 pr20'}>
                                                <AccessPointCodeSelector
                                                    onChange={this.onAccessPointChange}
                                                    value={accessPoint}
                                                    width={200}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className={'aic p5 pr20'}>
                                                <AccessPointNameSelector
                                                    onChange={this.onAccessPointChange}
                                                    value={accessPoint}
                                                    width={200}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className={'aic p5 mb10 pr20 '}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='driver'
                                                        label='Driver'
                                                        value={driver}
                                                        onChange={this.onDriverChange}
                                                        margin='normal'
                                                        className={'TextField'}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                            </AccordionDetails>
                            <Divider />
                            <AccordionActions>
                                <Button style={{ backgroundColor: orange[600] }} color='primary' variant='contained' onClick={this.onClearSearch}>
                                    <Icon style={{ marginRight: 10 }}>highlight_off</Icon>
                                    Clear
                                </Button>
                                <Button variant='contained' type='submit'>
                                    <Icon style={{ marginRight: 10 }}>search</Icon>
                                    SEARCH
                                </Button>
                            </AccordionActions>
                        </Accordion>
                    </form>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            Vehicle Entries
                        </Typography>
                        <IconButton color='primary' onClick={this.onSearch}>
                            <Tooltip title='Refresh'>
                                <Icon>
                                    refresh
                                </Icon>
                            </Tooltip>
                        </IconButton>
                        <span className={'flx1'} />
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pt5'}>
                                <span style={{ paddingRight: '15px' }}><b>Vehicle Count: </b>{uniqueVehiclesCount}</span>
                                <span style={{ paddingRight: '15px' }}><b>Gates Count: </b>{uniqueGatesCount}</span>
                                <span style={{ paddingRight: '15px' }}><b>Ins: </b>{insCount}</span>
                                <span style={{ paddingRight: '15px' }}><b>Outs: </b>{outsCount}</span>
                                <span style={{ paddingRight: '15px' }}><b>Total: </b>{vehicleEntries.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <MaterialTable<IAccessPointEventList>
                        id={'vehicleEntryTable'}
                        data={vehicleEntries}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        onExportCsvClick={this.onCSVClick}
                        columns={[
                            {
                                header: '',
                                width: '60px',
                                renderCell: row => (
                                    <div className='aic'>
                                        {
                                            hasViewRight &&
                                            <Tooltip title='Info'>
                                                <IconButton value={`${row.guid}`} onClick={this.onVehicleEntryClick}>
                                                    <Icon>
                                                        info
                                                    </Icon>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                ),
                            },
                            {
                                header: 'Date',
                                field: 'date',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (<span>{moment.utc(row.date).local().format('ddd YYYY-MM-DD HH:mm')}</span>);
                                },
                            },
                            {
                                header: 'Gate',
                                field: 'accessPointCode',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                        <Tooltip title={row.accessPointCode}>
                                            <div>
                                                {!row.accessPointName ? (<span>{row.accessPointCode}</span>) : (<span>{row.accessPointName}</span>)}
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                header: 'Direction',
                                field: 'isInbound',
                                align: 'center',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                        <Tooltip title={row.isInbound ? `IN` : 'OUT'}>
                                            <div>
                                                {row.isInbound ? (<span className='icon-direction-in cpd' style={{ fontSize: 20 }} />) : (<span className='icon-direction-out cpd' style={{ fontSize: 20 }} />)}
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                header: 'Vehicle Reg.',
                                field: 'vehicleReg',
                                enableSort: true,
                            },
                            {
                                header: 'Vehicle Make',
                                field: 'vehicleMake',
                                enableSort: true,
                            },
                            {
                                header: 'Vehicle Model',
                                field: 'vehicleModel',
                                enableSort: true,
                            },
                            {
                                header: 'Vehicle Colour',
                                field: 'vehicleColour',
                                enableSort: true,
                            },
                            {
                                header: 'Driver',
                                field: 'driverSurname',
                                enableSort: false,
                                renderCell: (row) => {
                                    return !!row.identifier ? (
                                        <Tooltip title={row.identifier}>
                                            <span>{!row.name ? `${row.init} ${row.surname}` : `${row.name} ${!!!row.surname ? '' : row.surname}`}</span>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title='Unknown'>
                                            <span>Unknown</span>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                header: 'Reason',
                                field: 'reason',
                                enableSort: true,
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={row.createdBy}>
                                        <span>{!row.createdByName ? row.createdBy : row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },

                        ]}
                    />
                </Card>
                <EntryInfoDialog maxWidth='md' fullWidth fullScreen transition={Transitions.Up}/>
                <CSVLink
                    data={csvData}
                    headers={[
                        {
                            key: 'date',
                            label: 'Date',
                        },
                        {
                            key: 'accessPointCode',
                            label: 'Gate Code',
                        },
                        {
                            key: 'accessPointName',
                            label: 'Gate Name',
                        },
                        {
                            key: 'direction',
                            label: 'Direction',
                        },
                        {
                            key: 'vehicleReg',
                            label: 'Vehicle Reg',
                        },
                        {
                            key: 'vehicleMake',
                            label: 'Vehicle Make',
                        },
                        {
                            key: 'vehicleModel',
                            label: 'Vehicle Model',
                        },
                        {
                            key: 'vehicleColour',
                            label: 'Vehicle Colour',
                        },
                        {
                            key: 'driver',
                            label: 'Driver Name',
                        },
                        {
                            key: 'driverIdentifier',
                            label: 'Driver ID',
                        },
                        {
                            key: 'reason',
                            label: 'Reason',
                        },
                        {
                            key: 'createdByName',
                            label: 'Created By Name',
                        },
                        {
                            key: 'createdBy',
                            label: 'Created By',
                        },
                    ]}
                    filename={`${csvName}_borehole.csv`}
                    className='dn'
                    ref={(r : any) => this.csvLink = r}
                    target='_blank'/>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        vehicleEntries: state.accessPointEvent.vehicleEntries,
        isLoading: state.accessPointEvent.isLoadingEntries,
        hasViewRight: !!state.auth.session &&  state.auth.session.user.userRights.some(n => n.rightId === RIGHTS['ACCESSEV_VIEW']),
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    setGuid: vehicleActions.setGuid,
}, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(VehicleEntryComponent));
