import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { IRootState } from '../../@types/redux';
import { IAuthState } from '../../store/auth/reducer';
import DialogContent from '@material-ui/core/DialogContent';
import UserNotificationSubscription from '../component/custom/UserNotificationSubscription';
import { removeArrayElementWhere, addArrayElement } from '../../service/helper/functionHelperService';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import authFunctions from '../../store/auth/functions';

interface IUserInfoDialogProps {
    onClose : () => void;
    title : string;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    open : boolean;
    auth : IAuthState;
}

interface IUserInfoDialogState {
    isLoading : boolean;
    subscribedNotificationIds : Array<number>;
}

class UserInfoDialog extends React.Component<IUserInfoDialogProps, IUserInfoDialogState> {
    constructor(props : IUserInfoDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
            subscribedNotificationIds: !props.auth.session ? [] : props.auth.session.user.userNotificationSubscriptions.map(n => n.notificationTypeId),
        };
    }

    public onSubscriptionCheckChanged = (id : number) => {
        let resultList;
        if (this.state.subscribedNotificationIds.includes(id)) {
            resultList = removeArrayElementWhere(this.state.subscribedNotificationIds, x => x === id);
        } else {
            resultList = addArrayElement(this.state.subscribedNotificationIds, id);
        }

        if (resultList) {
            this.setState({
                subscribedNotificationIds: resultList,
            });
        }
    }

    public onSaveClick = async () => {
        this.setState({
            isLoading: true,
        });

        const result = await authFunctions.updateNotificationSubscriptions(this.state.subscribedNotificationIds);

        this.setState({
            isLoading: false,
            subscribedNotificationIds: result,
        });
        this.props.onClose();
    }

    public render = () => {
        const { isLoading } = this.state;

        return (
            <Dialog
                    open={!!this.props.open}
                    onClose={this.props.onClose}
                    aria-labelledby='user-info-dialog-title'
                    aria-describedby='user-info-description'
                    fullWidth={this.props.fullWidth}
                    maxWidth={this.props.maxWidth}
                >
                <AppBar className='posr'>
                    <Toolbar>
                        <IconButton color='inherit' onClick={this.props.onClose} aria-label='Close'>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' color='inherit' className='flx1'>
                            {this.props.title}
                        </Typography>
                        <Button color='inherit' disabled={isLoading} onClick={this.onSaveClick}>
                            {!!!isLoading && <SaveIcon style={{ marginRight: '10px' }}></SaveIcon>}
                            {!!isLoading && <CircularProgress color='secondary' size={24} style={{ marginRight: '10px' }} ></CircularProgress>}
                            Save
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {
                        isLoading &&
                        <div className='flx1 fdc p60 jcc aic'>
                            <CircularProgress />
                        </div>

                    }
                    {
                        !isLoading &&
                        <div className='p15'>
                            <UserNotificationSubscription selectedIds={this.state.subscribedNotificationIds} onCheckChanged={this.onSubscriptionCheckChanged} />
                        </div>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(UserInfoDialog);
