import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { v4 } from 'uuid';
import StandardFab from '../../custom/button/StandardFab';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { IDivision } from '../../../../@types/model/division';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import FormHelperText from '@material-ui/core/FormHelperText';
import MultiFileDropZone from '../../custom/file/MultiFileDropZone';
import DivisionDropdown from '../../custom/dropdowns/DivisionDropdown';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ObservationFunctions from '../../../../store/observation/functions';
import { ILatLng } from '../../../../@types/model/base';
import LocationInput from '../../custom/input/LocationInput';
import TextField from '@material-ui/core/TextField';

interface IObservationCreateProps {
    onClose? : () => void;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
    disabled? : boolean;
}

interface IObservationCreateState {
    guid : string;
    open : boolean;

    date : moment.Moment;
    description : string;
    division? : IDivision;
    files : Array<IFileUpload>;
    geo : ILatLng;
}

export default class ObservationCreate extends React.PureComponent<IObservationCreateProps, IObservationCreateState> {
    private readonly maxDate : moment.Moment = moment().utc().startOf('day');
    private readonly maxFiles = 3;
    constructor(props : IObservationCreateProps) {
        super(props);
        this.state = {
            open: false,
            date: moment.utc().startOf('day'),
            description: '',
            guid: '',
            files: [],
            geo: {
                latitude: 0,
                longitude: 0,
            },
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
            date: moment.utc().startOf('day'),
            guid: v4(),
            description: '',
            division: undefined,
            files: [],
            geo: {
                latitude: 0,
                longitude: 0,
            },
        });
    }

    public readonly onClose = () => {
        this.setState({
            open: false,
        });
    }

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { guid, date, files, description, division, geo } = this.state;

        if (!guid || !date || !description || !division) return;
        ObservationFunctions.create(guid, date.valueOf(), division, description, geo, files).then((result) => {
            if (result != null) {
                this.onClose();
            }
        });
    }

    private readonly onDateChange = (momentDate : moment.Moment) => {
        if (momentDate && momentDate?.isAfter(this.maxDate)) return;
        const date = momentDate?.startOf('day');

        this.setState({
            date,
        });
    }

    private readonly onFileSelected = (file : IFileUpload) => {
        const files = this.state.files.slice();

        const index = files.findIndex(n => n.name === file.name);

        if (index > -1) {
            files.splice(index, 1, file);
        } else {
            files.push(file);
        }

        this.setState({
            files,
        });
    }

    private readonly onFileRemove = (file : IFileUpload) => {
        const files = this.state.files.slice();

        const index = files.findIndex(n => n.name === file.name);

        if (index > -1) {
            files.splice(index, 1);
        }

        this.setState({
            files,
        });
    }

    private readonly onDivisionChange = (division? : IDivision | undefined) => {
        this.setState({
            division,
        });
    }

    private readonly onDescriptionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            description: event.currentTarget.value,
        });
    }

    private onLocationChange = (geo : ILatLng) => {
        this.setState({
            geo,
        });
    }

    private onLatitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, latitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) },
        });
    }

    private onLongitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, longitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) ?? null },
        });
    }

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, disabled } = this.props;
        const { open, date, files, description, division, geo } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Create'>
                    <div>
                        <StandardFab aria-label='Create' disabled={isLoading || disabled} onClick={this.onClick}>
                            <AddIcon />
                        </StandardFab>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Create-dialog-title'
                    aria-describedby='Create-description'>
                    <AppBar position='static'>
                        <Toolbar>
                            <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant='h6' color='inherit' className='flx1'>
                                Create Observation
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            value={date}
                                            maxDate={this.maxDate}
                                            onChange={this.onDateChange}
                                            format={'YYYY-MM-DD'} label='Observation Date'
                                            id='ObservationDate'
                                            className={'TextField'}
                                            required
                                            margin={'normal'}
                                            error={!!!date}
                                            fullWidth
                                        />
                                        {
                                            !!!date &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'}>
                                <DivisionDropdown fullWidth required onChange={this.onDivisionChange} value={division} />
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                    <InputLabel required error={!description} htmlFor='description-input'>Description</InputLabel>
                                    <Input
                                        id='description-input'
                                        value={description}
                                        onChange={this.onDescriptionChange}
                                        aria-describedby='description-input-helper-text'
                                        required
                                        multiline
                                        error={!description}
                                    />
                                    {
                                        !description &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div className='fdr'>
                                <div className={'flx1 ais mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='lat'
                                            label='Latitude'
                                            value={geo?.latitude ?? null}
                                            onChange={this.onLatitudeChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!geo?.latitude && !!geo?.longitude}
                                            type='number'
                                        />
                                        {
                                            !geo?.latitude && !!geo?.longitude &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais mb10'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='long'
                                            label='Longitude'
                                            value={geo?.longitude}
                                            onChange={this.onLongitudeChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!!geo?.latitude && !geo?.longitude}
                                            type='number'
                                        />
                                        {
                                            !!geo?.latitude && !geo?.longitude &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className='h200'>
                                <LocationInput geo={geo} onChange={this.onLocationChange} />
                            </div>
                            <div className='fdr aifs mb10'>
                                <div className={'flx1'}>
                                    <MultiFileDropZone accept='audio/*,video/*,image/*,application/pdf' files={files} disabled={files.length >= this.maxFiles}
                                    onFileSelected={this.onFileSelected} onFileRemoved={this.onFileRemove} />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}
