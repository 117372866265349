import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { IIncident } from '../../../../../@types/model/incident';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import lodash from 'lodash';
import IncidentFunctions from '../../../../../store/incident/functions';
import { ISAPSStation } from '../../../../../@types/model/sapsStation';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SAPSStationDropdown from '../../../custom/dropdowns/SAPSStationDropdown';

interface IIncidentSAPSInfoDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IIncidentSAPSInfoDialogState {
    open : boolean;
    involved : boolean;
    station? : ISAPSStation;
    occRegNum : string;
    exhRegNum : string;
    caseRegNum : string;
    infoBookNum : string;
    accRepNum : string;
    officerName : string;
    officerRank : string;
    investigatingOfficer : string;
    investigatingOfficerRank : string;
}

export default class IncidentSAPSInfoDialog extends React.Component<IIncidentSAPSInfoDialogProps, IIncidentSAPSInfoDialogState> {
    constructor(props : IIncidentSAPSInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            involved: true,
            station: undefined,
            occRegNum: '',
            exhRegNum: '',
            caseRegNum: '',
            infoBookNum: '',
            accRepNum: '',
            officerName: '',
            officerRank: '',
            investigatingOfficer: '',
            investigatingOfficerRank: '',
        };
    }

    public componentDidUpdate = (prevProps : IIncidentSAPSInfoDialogProps, prevState : IIncidentSAPSInfoDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                involved: true,
                station: undefined,
                occRegNum: '',
                exhRegNum: '',
                caseRegNum: '',
                infoBookNum: '',
                accRepNum: '',
                officerName: '',
                officerRank: '',
                investigatingOfficer: '',
                investigatingOfficerRank: '',
            });
        }
        if (!prevState.open && this.state.open && this.props.incident) {
            this.setState({
                involved: !this.props.incident.saps ? false : true,
                station: !this.props.incident.saps ? undefined : {
                    emails: [],
                    guid: '',
                    name: this.props.incident.saps.station,
                    phoneNumbers: [],
                },
                occRegNum: !this.props.incident.saps?.occRegNr ? '' : this.props.incident.saps.occRegNr,
                exhRegNum: !this.props.incident.saps?.exhibitRegNr ? '' : this.props.incident.saps.exhibitRegNr,
                caseRegNum: !this.props.incident.saps?.caseRegNr ? '' : this.props.incident.saps.caseRegNr,
                infoBookNum: !this.props.incident.saps?.infBookNr ? '' : this.props.incident.saps.infBookNr,
                accRepNum: !this.props.incident.saps?.accidentReportNr ? '' : this.props.incident.saps.accidentReportNr,
                officerName: !this.props.incident.saps?.officerName ? '' : this.props.incident.saps.officerName,
                officerRank: !this.props.incident.saps?.officerRank ? '' : this.props.incident.saps.officerRank,
                investigatingOfficer: !this.props.incident.saps?.investigatingOfficerName ? '' : this.props.incident.saps.investigatingOfficerName,
                investigatingOfficerRank: !this.props.incident.saps?.investigatingOfficerRank ? '' : this.props.incident.saps.investigatingOfficerRank,
            });
        }
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private save = async () => {
        const { involved, station, occRegNum, exhRegNum, caseRegNum, infoBookNum, accRepNum,
            officerName,
            officerRank,
            investigatingOfficer,
            investigatingOfficerRank,
         } = this.state;

        const incident = lodash.cloneDeep(this.props.incident);

        if (involved && !station) return;

        if (incident) {

            if (involved) {
                const currentSaps = lodash.cloneDeep(this.props.incident.saps!);
                incident.saps = {
                    ...currentSaps,
                    accidentReportNr: accRepNum,
                    caseRegNr: caseRegNum,
                    exhibitRegNr: exhRegNum,
                    infBookNr: infoBookNum,
                    occRegNr: occRegNum,
                    station: station!.name,
                    officerName,
                    officerRank,
                    investigatingOfficerName: investigatingOfficer,
                    investigatingOfficerRank,
                };
            } else {
                incident.saps = undefined;
            }

            const result = await IncidentFunctions.save(incident);

            if (result) {
                this.onClose();
            }
        }
    }

    private onEditClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({
            open: true,
        });
    }

    /**
     * Stops expansion panel from going crazy
     */
    private onClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    }

    private onStationChange = (station : ISAPSStation) => {
        this.setState({
            station,
        });
    }

    private onOccRegNumChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            occRegNum: event.currentTarget.value,
        });
    }

    private onExhRegNumChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            exhRegNum: event.currentTarget.value,
        });
    }

    private onCaseRegNumChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            caseRegNum: event.currentTarget.value,
        });
    }

    private onInfoBookNumChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            infoBookNum: event.currentTarget.value,
        });
    }

    private onAccRepNumChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            accRepNum: event.currentTarget.value,
        });
    }

    private onInvestigatingOfficerRankChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            investigatingOfficerRank: event.currentTarget.value,
        });
    }

    private onInvestigatingOfficerNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            investigatingOfficer: event.currentTarget.value,
        });
    }

    private onOfficerRankChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            officerRank: event.currentTarget.value,
        });
    }

    private onOfficerNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            officerName: event.currentTarget.value,
        });
    }

    private onSapsInvolvedChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            involved: checked,
        });

        if (!checked) {
            this.setState({
                station: undefined,
                occRegNum: '',
                exhRegNum: '',
                caseRegNum: '',
                infoBookNum: '',
                accRepNum: '',
                officerName: '',
                officerRank: '',
                investigatingOfficer: '',
                investigatingOfficerRank: '',
            });
        }
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, involved, station, occRegNum, exhRegNum, caseRegNum, infoBookNum, accRepNum,
            officerName,
            officerRank,
            investigatingOfficer,
            investigatingOfficerRank,
        } = this.state;
        return (<div onClick={this.onClick}>
            <Tooltip title='Edit'>
                <div>
                    <IconButton color='secondary' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='saps-info-title'
                aria-describedby='saps-info-description'
                className='hfill'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit SAPS Info
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 hfill'>
                        <div className='flx1 fdr aifs'>
                            <div className='fdc flx1'>
                                <div className='fdr aic m5'>
                                    <FormControlLabel
                                        labelPlacement='start'
                                        className='cp'
                                        control={
                                            <Switch
                                                checked={involved}
                                                color='primary'
                                                onChange={this.onSapsInvolvedChange}/>
                                        }
                                        label={`SAPS Involved?`}/>
                                </div>
                                {
                                    involved &&
                                    <div className='fdc'>
                                        <div className='fdr aifs flx1'>
                                            <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <SAPSStationDropdown fullWidth value={station} required onChange={this.onStationChange} />
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='occRegNum-input'>Occurrence Register Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='occRegNum-input'
                                                        value={occRegNum}
                                                        onChange={this.onOccRegNumChange}
                                                        aria-describedby='occRegNum-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='exhRegNum-input'>Exhibit Register Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='exhRegNum-input'
                                                        value={exhRegNum}
                                                        onChange={this.onExhRegNumChange}
                                                        aria-describedby='exhRegNum-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aifs'>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='caseRegNum-input'>Case Registration Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='caseRegNum-input'
                                                        value={caseRegNum}
                                                        onChange={this.onCaseRegNumChange}
                                                        aria-describedby='station-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='infoBookNum-input'>Information Book Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='infoBookNum-input'
                                                        value={infoBookNum}
                                                        onChange={this.onInfoBookNumChange}
                                                        aria-describedby='infoBookNum-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='accRepNum-input'>Accident Report Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='accRepNum-input'
                                                        value={accRepNum}
                                                        onChange={this.onAccRepNumChange}
                                                        aria-describedby='accRepNum-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aifs'>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='officerName-input'>Officer on Scene</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='officerName-input'
                                                        value={officerName}
                                                        onChange={this.onOfficerNameChange}
                                                        aria-describedby='officerName-input-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='officerRank-input'>Officer on Scene Rank</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='officerRank-input'
                                                        value={officerRank}
                                                        onChange={this.onOfficerRankChange}
                                                        aria-describedby='officerRank-input-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aifs'>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='investigatingOfficer-input'>Investigating Officer</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='investigatingOfficer-input'
                                                        value={investigatingOfficer}
                                                        onChange={this.onInvestigatingOfficerNameChange}
                                                        aria-describedby='investigatingOfficer-input-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='investigatingOfficerRank-input'>Investigating Officer Rank</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='investigatingOfficerRank-input'
                                                        value={investigatingOfficerRank}
                                                        onChange={this.onInvestigatingOfficerRankChange}
                                                        aria-describedby='investigatingOfficerRank-input-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading || (involved && !station)} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>);
    }
}
