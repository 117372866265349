import React from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import { Icon, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { IAccessPointCategory } from '../../../../@types/model/masterData/accessPointCategory';
import MasterDataFunctions from '../../../../store/masterData/functions';
import { IRootState } from '../../../../@types/redux';
import MaterialTable from '../../custom/materialTable/Table';
import EditAccessPointCategory from './Edit';
import { Transitions } from '../../custom/animations/Transitions';
import DateHelperService from '../../../../service/helper/dateHelperService';
import { createSelector } from 'reselect';
import lodash from 'lodash';

interface IAccessPointCategoryListProps {
    accessPointCategories : Array<IAccessPointCategory>;
    isLoading : boolean;
}

interface IAccessPointCategoryListState {

}

class AccessPointCategoryList extends React.Component<IAccessPointCategoryListProps, IAccessPointCategoryListState> {
    constructor(props : IAccessPointCategoryListProps) {
        super(props);

        this.state = {

        };
    }

    public readonly componentDidMount = () => {
        this.loadData();

    }

    private readonly onRefreshClick = () => {
        this.loadData();
    }

    private readonly loadData = async () => {
        await MasterDataFunctions.getAllAccessPointCategories(true);
    }

    public getCategories = (state : IAccessPointCategoryListState, props : IAccessPointCategoryListProps) => props.accessPointCategories;
    public getInitialCode = createSelector([
        this.getCategories,
    ],
        (categories) => {
            const num = lodash
            .chain(categories)
            .map(category => category.code)
            .map(code => parseInt(code?.substr(2)) ?? 0)
            .max()
            .value() + 1;

            return `AC${(!num ? 1 : num).toString().padStart(4, '0')}`;
        },
    );

    public readonly render = () => {
        const { isLoading, accessPointCategories } = this.props;
        const initialCode = this.getInitialCode(this.state, this.props);

        return (
            <div className={'flx1 fdc p5'}>
                <Card className={'flx1 fdc'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            Access Point Categories
                        </Typography>
                        <IconButton color='primary' onClick={this.onRefreshClick}>
                            <Tooltip title='Refresh'>
                                <Icon>
                                    refresh
                                </Icon>
                            </Tooltip>
                        </IconButton>
                        <span className={'flx1'} />
                        <EditAccessPointCategory
                            transition={Transitions.Up}
                            maxWidth='md'
                            fullWidth
                            isLoading={isLoading}
                            initialCode={initialCode}
                        />
                    </Toolbar>
                    <MaterialTable<IAccessPointCategory>
                        id={'accessPointCategoryTable'}
                        data={accessPointCategories}
                        isLoading={isLoading}
                        rowsPerPage={20}
                        columns={[
                            {
                                header: '',
                                field: '',
                                width: 100,
                                renderCell: (row) => {
                                    return (
                                        <div className='aic'>
                                            <EditAccessPointCategory
                                                accessPointCategory={row}
                                                transition={Transitions.Up}
                                                maxWidth='md'
                                                fullWidth
                                                isLoading={isLoading}
                                            />
                                        </div>
                                    );
                                },
                            },
                            {
                                header: 'Code',
                                field: 'code',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Description',
                                field: 'description',
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Active',
                                field: 'isActive',
                                enableFilter: true,
                                enableSort: true,
                                align: 'center',
                                renderCell: (row) => {
                                    return (
                                        <div className={'jcc aic pr40'}>
                                        {
                                            !!row.isActive &&
                                            <Icon className={'cp'}>
                                                check_circle
                                            </Icon>
                                        }
                                        {
                                            !row.isActive &&
                                            <Icon className={'cpr'}>
                                                cancel_outlined
                                            </Icon>
                                        }
                                        </div>);
                                },
                            },
                            {
                                header: 'Created By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                        <Tooltip title={DateHelperService.format(row.createdOn, 'YYYY-MM-DD HH:mm')}>
                                        <span>{row.createdByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                            {
                                header: 'Updated By',
                                field: 'createdByName',
                                enableSort: true,
                                renderCell: (row) => {
                                    return (
                                    <Tooltip title={DateHelperService.format(row.updatedOn, 'YYYY-MM-DD HH:mm')}>
                                        <span>{row.updatedByName.toTitleCase()}</span>
                                    </Tooltip>);
                                },
                            },
                        ]}
                    />
                </Card>
                <div className='fdr jcfe'>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        accessPointCategories : state.masterData.accessPointCategories,
        isLoading: state.masterData.isLoadingAccessPointCategory,
    };
};

export default connect(
    mapStateToProps,
)(AccessPointCategoryList);
