import React from 'react';
import { IAccessPointEventList } from '../../../../@types/model/access';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PersonIcon from '@material-ui/icons/Person';
import HelpOutlineTwoToneIcon from '@material-ui/icons/HelpOutlineTwoTone';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { createSelector } from 'reselect';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootAction, DispatchCall, IRootState } from '../../../../@types/redux';
import accessPointEvents from '../../../../store/accessPointEvent/actions';

interface IAccessPointEventListItemProps {
    event : IAccessPointEventList;

    onOpenClick? : (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    setGuid : DispatchCall<string | undefined>;
}

interface IAccessPointEventListItemState {

}

class AccessPointEventListItem extends React.Component<IAccessPointEventListItemProps, IAccessPointEventListItemState> {
    constructor(props : IAccessPointEventListItemProps) {
        super(props);
        this.state = {};
    }

    private onOpenClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.props.setGuid(this.props.event.guid);
    }

    private getData = (state : IAccessPointEventListItemState, props : IAccessPointEventListItemProps) => props.event;
    private getName = createSelector([
        this.getData,
    ], (event) => {

        if (!event.name) {
            return ''.concat(!event.init ? '' : event.init, !event.init ? '' : ' ', !event.surname ? '' : event.surname).toLocaleUpperCase();
        }

        return `${event.name} ${!!!event.surname ? '' : event.surname}`.toLocaleUpperCase();
    });

    public render = () => {
        const { event } = this.props;

        const name = this.getName(this.state, this.props);
        return (
        <div className='fdc jcfs ais'>
            <div className='fdr'>
                <div className='fdr flx1'>
                    <Typography color='textPrimary' className='pt5 pb5 aic' variant={'body1'}>
                        {event.vehicleReg}
                    </Typography>
                    <div className='fdc aic ml15 mr15'>
                        <Divider orientation='vertical' className='bcb' />
                    </div>
                    <Typography color='textPrimary' className='pt5 pb5 aic' variant={'body1'}>
                        {event.vehicleMake}
                    </Typography>
                </div>
                <div className='ml15 aic'>
                    <Tooltip title='Open'>
                        <IconButton size='small' onClick={this.onOpenClick}>
                            <OpenInNewIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className='mt10 mb10'>
                <Divider />
            </div>
            <div className='fdc ais'>
                <div className='fdr ais flx1 mb15'>
                    <div className='fdr aic flx1'>
                        <div className='aic mr10'>
                            <DateRangeIcon fontSize='small' color='primary' />
                        </div>
                        <div className='aic'>
                            <Typography color='textPrimary' variant='body2'>
                                {moment.utc(event.date).local().format('YYYY-MM-DD')}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr aic flx1'>
                        <div className='aic'>
                            <PersonIcon fontSize='small' color='primary' />
                        </div>
                        <div className='aic'>
                            <Typography color='textPrimary' variant='body2'>
                                {
                                    event.identifier ? (
                                        <Tooltip title={event.identifier}>
                                            <span>{name}</span>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title='Unknown'>
                                            <span>Unknown</span>
                                        </Tooltip>
                                    )
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr ais flx1'>
                    <div className='fdr aic flx1'>
                        <div className='aic mr10'>
                            <ScheduleIcon fontSize='small' color='primary' />
                        </div>
                        <div>
                            <Typography color='textPrimary' variant='body2'>
                                {moment.utc(event.date).local().format('HH:mm')}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr aic flx1'>
                        <div className='aic mr10'>
                            <HelpOutlineTwoToneIcon fontSize='small' color='primary' />
                        </div>
                        <div>
                            <Typography color='textPrimary' variant='body2'>
                                {event.reason}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt10 mb10'>
                <Divider className='bcb' />
            </div>
        </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    setGuid: accessPointEvents.setGuid,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccessPointEventListItem);
