import { createAction } from 'typesafe-actions';
import { INotification } from '../../@types/redux';
import { IWebNotification } from '../../@types/notification';

export default class GeneralActions {
    public static readonly enqueueSnackbar = createAction('ENQUEUE_SNACKBAR', resolve => (notification : INotification) => resolve({
        key: new Date().getTime() + Math.random(),
        ...notification,
    }));
    public static readonly  removeSnackbar = createAction('REMOVE_SNACKBAR', resolve => (key : number) => resolve(key));
    public static readonly enqueueWebNotification = createAction('ENQUEUE_WEB_NOTIFICATION', resolve => (notification : IWebNotification) => resolve(notification));
    public static readonly removeWebNotification = createAction('REMOVE_WEB_NOTIFICATION', resolve => (key : number) => resolve(key));
    public static readonly setIsLoadingMap = createAction('SET_IS_LOADING_MAP', resolve => (isLoading : boolean) => resolve(isLoading));

}
