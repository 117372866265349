import * as React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import defaultTheme from './style/materialTheme';
import ReactDOM from 'react-dom';
import App from './App';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import store, { hashHistory } from './store';
import './style/App.scss';
import './style/Icons.scss';
import './@types/extensions';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';

if (process.env.NODE_ENV === 'production') {
    // tslint:disable-next-line:no-console
    console.log('Production?', process.env.NODE_ENV);
}

// TODO: Remove on future date!
if (window.location.href.startsWith('https://zz2-security-incident.firebaseapp.com')) {
    window.location.replace('https://security.zz2.co.za');
}

interface IAppRootProps {}

class AppRoot extends React.Component<IAppRootProps> {
    protected store : any;

    constructor(props : IAppRootProps) {
        super(props);
        this.store = store;
    }

    public render() {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Provider store={this.store}>
                    <ConnectedRouter history={hashHistory}>
                        <MuiThemeProvider theme={defaultTheme}>
                            <SnackbarProvider maxSnack={4}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                classes={{
                                    variantSuccess: defaultTheme.palette.primary.main,
                                    variantError: '#e81d2c',
                                    variantWarning: '#FF7F00',
                                    variantInfo: '#0d703f',
                                }}>
                                <App />
                            </SnackbarProvider>
                        </MuiThemeProvider>
                    </ConnectedRouter>
                </Provider>
            </MuiPickersUtilsProvider>
        );
    }
}

const rootDomElement = document.getElementById('root');

if (rootDomElement) {
    ReactDOM.render(<AppRoot />, rootDomElement);
}
