import React from 'react';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { IIncidentSAPSCapture } from '../../../../@types/viewmodel/incident';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ISAPSStation } from '../../../../@types/model/sapsStation';
import SAPSStationDropdown from '../../custom/dropdowns/SAPSStationDropdown';

interface IIncidentSAPSProps {
    value? : IIncidentSAPSCapture;

    onSubmit? : (value : IIncidentSAPSCapture, back? : boolean) => void;
}

interface IIncidentSAPSState {
    involved : boolean;
    station? : ISAPSStation;
    occRegNum : string;
    exhRegNum : string;
    caseRegNum : string;
    infoBookNum : string;
    accRepNum : string;
    officerName : string;
    officerRank : string;
    investigatingOfficer : string;
    investigatingOfficerRank : string;
}

export default class IncidentSAPS extends React.Component<IIncidentSAPSProps, IIncidentSAPSState> {
    constructor(props : IIncidentSAPSProps) {
        super(props);
        this.state = {
            involved: !props.value ? true : props.value.involved,
            station: !props.value ? undefined : props.value.station,
            occRegNum: !props.value ? '' : props.value.occRegNum,
            exhRegNum: !props.value ? '' : props.value.exhRegNum,
            caseRegNum: !props.value ? '' : props.value.caseRegNum,
            infoBookNum: !props.value ? '' : props.value.infoBookNum,
            accRepNum: !props.value ? '' : props.value.accRepNum,
            officerName: !props.value ? '' : props.value.officerName,
            officerRank: !props.value ? '' : props.value.officerRank,
            investigatingOfficer: !props.value ? '' : props.value.investigatingOfficer,
            investigatingOfficerRank: !props.value ? '' : props.value.investigatingOfficerRank,
        };
    }

    private submit = (back? : boolean) => {
        if (!!this.props.onSubmit) {
            this.props.onSubmit({
                involved: this.state.involved,
                station: this.state.station,
                occRegNum: this.state.occRegNum,
                exhRegNum: this.state.exhRegNum,
                caseRegNum: this.state.caseRegNum,
                infoBookNum: this.state.infoBookNum,
                accRepNum: this.state.accRepNum,
                officerName: this.state.officerName,
                officerRank: this.state.officerRank,
                investigatingOfficer: this.state.investigatingOfficer,
                investigatingOfficerRank: this.state.investigatingOfficerRank,
            }, back);
        }
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.submit();
    }

    private onBack = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.submit(true);
    }

    private onStationChange = (station : ISAPSStation) => {
        this.setState({
            station,
        });
    }

    private onOccRegNumChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            occRegNum: event.currentTarget.value,
        });
    }

    private onExhRegNumChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            exhRegNum: event.currentTarget.value,
        });
    }

    private onCaseRegNumChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            caseRegNum: event.currentTarget.value,
        });
    }

    private onInfoBookNumChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            infoBookNum: event.currentTarget.value,
        });
    }

    private onAccRepNumChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            accRepNum: event.currentTarget.value,
        });
    }

    private onOfficerNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            officerName: event.currentTarget.value,
        });
    }

    private onOfficerRankChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            officerRank: event.currentTarget.value,
        });
    }

    private onInvestigatingOfficerChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            investigatingOfficer: event.currentTarget.value,
        });
    }

    private onInvestigatingOfficerRankChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            investigatingOfficerRank: event.currentTarget.value,
        });
    }

    private onSapsInvolvedChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            involved: checked,
        });

        if (!checked) {
            this.setState({
                station: undefined,
                occRegNum: '',
                exhRegNum: '',
                caseRegNum: '',
                infoBookNum: '',
                accRepNum: '',
                officerName: '',
                officerRank: '',
                investigatingOfficer: '',
                investigatingOfficerRank: '',
            });
        }
    }

    public render = () => {
        const { involved, station, occRegNum, exhRegNum, caseRegNum, infoBookNum, accRepNum,
        officerName, officerRank, investigatingOfficer, investigatingOfficerRank } = this.state;
        return (
            <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
                <Card className='fdr aifs mb10 sbs mnh450'>
                    <CardContent className='flx1 fdr aifs'>
                        <div className='flx1 fdr aifs'>
                            <div className='fdc flx1'>
                                <div className='fdr aic m5'>
                                    <FormControlLabel
                                        labelPlacement='start'
                                        className='cp'
                                        control={
                                            <Switch
                                                checked={involved}
                                                color='primary'
                                                onChange={this.onSapsInvolvedChange}/>
                                        }
                                        label={`SAPS Involved?`}/>
                                </div>
                                {
                                    involved &&
                                    <div className='fdc'>
                                        <div className='fdr aifs flx1'>
                                            <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <SAPSStationDropdown fullWidth value={station} required onChange={this.onStationChange} />
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='occRegNum-input'>Occurrence Register Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='occRegNum-input'
                                                        value={occRegNum}
                                                        onChange={this.onOccRegNumChange}
                                                        aria-describedby='occRegNum-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='exhRegNum-input'>Exhibit Register Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='exhRegNum-input'
                                                        value={exhRegNum}
                                                        onChange={this.onExhRegNumChange}
                                                        aria-describedby='exhRegNum-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aifs'>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth error={!officerName} required>
                                                    <InputLabel htmlFor='officer-name-input'>Officer on Scene Name</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        required
                                                        id='officer-name-input'
                                                        value={officerName}
                                                        onChange={this.onOfficerNameChange}
                                                        aria-describedby='officer-name-input-helper-text'
                                                        error={!officerName}
                                                    />
                                                    {
                                                        !officerName &&
                                                        <FormHelperText error>
                                                            Required
                                                        </FormHelperText>
                                                    }
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth error={!officerRank} required>
                                                    <InputLabel htmlFor='officer-rank-input'>Officer on Scene Rank</InputLabel>
                                                    <Input
                                                        required
                                                        fullWidth
                                                        id='officer-rank-input'
                                                        value={officerRank}
                                                        onChange={this.onOfficerRankChange}
                                                        aria-describedby='officer-rank-input-text'
                                                        error={!officerRank}
                                                    />
                                                    {
                                                        !officerRank &&
                                                        <FormHelperText error>
                                                            Required
                                                        </FormHelperText>
                                                    }
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='investigating-officer-input'>Investigating Officer Name</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='investigating-officer-input-input'
                                                        value={investigatingOfficer}
                                                        onChange={this.onInvestigatingOfficerChange}
                                                        aria-describedby='investigating-officer-input-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='investigating-officer-input-rank-input'>Investigating Officer Rank</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='investigating-officer-input-input'
                                                        value={investigatingOfficerRank}
                                                        onChange={this.onInvestigatingOfficerRankChange}
                                                        aria-describedby='investigating-officer-input-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aifs'>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='caseRegNum-input'>Case Registration Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='caseRegNum-input'
                                                        value={caseRegNum}
                                                        onChange={this.onCaseRegNumChange}
                                                        aria-describedby='station-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='infoBookNum-input'>Information Book Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='infoBookNum-input'
                                                        value={infoBookNum}
                                                        onChange={this.onInfoBookNumChange}
                                                        aria-describedby='infoBookNum-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='accRepNum-input'>Accident Report Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='accRepNum-input'
                                                        value={accRepNum}
                                                        onChange={this.onAccRepNumChange}
                                                        aria-describedby='accRepNum-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <div className='fdr mr15 ais'>
                    <span className='flx1' />
                    <div>
                        <Fab className={`nbs bct cp pl40 pr40 mr15`}
                            variant='extended'
                            onClick={this.onBack}
                            size='medium'
                            type='button'>
                            Back
                        </Fab>
                    </div>
                    <div>
                        <Fab className={`${involved && !station ? 'cg0 bcg1' : 'cp bcy'} pl40 pr40`}
                            variant='extended'
                            size='medium'
                            disabled={involved && (!station || !officerName || !officerRank)}
                            type='submit'>
                            Next
                        </Fab>
                    </div>
                </div>
            </form>
        );
    }
}
