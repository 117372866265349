import React from 'react';
import { IAccessPoint, IAccessPointEventList } from '../../../../@types/model/access';
import { AccessPointEventFunctions } from '../../../../store/accessPointEvent/functions';
import { AccessPointEventHttpService } from '../../../../service/http/accessPointEventHttpService';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import AccessPointEventListItem from './AccessPointEventListItem';

interface IAccessPointEventListProps {
    isInbound? : boolean;

    accessPoint : IAccessPoint;
}

interface IAccessPointEventListState {
    events : Array<IAccessPointEventList>;
    isLoading : boolean;
}

export default class AccessPointEventList extends React.Component<IAccessPointEventListProps, IAccessPointEventListState> {
    constructor(props : IAccessPointEventListProps) {
        super(props);
        this.state = {
            events: [],
            isLoading: false,
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public componentDidUpdate = (prevProps : IAccessPointEventListProps) => {
        if (prevProps.accessPoint.guid !== this.props.accessPoint.guid) {
            this.loadData();
        }
    }

    public componentWillUnmount = () => {
        if (!!this.props.isInbound && AccessPointEventHttpService?.cancelIn) {
            AccessPointEventHttpService.cancelIn();

        }

        if (!this.props.isInbound && AccessPointEventHttpService?.cancelOut) {
            AccessPointEventHttpService.cancelOut();
        }
    }

    private loadData = async () => {
        this.setState({
            isLoading: true,
        });

        const events = await AccessPointEventFunctions.getLatest(!!this.props.isInbound, this.props.accessPoint.id, 20);

        if (events) {
            this.setState({
                isLoading: false,
                events,
            });
        }
    }

    public render = () => {
        const { isLoading, events } = this.state;
        return (
            <div>
                <div className='h4'>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
                {
                    !isLoading && !events.length &&
                    <div className='fdc flx1 jcc ais'>
                        <span>None</span>
                    </div>
                }
                {
                    !isLoading && events.length &&
                    <div className='fdc flx1 jcc ais mh0 m5'>
                        <List>
                            {
                                events.map(n => (
                                    <AccessPointEventListItem event={n} key={n.guid} />
                                ))
                            }
                        </List>
                    </div>
                }
            </div>
        );
    }
}
