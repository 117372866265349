import { DataAction } from '../../@types/redux';
import dataActions from './actions';
import { getType } from 'typesafe-actions';
import { IAccessPoint } from '../../@types/model/access';
import { ISAPSStation } from '../../@types/model/sapsStation';
import { IIncidentCategory } from '../../@types/model/incident';
import { ICustomer } from '../../@types/model/customer';
import { IIncidentGroup } from '../../@types/model/masterData/incidentGroup';

export interface IDataState {
    isLoading : boolean;
    accessPoints : Array<IAccessPoint>;
    accessPoint? : IAccessPoint;
    sapsStations : Array<ISAPSStation>;
    isLoadingIncidentCategories : boolean;
    incidentCategories : Array<IIncidentCategory>;
    isLoadingDivisions : boolean;
    divisions : Array<ICustomer>;
    isLoadingIncidentGroups : boolean;
    incidentGroups : Array<IIncidentGroup>;
}

const initialState = {
    isLoading: false,
    accessPoints: [],
    sapsStations: [],
    isLoadingIncidentCategories: false,
    incidentCategories: [],
    isLoadingDivisions: false,
    divisions: [],
    isLoadingIncidentGroups: false,
    incidentGroups: [],
};

export default function dataReducer(state : IDataState = initialState, action : DataAction) : IDataState {
    switch (action.type) {
        case getType(dataActions.setIsLoading):
            return { ...state, isLoading: action.payload };
        case getType(dataActions.setAccessPoints):
            return { ...state, accessPoints: action.payload };
        case getType(dataActions.setAccessPoint):
            return { ...state, accessPoint: action.payload };
        case getType(dataActions.setSAPSStations):
            return { ...state, sapsStations: action.payload };
        case getType(dataActions.setLoadingIncidentCategories):
            return { ...state, isLoadingIncidentCategories: action.payload };
        case getType(dataActions.setIncidentCategories):
            return { ...state, incidentCategories: action.payload };
        case getType(dataActions.setIsLoadingDivisions):
            return { ...state, isLoadingDivisions: action.payload };
        case getType(dataActions.setDivisions):
            return { ...state, divisions: action.payload };
        case getType(dataActions.setLoadingIncidentGroups):
            return { ...state, isLoadingIncidentGroups: action.payload };
        case getType(dataActions.setIncidentGroups):
            return { ...state, incidentGroups: action.payload };
        default:
            return state;
    }
}
