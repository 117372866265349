import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import lodash from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import { EnumPriority } from '../../../../@types/model/access';

interface IPriorityDropdownProps {
    value : number | null;
    required ?: boolean
    error ?: boolean;
    onChange : (value : number) => void;
}

interface IPriorityDropdownState {}

export default class PriorityDropdown extends React.Component<IPriorityDropdownProps, IPriorityDropdownState> {
    constructor(props : IPriorityDropdownProps) {
        super(props);
        this.state = {};
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>) => {
        this.props.onChange(Number(event.target.value));
    }

    public render = () => {
        const { value, required, error } = this.props;

        return (
            <FormControl margin='normal' fullWidth required={required} error={error}>
                <InputLabel htmlFor='priority'>Priority</InputLabel>
                <Select
                    fullWidth
                    id='priority'
                    value={value ?? ''}
                    onChange={this.onChange}
                    required={required}
                    error={error}
                >
                {
                    lodash.chain(EnumPriority)
                        .filter((x, i) => !isNaN(Number(i)))
                        .map((n, k) => (
                            <MenuItem key={`${n}_priority`} value={k}>
                                {n}
                            </MenuItem>
                        ))
                        .value()
                }
                </Select>
            </FormControl>
        );
    }
}
