import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { EnumIncidentCourtCaseStatus, IIncident, IIncidentCourtCase } from '../../../../../@types/model/incident';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import IncidentFunctions from '../../../../../store/incident/functions';
import { Moment } from 'moment';
import moment from 'moment';
import IncidentCourtCaseStatusAutocomplete from '../../../custom/autocomplete/incident/IncidentCourtCaseStatusDropdown';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import { FormControlLabel, Switch } from '@material-ui/core';

interface IIncidentCourtCaseDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IIncidentCourtCaseDialogState {
    open : boolean;
    involved : boolean;
    
    caseNumber : string | null;
    courtNumber : string | null;
    court : string | null;
    originalCourtDate : Moment | null;
    magistrate : string | null;
    prosecutor : string | null;
    status : EnumIncidentCourtCaseStatus | null;
    dateOfVerdict : Moment | null;
    dateOfSentence : Moment | null;
    nextCourtDate : Moment | null;
}

export default class IncidentCourtCaseDialog extends React.Component<IIncidentCourtCaseDialogProps, IIncidentCourtCaseDialogState> {
    constructor(props : IIncidentCourtCaseDialogProps) {
        super(props);
        this.state = {
            open: false,
            involved: false,
            courtNumber: null,
            caseNumber: null,
            court: null,
            originalCourtDate: null,
            magistrate: null,
            prosecutor: null,
            status: null,
            dateOfVerdict: null,
            dateOfSentence: null,
            nextCourtDate: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IIncidentCourtCaseDialogProps, prevState : IIncidentCourtCaseDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                involved: false,
                caseNumber: null,
                courtNumber: null,
                court: null,
                originalCourtDate: null,
                magistrate: null,
                prosecutor: null,
                status: null,
                dateOfVerdict: null,
                dateOfSentence: null,
                nextCourtDate: null,
            });
        }
        
        if (!prevState.open && this.state.open && this.props.incident.courtCase?.isActive) {
            const {
                caseNumber,
                courtNumber,
                court,
                magistrate,
                prosecutor,
                status,
                originalCourtDate,
                dateOfSentence,
                dateOfVerdict,
                nextCourtDate,
            } = this.props.incident.courtCase;

            this.setState({
                involved: !!this.props.incident.courtCase,
                caseNumber: caseNumber ?? null,
                courtNumber: courtNumber ?? null,
                court: court ?? null,
                magistrate: magistrate ?? null,
                prosecutor: prosecutor ?? null,
                status: status ?? null,
                originalCourtDate: !originalCourtDate ? null : moment.utc(originalCourtDate),
                dateOfSentence: !dateOfSentence ? null : moment.utc(dateOfSentence),
                dateOfVerdict: !dateOfVerdict ? null : moment.utc(dateOfVerdict),
                nextCourtDate: !nextCourtDate ? null : moment.utc(nextCourtDate),
            });
        }
    }

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private readonly save = async () => {
        const { 
            involved,
            caseNumber,
            courtNumber,
            court,
            magistrate,
            prosecutor,
            status,
            originalCourtDate,
            dateOfSentence,
            dateOfVerdict,
            nextCourtDate,
        } = this.state;

        const incident = {
            ...this.props.incident,
        };

        if (!incident) return;

        if (!involved) {
            incident.courtCase = null;
        } else {
            incident.courtCase = {
                ...incident.courtCase,
                caseNumber,
                court,
                courtNumber,
                dateOfSentence: dateOfSentence?.toISOString() ?? null,
                dateOfVerdict: dateOfVerdict?.toISOString() ?? null,
                magistrate,
                originalCourtDate: originalCourtDate?.toISOString() ?? null,
                nextCourtDate: nextCourtDate?.toISOString() ?? null,
                prosecutor,
                status,
                incidentCourtCaseAccuseds: incident.courtCase?.incidentCourtCaseAccuseds?.length ? [...incident.courtCase?.incidentCourtCaseAccuseds] : incident.suspects.map((suspect) => ({
                    id: 0,
                    name: suspect.name,
                    representative: null,
                    sentence: null,
                    charges: null,
                    surname: suspect.surname,
                    verdict: null,
                    isActive: true,
                })),
            } as IIncidentCourtCase;
        }

        const result = await IncidentFunctions.save(incident);

        if (result) {
            this.onClose();
        }
    }

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({
            open: true,
        });
    }

    /**
     * Stops expansion panel from going crazy
     */
    private readonly onClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    }

    private onSapsInvolvedChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            involved: checked,
        });

        if (!checked) {
            this.setState({
                caseNumber: null,
                courtNumber: null,
                court: null,
                originalCourtDate: null,
                nextCourtDate: null,
                magistrate: null,
                prosecutor: null,
                status: null,
                dateOfVerdict: null,
                dateOfSentence: null,
            });
        }
    }

    private readonly onCaseNumberChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            caseNumber: event.currentTarget.value,
        });
    }

    private readonly onCourtNumberChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            courtNumber: event.currentTarget.value,
        });
    }

    private readonly onCourtChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            court: event.currentTarget.value,
        });
    }

    private readonly onOriginalCourtDateChange = (momentDate : Moment | null) => {
        const date = momentDate?.startOf('day');

        this.setState({
            originalCourtDate: date?.asUTC() ?? null,
        });
    }

    private readonly onNextCourtDateChange = (momentDate : Moment | null) => {
        const date = momentDate?.startOf('day');

        this.setState({
            nextCourtDate: date?.asUTC() ?? null,
        });
    }

    private readonly onMagistrateChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            magistrate: event.currentTarget.value,
        });
    }

    private readonly onProsecutorChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            prosecutor: event.currentTarget.value,
        });
    }

    private readonly onStatusChange = (value : EnumIncidentCourtCaseStatus | null) => {
        this.setState({
            status: value,
        });
    }

    private readonly onDateOfVerdictChange = (momentDate : Moment | null) => {
        const date = momentDate?.startOf('day');

        this.setState({
            dateOfVerdict: date?.asUTC() ?? null,
        });
    }

    private readonly onDateOfSentenceChange = (momentDate : Moment | null) => {
        const date = momentDate?.startOf('day');

        this.setState({
            dateOfSentence: date?.asUTC() ?? null,
        });
    }


    public render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            isLoading
        } = this.props;

        const {
            open,
            involved,
            caseNumber,
            courtNumber,
            court,
            magistrate,
            prosecutor,
            status,
            originalCourtDate,
            dateOfSentence,
            dateOfVerdict,
            nextCourtDate
        } = this.state;

        return (
            <div onClick={this.onClick}>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='secondary' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='saps-info-title'
                    aria-describedby='saps-info-description'
                    className='hfill'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Court Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className='flx1 fdc'>
                                <div className='fdr aic m5'>
                                    <FormControlLabel
                                        labelPlacement='start'
                                        className='cp'
                                        control={
                                            <Switch
                                                checked={involved}
                                                color='primary'
                                                onChange={this.onSapsInvolvedChange}/>
                                        }
                                        label={`Court Case Info?`}/>
                                </div>
                                {
                                    involved &&
                                    <>
                                        <div className='fdr aifs'>
                                            <div className={'fdc flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='caseNumber-input'>CAS Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='caseNumber-input'
                                                        value={caseNumber ?? ''}
                                                        onChange={this.onCaseNumberChange}
                                                        aria-describedby='caseNumber-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='courtNumber-input'>Court Number</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='courtNumber-input'
                                                        value={courtNumber ?? ''}
                                                        onChange={this.onCourtNumberChange}
                                                        aria-describedby='courtNumber-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='court-input'>Court</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='court-input'
                                                        value={court ?? ''}
                                                        onChange={this.onCourtChange}
                                                        aria-describedby='court-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aifs'>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        value={originalCourtDate}
                                                        onChange={this.onOriginalCourtDateChange}
                                                        format={'YYYY-MM-DD'}
                                                        label='Original Court Date'
                                                        id='originalCourtDate'
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='magistrate-input'>Magistrate</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='magistrate-input'
                                                        value={magistrate ?? ''}
                                                        onChange={this.onMagistrateChange}
                                                        aria-describedby='magistrate-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='prosecutor-input'>Prosecutor</InputLabel>
                                                    <Input
                                                        fullWidth
                                                        id='prosecutor-input'
                                                        value={prosecutor ?? ''}
                                                        onChange={this.onProsecutorChange}
                                                        aria-describedby='prosecutor-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aifs'>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <IncidentCourtCaseStatusAutocomplete
                                                        value={status}
                                                        onChange={this.onStatusChange}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        value={dateOfVerdict}
                                                        onChange={this.onDateOfVerdictChange}
                                                        format={'YYYY-MM-DD'}
                                                        label='Date Of Verdict'
                                                        id='dateOfVerdict'
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        value={dateOfSentence}
                                                        onChange={this.onDateOfSentenceChange}
                                                        format={'YYYY-MM-DD'}
                                                        label='Date Of Sentence'
                                                        id='dateOfSentence'
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aifs'>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        value={nextCourtDate}
                                                        onChange={this.onNextCourtDateChange}
                                                        format={'YYYY-MM-DD'}
                                                        label='Next Court Date'
                                                        id='nextCourtDate'
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'}>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }
}
