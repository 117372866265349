import React from 'react';
import { createSelector } from 'reselect';
import { IAuthState } from '../../../store/auth/reducer';
import { EnumRightType, IUserRight } from '../../../@types/model/right';
import NavMenuListItem from '../../component/custom/listItem/NavMenuListItem';
import { RIGHT_TO_ICON } from '../../../appConstants';
import NavMenuItem from '../../component/custom/listItem/NavMenuItem';

interface IAccessEventNavMenuProps {
    auth : IAuthState;
    onClick : (url : string) => void;
}

interface IAccessEventNavMenuState {}

export default class AccessEventNavMenu extends React.PureComponent<IAccessEventNavMenuProps, IAccessEventNavMenuState> {
    constructor(props : IAccessEventNavMenuProps) {
        super(props);
        this.state = {};
    }
    public getUserRights = (props : IAccessEventNavMenuProps) => props.auth.session?.user.userRights ?? [];
    public getAccessRights = createSelector(
        [this.getUserRights],
        (userRights : Array<IUserRight>) => {
            return userRights.filter(x => (
                x.right?.type === EnumRightType.AccessPointEvent
                && x.right.isMenu
                && x.right.parentId
                && x.isActive
            ));
        },
    );

    public render = () => {
        const {
            onClick,
        } = this.props;

        const rights = this.getAccessRights(this.props);

        return (
            !!rights.length &&
            <NavMenuListItem
                key={`access_nav_header`}
                icon={`${ASSET_BASE}/assets/images/icons/camera.svg`}
                imgProps={{
                    className: 'h24',
                }}
                title={'Access Event'}>
                    {
                        rights.map((o) => {
                            return (
                                <NavMenuItem userRight={o} key={`${o.right?.name}_Nav`} imgProps={{
                                    className: 'h30 w30',
                                }} onClick={onClick} icon={RIGHT_TO_ICON[o.right?.code ?? '']} />
                            );
                        })
                    }
            </NavMenuListItem>
        );
    }
}
