import React from 'react';

interface IOutsideClickerProps { 
    onClickedOutside ?: () => void;
}

export default class OutsideClicker extends React.Component<IOutsideClickerProps> {
    private wrapperRef : any;

    constructor(props : IOutsideClickerProps) {
        super(props);
        
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    public onClick = (e : MouseEvent) => {
        if (!this.props.onClickedOutside) return;
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.props.onClickedOutside();
        }
    }

    public componentDidMount() {
        document.addEventListener('mousedown', e => this.onClick(e));
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', e => this.onClick(e));
    }

    public setWrapperRef(node : any) {
        this.wrapperRef = node;
    }

    public render() {
        return <div ref={this.wrapperRef}>{this.props.children}</div>
    }
}