import axios, { AxiosPromise } from 'axios';
import { parseParams } from '../helper/functionHelperService';
import { IUserNotificationSubscriptions, IUserListView, IUser } from '../../@types/model/right';

export default class UserHttpService {
    public static setUserFCMToken(fcmToken : string | null) : AxiosPromise {
        return axios.post(`${API_URL}/api/Rights/User/SetUserFCMToken`, {}, {
            params: {
                fcmToken,
            },
        });
    }

    public static updateUserNotificationSubscriptions(fcmToken : string, typeIds : Array<number>) : AxiosPromise<Array<IUserNotificationSubscriptions>> {
        return axios.post(`${API_URL}/api/Rights/User/UpdateUserNotificationSubscriptions`, {}, {
            params: {
                fcmToken,
                typeIds,
            },
            paramsSerializer: params => parseParams(params),
        });
    }

    public static getUserList() : AxiosPromise<Array<IUserListView>> {
        return axios.get<Array<IUserListView>>(`${API_URL}/api/Rights/User/GetList`, {
            params : {
            },
        });
    }

    public static get(id : number) : AxiosPromise<IUser | undefined> {
        return axios.get<IUser>(`${API_URL}/api/Rights/User/Get`, {
            params : {
                id,
            },
        });
    }

    public static save(
        userId : number,
        roleId : number,
        employeeNr : string,
        rightIds : Array<number>,
        divisions : Array<string>,
        accessPoints : Array<number>,
        isActive : boolean,
    ) : AxiosPromise<IUser> {
        return axios.post<IUser>(`${API_URL}/api/Rights/User/Save`, {}, {
            params : {
                userId,
                roleId,
                employeeNr,
                rightIds,
                divisions,
                accessPoints,
                isActive,
            },
            paramsSerializer: params => parseParams(params),
        });
    }
}
