import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import React from 'react';

export type GoogleSignInProps = Omit<IGoogleLoginProps, 'onSuccess' | 'onError'> ;
export type GoogleSignInComponentProps = IGoogleLoginProps ;

export interface IGoogleLoginSuccess extends Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'> {}
export interface IGoogleLoginError extends Pick<CodeResponse, 'error' | 'error_description' | 'error_uri'> {}

interface IGoogleLoginProps {
    onLoginClick? : () => void;

    onSuccess? : (response : IGoogleLoginSuccess) => void;
    onError? : (response : IGoogleLoginError) => void;
}

// tslint:disable-next-line: variable-name
export function withGoogleSignIn(Component : React.ComponentType<GoogleSignInProps>) {
    return (props : GoogleSignInComponentProps) => {
        const login = useGoogleLogin({
            onSuccess: props.onSuccess,
            onError: props.onError,
            flow: 'auth-code',
            scope: 'profile email openid',
        });

        const onLoginClick = () => {
            login();

            if (props.onLoginClick) props.onLoginClick();
        };

        return <Component {...props} onLoginClick={onLoginClick} />;
    };
}
