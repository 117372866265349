import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { IIncident } from '../../../../../@types/model/incident';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import IncidentFunctions from '../../../../../store/incident/functions';
import SpokenLanguageDropdown from '../../../custom/dropdowns/SpokenLanguageDropdown';

interface IIncidentComplainantEditDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IIncidentComplainantEditDialogState {
    open : boolean;

    name : string;
    surname : string;
    employeeNr : string;
    address : string;
    tel : string;
    cel : string;
    language : string;
}

export default class IncidentComplainantEditDialog extends React.Component<IIncidentComplainantEditDialogProps, IIncidentComplainantEditDialogState> {
    constructor(props : IIncidentComplainantEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            surname: '',
            employeeNr: '',
            address: '',
            tel: '',
            cel: '',
            language: '',
        };
    }

    public componentDidUpdate = (prevProps : IIncidentComplainantEditDialogProps, prevState : IIncidentComplainantEditDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                name: '',
                surname: '',
                employeeNr: '',
                address: '',
                tel: '',
                cel: '',
                language: '',
            });
        }
        if (!prevState.open && this.state.open && this.props.incident) {
            this.setState({
                name: !this.props.incident.complainant ? '' : this.props.incident.complainant.name,
                surname: !this.props.incident.complainant ? '' : this.props.incident.complainant.surname,
                employeeNr: !this.props.incident.complainant ? '' : this.props.incident.complainant.employeeNr,
                address: !this.props.incident.complainant ? '' : this.props.incident.complainant.address,
                tel: !this.props.incident.complainant ? '' : this.props.incident.complainant.tel,
                cel: !this.props.incident.complainant ? '' : this.props.incident.complainant.cel,
                language: !this.props.incident.complainant ? '' : this.props.incident.complainant.language,
            });
        }
    }

    private save = async () => {
        const { name, surname, employeeNr, address, tel, cel, language } = this.state;

        const incident = lodash.cloneDeep(this.props.incident);

        if (incident) {

            incident.complainant.name = name;
            incident.complainant.surname = surname;
            incident.complainant.employeeNr = employeeNr;
            incident.complainant.address = address;
            incident.complainant.tel = tel;
            incident.complainant.cel = cel;
            incident.complainant.language = language;

            const result = await IncidentFunctions.save(incident);

            if (result) {
                this.onClose();
            }
        }
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    private onNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    }

    private onSurnameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            surname: event.currentTarget.value,
        });
    }

    private onEmployeeNrChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            employeeNr: event.currentTarget.value,
        });
    }

    private onAddressChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            address: event.currentTarget.value,
        });
    }

    private onTelChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            tel: event.currentTarget.value,
        });
    }

    private onCelChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            cel: event.currentTarget.value,
        });
    }

    private onLanguageChange = (language : string) => {
        this.setState({
            language,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, name, surname, employeeNr, address, tel, cel, language } = this.state;
        return (<React.Fragment>
            <Tooltip title='Edit'>
                <div>
                    <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='complainant-info-title'
                aria-describedby='complainant-info-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Complainant Info
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 hfill'>
                        <div className='flx1 fdr aifs'>
                            <div className='fdc flx1'>
                                <div className='fdr aifs'>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel required error={!name} htmlFor='name-input'>Name</InputLabel>
                                            <Input
                                                autoFocus
                                                id='name-input'
                                                value={name}
                                                onChange={this.onNameChange}
                                                aria-describedby='name-input-helper-text'
                                                required
                                                error={!name}
                                            />
                                            {
                                                !name &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel required error={!surname} htmlFor='surname-input'>Surname</InputLabel>
                                            <Input
                                                id='surname-input'
                                                value={surname}
                                                onChange={this.onSurnameChange}
                                                aria-describedby='surname-input-helper-text'
                                                required
                                                error={!surname}
                                            />
                                            {
                                                !surname &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='employeeNr-input'>Employee Nr</InputLabel>
                                            <Input
                                                id='employeeNr-input'
                                                value={employeeNr}
                                                onChange={this.onEmployeeNrChange}
                                                aria-describedby='employeeNr-input-helper-text'
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='fdr aifs'>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel error={!tel} htmlFor='tel-input'>Tel</InputLabel>
                                            <Input
                                                id='tel-input'
                                                value={tel}
                                                onChange={this.onTelChange}
                                                aria-describedby='tel-input-helper-text'
                                                error={!tel}
                                                placeholder='0121231234'
                                                inputProps={{
                                                    pattern: '[0-9]{10}',
                                                }}
                                            />
                                            {
                                                !tel &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel error={!cel} htmlFor='cel-input'>Cel</InputLabel>
                                            <Input
                                                id='cel-input'
                                                value={cel}
                                                onChange={this.onCelChange}
                                                aria-describedby='cel-input-helper-text'
                                                error={!cel}
                                                placeholder='0121231234'
                                                inputProps={{
                                                    pattern: '[0-9]{10}',
                                                }}
                                            />
                                            {
                                                !cel &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <SpokenLanguageDropdown fullWidth value={language} onChange={this.onLanguageChange} required />
                                    </div>
                                </div>
                                <div className='fdr aifs'>
                                    <div className={'aic p5 mb10 flx2'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel required error={!address} htmlFor='address-input'>Address</InputLabel>
                                            <Input
                                                id='address-input'
                                                value={address}
                                                onChange={this.onAddressChange}
                                                aria-describedby='address-input-helper-text'
                                                required
                                                error={!address}
                                                multiline
                                                maxRows='5'
                                            />
                                            {
                                                !address &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading || !name || !surname || !employeeNr || !address || !tel || !cel} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>);
    }
}
