
import React from 'react';
import { ILatLng } from '../../../../@types/model/base';
import GoogleMap from '../mapItem/GoogleMap';
import { Marker } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { IRootState } from '../../../../@types/redux';

interface ILocationInputProps {
    id? : string;
    geo : ILatLng;
    onChange? : (geo : ILatLng) => void;

    showMap : boolean;
}

interface ILocationInputState {}

class LocationInputClass extends React.Component<ILocationInputProps, ILocationInputState> {
    private readonly mapRef : React.RefObject<GoogleMap>;
    private center : {
        lat : number,
        lng : number,
    };
    private zoom = 6.15;

    constructor(props : ILocationInputProps) {
        super(props);

        this.center = {
            lat: props.geo.latitude,
            lng: props.geo.longitude,
        };

        this.state = {
            geo: props.geo,
        };

        this.mapRef = React.createRef<GoogleMap>();
    }

    public readonly componentDidUpdate = (prevProps : ILocationInputProps) => {
        if (prevProps.geo !== this.props.geo && this.mapRef && this.mapRef.current) {
            this.mapRef.current.panTo({ lat: this.props.geo.latitude ?? 0, lng: this.props.geo.longitude ?? 0 });
        }
    }

    private onMapClick = (e : google.maps.MapMouseEvent) => {
        if (this.props.onChange) {
            this.props.onChange({
                latitude: e.latLng?.lat() ?? 0,
                longitude: e.latLng?.lng() ?? 0,
            });
        }
    }

    public render = () => {
        const { } = this.state;
        const { geo, id, showMap } = this.props;
        return (
            <div className={'hfill flx1 fdc'}>
                <div className={'hfill flx1'}>
                    <GoogleMap
                        id={id ?? 'main-map'}
                        showMap={showMap}
                        ref={this.mapRef}
                            center={this.center}
                            zoom={this.zoom}
                            onClick={this.onMapClick}
                    >
                        <Marker
                            position={{
                                lat: geo.latitude,
                                lng: geo.longitude,
                            }}/>
                    </GoogleMap>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        showMap : !state.general.isLoadingMap,
    };
};

// tslint:disable-next-line: variable-name
const LocationInput = connect(
    mapStateToProps,
)(LocationInputClass);

export default LocationInput;
