import moment from 'moment';

declare module 'moment' {
    // tslint:disable-next-line: interface-name
    export interface Moment {
        asUTC() : moment.Moment;
    }
}

moment.prototype.asUTC = function () : moment.Moment {
    const self = this as moment.Moment;
    return moment.utc(self.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD  HH:mm');
};

declare global {
    // tslint:disable-next-line: interface-name
    interface String {
        toTitleCase() : string;
    }
}

if (!String.prototype.toTitleCase) {
    String.prototype.toTitleCase = function () : string {
        const splitStrings = String(this).split(' ');
        const result : Array<string> = splitStrings
            .filter(n => n.length > 0)
            .map(n => n[0].toLocaleUpperCase() + n.substring(1).toLocaleLowerCase());

        return result.join(' ');
    };
}