import React from 'react';
import AppFunctions from '../../../../../service/appFunctions';
import RightsSelector from '../../../custom/selector/rights/RightsSelector';

interface IUserRightTabProps {
    onRightsChange : (ids : Array<number>) => void;


    value : Array<number>;
}

interface IUserRightTabState {}

export default class UserRightTab extends React.PureComponent<IUserRightTabProps, IUserRightTabState> {
    constructor(props : IUserRightTabProps) {
        super(props);
        this.state = {};
    }

    private readonly onRightSelected = (id : number, checked : boolean, children : Array<number>) => {
        let value = this.props.value.slice();

        if (checked && value.indexOf(id) === -1) {
            value = AppFunctions.addArrayElement(value, id);
        } else if (!checked) {
            value = AppFunctions.removeArrayElement(value, value.indexOf(id));

            children.forEach((x) => {
                value = AppFunctions.removeArrayElement(value, value.indexOf(x));
            });
        }

        this.props.onRightsChange(value);
    }

    public render = () => {
        const { 
            value,
        } = this.props;
        return (
            <RightsSelector selectedIds={value} onSelected={this.onRightSelected} />
        );
    }
}
