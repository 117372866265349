import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IGraveVisitReason } from '../../../@types/model/masterData/graveVisitReason';

export default class GraveVisitReasonHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel current Person request
     */
    public static cancelGet? : Canceler;

    public static getAll() : AxiosPromise<Array<IGraveVisitReason>> {
        if (GraveVisitReasonHttpService.cancelGet) {
            GraveVisitReasonHttpService.cancelGet();
        }
        return axios.get(`${API_URL}/api/MasterData/GraveVisitReason/GetAll`, {
            cancelToken: new GraveVisitReasonHttpService.CancelToken((c) => {
                GraveVisitReasonHttpService.cancelGet = c;
            }),
            params: {
            },
        });
    }

    public static save(graveSite : IGraveVisitReason) {
        return axios.post<IGraveVisitReason>(`${API_URL}/api/MasterData/GraveVisitReason/Save`, graveSite);
    }

}
