import { createAction } from 'typesafe-actions';
import { IAccessPoint } from '../../@types/model/access';
import { ISAPSStation } from '../../@types/model/sapsStation';
import { IIncidentCategory } from '../../@types/model/incident';
import { ICustomer } from '../../@types/model/customer';
import { IIncidentGroup } from '../../@types/model/masterData/incidentGroup';

const dataActions = {
    setIsLoading: createAction('DATA_SET_LOADING', resolve => (isLoading : boolean) => resolve(isLoading)),
    setAccessPoints: createAction('DATA_SET_ACCESS_POINTS', resolve => (accessPoints : Array<IAccessPoint>) => resolve(accessPoints)),
    setAccessPoint: createAction('DATA_SET_ACCESS_POINT', resolve => (accessPoint : IAccessPoint) => resolve(accessPoint)),
    setSAPSStations: createAction('DATA_SET_SAPS_STATIONS', resolve => (stations : Array<ISAPSStation>) => resolve(stations)),
    setLoadingIncidentCategories: createAction('DATA_SET_LOADING_INCIDENT_CATEGORIES', resolve => (isLoading : boolean) => resolve(isLoading)),
    setIncidentCategories: createAction('DATA_SET_INCIDENT_CATEGORIES', resolve => (list : Array<IIncidentCategory>) => resolve(list)),
    setIsLoadingDivisions: createAction('DATA_SET_LOADING_DIVISIONS', resolve => (isLoading : boolean) => resolve(isLoading)),
    setDivisions: createAction('DATA_SET_DIVISIONS', resolve => (list : Array<ICustomer>) => resolve(list)),
    setLoadingIncidentGroups: createAction('DATA_SET_LOADING_INCIDENT_GROUPS', resolve => (isLoading : boolean) => resolve(isLoading)),
    setIncidentGroups: createAction('DATA_SET_INCIDENT_GROUPS', resolve => (list : Array<IIncidentGroup>) => resolve(list)),
};

export default dataActions;
