import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { v4 } from 'uuid';
import DataFunctions from '../../../../store/data/functions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { IGraveVisitReason } from '../../../../@types/model/masterData/graveVisitReason';
import MasterDataFunctions from '../../../../store/masterData/functions';

interface IEditGraveVisitReasonProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    graveVisitReason? : IGraveVisitReason;

    initialCode? : string;
}

interface IEditGraveVisitReasonState {
    open : boolean;
    description : string;
    guid : string;
    isActive : boolean;
}

export default class EditGraveVisitReason extends React.Component<IEditGraveVisitReasonProps, IEditGraveVisitReasonState> {
    constructor(props : IEditGraveVisitReasonProps) {
        super(props);
        this.state = {
            open: false,
            description: '',
            guid: v4(),
            isActive: true,
        };
    }

    public componentDidUpdate = (prevProps : IEditGraveVisitReasonProps, prevState : IEditGraveVisitReasonState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                description: '',
                guid: v4(),
                isActive: true,
            });
        }

        if (!prevState.open && this.state.open && this.props.graveVisitReason) {
            this.setState({
                description: this.props.graveVisitReason.description,
                guid: !this.props.graveVisitReason.guid ? v4() : this.props.graveVisitReason.guid,
                isActive: this.props.graveVisitReason.isActive,
            });
        }
    }

    private save = async () => {
        const { description, guid, isActive } = this.state;

        if (!description) return;
        if (!guid) return;

        const graveVisitReason = {
            ...this.props.graveVisitReason,
            description,
            guid,
            isActive,
        } as IGraveVisitReason;

        const result = await MasterDataFunctions.saveGraveVisitReason(graveVisitReason);

        if (result) {
            this.onClose();
        }
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onDescriptionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            description: event.currentTarget.value,
        });
    }

    private onIsActiveChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            isActive: checked,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, graveVisitReason } = this.props;
        const { open, description, isActive } = this.state;

        return (

            <React.Fragment>
                <Tooltip title={!graveVisitReason ? 'Add' : 'Edit'}>
                    <div>
                        <IconButton color='primary' onClick={this.onEditClick} aria-label={!graveVisitReason ? 'Add' : 'Edit'} disabled={isLoading}>
                            <Icon>{!graveVisitReason ? 'add' : 'edit'}</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='grave-visit-reason-edit-dialog-title'
                    aria-describedby='grave-visit-reason-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {!graveVisitReason ? 'New' : 'Edit'} Grave Visit Reason
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='description'
                                            label='Description'
                                            value={description}
                                            onChange={this.onDescriptionChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!description}
                                        />
                                        {
                                            !description &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color='primary' checked={isActive} onChange={this.onIsActiveChanged}  />
                                            }
                                            label='IsActive?'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}
