import React from 'react';
import Dropzone from 'react-dropzone';
import { fileTypeToEnumFileType } from '../../../../service/helper/functionHelperService';
import { EnumFileType } from '../../../../@types/model/base';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Typography } from '@material-ui/core';
import DocumentFileView from './DocumentFileView';

interface IDocumentFileUploadProps {
    value : Array<IFileUpload>;

    onFileChange? : (files : Array<IFileUpload>) => void;

    disabled? : boolean;

    accept : string;

    label : string;
}

interface IDocumentFileUploadState {
}

export default class DocumentFileUpload extends React.Component<IDocumentFileUploadProps, IDocumentFileUploadState> {
    constructor(props : IDocumentFileUploadProps) {
        super(props);
        this.state = {
        };
    }

    private onFileDrop = (acceptedFiles : Array<File>) => {
        if (!this.props.onFileChange) return;
        const files : Array<IFileUpload> = [];

        acceptedFiles.forEach((file) => {
            switch (fileTypeToEnumFileType(file.type)) {
                case EnumFileType.Audio:
                case EnumFileType.Video:
                    break;
                default:
                    files.push({
                        name: file.name,
                        file,
                        type: fileTypeToEnumFileType(file.type),
                        duration: null,
                    });
            }
        });

        this.props.onFileChange(files);
    }

    private readonly onRemoveFile = (file : IFileUpload, index : number) => {
        if (!this.props.onFileChange) return;
        const files = this.props.value.slice();
        files.splice(index, 1);
        this.props.onFileChange(files);
    }

    public render = () => {
        const { disabled, accept, value, onFileChange, label } = this.props;
        const { } = this.state;
        return (
            <div className={`fdc hfill wfill ${!onFileChange ? '' : 'cursorPointer'}`}>
                <Dropzone onDrop={!onFileChange ? undefined : this.onFileDrop} accept={accept}>
                    {({ getRootProps, getInputProps }) => (
                        <section className='fdc hfill wfill'>
                            <div className={`fdc hfill wfill container`} {...getRootProps()}>
                                <input disabled={disabled || !onFileChange} multiple={false} {...getInputProps()} />
                                <div className='fdc flx1 aic jcc'>
                                    {
                                        !value.length &&
                                        <AttachFileIcon className='' />
                                    }
                                    {
                                        !!value.length &&
                                        value.map((n, i) => (
                                            <DocumentFileView
                                                key={i}
                                                index={i}
                                                value={n}
                                            />
                                        ))
                                    }
                                </div>
                                <div className={'overlay posa bcg2 fdc aic jcc show'}>
                                    <Typography className={'fwb fs19 cw pb10'}>
                                        {label}
                                    </Typography>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        );
    }
}
