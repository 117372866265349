import * as React from 'react';
import NavBar from './modules/root/NavBar';
import NavDrawer from './modules/root/NavDrawer';
import Routes from './modules/root/Routes';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import { IRootState } from './@types/redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { IAuthState } from './store/auth/reducer';
import Login from './modules/root/Login';
import UserInfoDialog from './modules/root/UserInfoDialog';
import FileUploadProgressIndicator from './modules/component/custom/progress/FileUploadProgressIndicator';
import { IFileQueue } from './@types/viewmodel/file';
import ScriptLoader from './modules/root/ScriptLoader';
import AuthFunctions from './store/auth/functions';

interface IAppProps extends RouteComponentProps {
    auth : IAuthState;

    uploadProgress : number;
    files : Array<IFileQueue>;
    currentFile? : string;
}

interface IAppState {
    isLeftDrawerOpen : boolean;
    isUserInfoOpen : boolean;
}

class App extends React.Component<IAppProps, IAppState> {
    constructor(props : IAppProps) {
        super(props);

        this.state = {
            isLeftDrawerOpen: false,
            isUserInfoOpen: false,
        };
    }

    public componentDidMount() : void {
        AuthFunctions.init();
    }

    public toggleLeftDrawer = () => {
        this.setState({ isLeftDrawerOpen: !this.state.isLeftDrawerOpen });
    }

    public toggleUserInfo = () => {
        this.setState({ isUserInfoOpen: !this.state.isUserInfoOpen });
    }

    public render() {
        const { uploadProgress, files, currentFile } = this.props;
        return (
                <div className={'fdc hfill mnh0'}>
                    <NavBar toggleLeftDrawer={this.toggleLeftDrawer} toggleUserInfo={this.toggleUserInfo} />
                    <div className={'fdc hfill'}>
                    <NavDrawer key='leftmenu' toggleDrawer={this.toggleLeftDrawer} isOpen={this.state.isLeftDrawerOpen} /> {/* For Navigation */}
                    {
                        this.props.auth.session && !this.props.auth.isLoggingIn ? (
                            <React.Fragment>
                                <Routes />
                                <FileUploadProgressIndicator progress={uploadProgress} files={files} current={currentFile} />
                            </React.Fragment>
                        ) : (
                            <Login />
                        )
                    }
                    <SnackbarNotifier />
                    <ScriptLoader />
                    {
                        this.props.auth.session &&
                        <UserInfoDialog maxWidth='sm' fullWidth title={this.props.auth.session.user.name} open={this.state.isUserInfoOpen} onClose={this.toggleUserInfo} />
                    }
                    </div>
                </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        files: state.file.files,
        uploadProgress: state.file.uploadProgress,
        currentFile: state.file.current,
    };
};

export default connect(
    mapStateToProps,
)(withRouter(App));
