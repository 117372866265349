import { Card, Icon, IconButton, Typography } from '@material-ui/core';
import { Marker } from '@react-google-maps/api';
import lodash, { xor } from 'lodash';
import moment from 'moment';
import React from 'react';
import { createSelector } from 'reselect';
import { EnumGraveSiteFileType, IGraveSite } from '../../../../@types/model/graveSite/graveSite';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import GraveSiteFunctions from '../../../../store/graveSites/functions';
import FileCarouselDialog from '../../custom/dialog/FileCarouselDialog';
import ImageFileUpload from '../../custom/file/ImageFileUpload';
import FileThumbnail from '../../custom/fileItem/FileThumbnail';
import GoogleMap from '../../custom/mapItem/GoogleMap';
import GraveSiteInfoCircle from '../components/GraveSiteInfoCircle';
import GraveSiteDescriptionEditDialog from '../dialog/EditDescriptionDialog';
import GraveSiteRepresentativeEditDialog from '../dialog/EditRepresentativeDialog';
import GraveSiteLocationEditDialog from '../dialog/EditLocationDialog';

interface IGraveSiteSummaryViewProps {
    graveSite : IGraveSite;
    isGraveSiteAdmin : boolean;
}

interface IGraveSiteSummaryViewState {
    fileIndex? : number;
    repFileIndex? : number;
}

export default class GraveSiteSummaryView extends React.PureComponent<IGraveSiteSummaryViewProps, IGraveSiteSummaryViewState> {
    constructor(props : IGraveSiteSummaryViewProps) {
        super(props);
        this.state = {};
    }

    private readonly onGeneralFileSelected = (files : Array<IFileUpload>) => {
        if (!files.length) return;

        GraveSiteFunctions.generalImageSave(this.props.graveSite.guid, files);
    }

    public readonly getGraveSite = (props : IGraveSiteSummaryViewProps) => props.graveSite;

    public getGeneralFiles = createSelector([
        this.getGraveSite,
    ],
        (graveSite) => {
            return lodash
            .chain(graveSite.files)
            .filter(g => g.isActive)
            .filter(g => g.type === EnumGraveSiteFileType.Image)
            .sort((a, b) => moment.utc(a.createdOn).valueOf() - moment.utc(b.createdOn).valueOf())
            .value();
        },
    );
    private readonly getGraves = createSelector(
        [this.getGraveSite],
        (graveSite) => {
            return graveSite.graves.filter(x => x.isActive);
        },
    );

    public getLastGeneralFile = createSelector(
        [this.getGeneralFiles],
        (files) => {
            return lodash.last(files);
        },
    );

    public onOpenFileCarousel = (index : number) => {
        if (this.props.graveSite) {
            this.setState({
                fileIndex: index,
            });
        }
    }

    public onFileCarouselClose = () => {
        this.setState({
            fileIndex: undefined,
        });
    }

    public onOnGeneralFileDelete = (index : number) => {
        this.setState({
            fileIndex: undefined,
        });

        const files = this.getGeneralFiles(this.props);

        const file = files[index];
        
        GraveSiteFunctions.deleteImageSave(this.props.graveSite.id, file);
    }

    public onOpenRepFileCarousel = (index : number) => {
        if (this.props.graveSite) {
            this.setState({
                repFileIndex: index,
            });
        }
    }

    public onFileRepCarouselClose = () => {
        this.setState({
            repFileIndex: undefined,
        });
    }

    public render = () => {
        const general = this.getGeneralFiles(this.props);
        const lastGeneral = this.getLastGeneralFile(this.props);
        const graves = this.getGraves(this.props);

        const { graveSite } = this.props;
        const { fileIndex } = this.state;
        return (
            <div className={'flx1 hfill fdr bcg1 pt15 pl30 pr30'}>
                <div className={'fdc flx3'}>
                    <div className={'fdr flx2'}>
                        <div className={'fdc flx1 m25'}>
                            <Typography className={'fwm fs20 cpd pb10'}>
                                GENERAL PICTURE OF SITE {graveSite.number}
                            </Typography>
                            <div className={'bcg1 flx1 jcc aic h470 w530'}>

                                {
                                    lastGeneral?.file ?
                                    <FileThumbnail
                                        imageWidth={'h470 w530'}
                                        imageClassName={'h470 w530'}
                                        key={`file_${lastGeneral?.file?.fileName}`}
                                        index={general.length - 1}
                                        file={lastGeneral?.file!}
                                        onOpenFileCarousel={this.onOpenFileCarousel}
                                    />
                                    : <ImageFileUpload accept='image/*' value={[]} onFileChange={this.onGeneralFileSelected} />
                                }
                                <FileCarouselDialog
                                    fullWidth
                                    maxWidth='md'
                                    selectedIndex={fileIndex}
                                    files={general}
                                    open={fileIndex !== undefined}
                                    onClose={this.onFileCarouselClose}
                                    onDelete={this.onOnGeneralFileDelete}
                                />
                            </div>
                        </div>
                        <div className={'fdc flx1'}>
                            <div className={'fdc flx2'}>
                                <Card elevation={5} className={'m10 bcw fdc flx1 br0'}>
                                    <div className={'h80 bco fdr jcsb aic'}>
                                        <Typography className='cw fs18 fwm ml15'>
                                            DESCRIPTION
                                        </Typography>
                                        <GraveSiteDescriptionEditDialog graveSite={graveSite}/>
                                    </div>
                                    <div className={'flx1 p15 oya pb30'}>
                                        <Typography className='fs18 cpla fwn'>
                                            {graveSite.description}
                                        </Typography>
                                    </div>
                                </Card>
                            </div>
                            <div className={'fdc flx1'}>
                                <Card elevation={5} className={'bcp fdr flx1 m10 mb20 br0'}>
                                    <div className={'fdc flx2 mt25 ml25 h110'}>
                                        <div className={'fdr jcsb'}>
                                            <Typography className={'fs18 cw'}>
                                                ZZ2 REPRESENTATIVE
                                            </Typography>
                                            <GraveSiteRepresentativeEditDialog graveSite={graveSite} />
                                        </div>
                                        <Typography className={'fwb fs22 cw'}>
                                            {graveSite.representativeName}
                                        </Typography>
                                        <Typography className={'fs18 cw'}>
                                            {graveSite.representative}
                                        </Typography>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div className={'fdr flx1 jcfs aic pl30 pr30'}>
                        <GraveSiteInfoCircle
                            title={'REGISTERED DATE'}
                            backgroundColor={'bco'}
                            titleColor={'co'}
                        >
                            <Typography className={'cw fwb fs25 pl5 pr5'}>
                                {moment.utc(graveSite.date).local().format('DD MMM YYYY').toLocaleUpperCase()}
                            </Typography>
                        </GraveSiteInfoCircle>
                        <GraveSiteInfoCircle
                            title={'NUMBER OF GRAVES'}
                            backgroundColor={'bcdb'}
                            titleColor={'cdb'}
                        >
                            <Typography className={'cw fwb fs42 '}>
                                {graves.length.toString() ?? '0'}
                            </Typography>
                        </GraveSiteInfoCircle>
                        <GraveSiteInfoCircle
                            title={'IDENTIFIED GRAVES'}
                            backgroundColor={'bcpd'}
                            titleColor={'cpd'}
                        >
                            <Typography className={'cw fwb fs42 '}>
                                {graves.filter(e => e.identified).length.toString() ?? '0'}
                            </Typography>
                        </GraveSiteInfoCircle>
                    </div>
                </div>
                <div className={'fdc flx2 pr15 pb15'}>
                    <div className={'fdc flx1'}>
                        <Card elevation={5} className={'m10 bcw fdc br0'}>
                            <div className={'h80 bclg fdr jcsb aic'}>
                                <Typography className='cw fs18 fwm ml15'>
                                    LOCATION
                                </Typography>
                                <GraveSiteLocationEditDialog graveSite={graveSite} />
                            </div>
                            <div className={'flx1 p15 fdc'}>
                                <div className={'fdr jcsb pt15'}>
                                    <div className={'fdc flx1 ml15'}>
                                        <Typography className={'cpla fwn fs14'}>
                                            Latitude
                                        </Typography>
                                        <Typography className={'cp fwn fs18'}>
                                            {graveSite.geo.latitude?.toFixed(6) ?? 'None'}
                                        </Typography>
                                    </div>
                                    <div className={'fdc flx1 ml15'}>
                                        <Typography className={'cpla fwn fs14'}>
                                            Longitude
                                        </Typography>
                                        <Typography className={'cp fwn fs18'}>
                                            {graveSite.geo.longitude?.toFixed(6) ?? 'None'}
                                        </Typography>
                                    </div>
                                    <div className={'fdc flx1 ml15'}>
                                        <Typography className={'cpla fwn fs14'}>
                                            Province
                                        </Typography>
                                        <Typography className={'cp fwn fs18'}>
                                            {graveSite.division?.province ?? 'None'}
                                        </Typography>
                                    </div>
                                    <div className={'fdc flx1'}>
                                        <Typography className={'cpla fwn fs14'}>
                                            Region
                                        </Typography>
                                        <Typography className={'cp fwn fs18'}>
                                            {graveSite.division?.regionName ?? 'None'}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={'fdr pt30'}>
                                    <div className={'fdc flx1 ml15'}>
                                        <Typography className={'cpla fwn fs14'}>
                                            Division
                                        </Typography>
                                        <Typography className={'cp fwn fs18'}>
                                            {graveSite.division?.description ?? 'None'}
                                        </Typography>
                                    </div>
                                    <div className={'fdc flx1 ml15'}>
                                        <Typography className={'cpla fwn fs14'}>
                                            Identified
                                        </Typography>
                                        <Typography className={'cp fwn fs18'}>
                                            {
                                                !!graveSite.identified &&
                                                <Icon className={'cp'}>
                                                    check_circle
                                                </Icon>
                                            }
                                            {
                                                !graveSite.identified &&
                                                <Icon className={'cpr'}>
                                                    cancel_outlined
                                                </Icon>
                                            }
                                        </Typography>
                                    </div>
                                    <div className={'fdc flx1 ml15'}>
                                    </div>
                                    <div className={'fdc flx1'}>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className={'fdc flx2'}>
                        <Card elevation={5} className={'m10 bcw fdc flx2 br0'}>
                            <div className={'h80 bcp fdr jcsb aic'}>
                                <Typography className='cw fs18 fwm ml15'>
                                    MAP
                                </Typography>
                                <GraveSiteLocationEditDialog graveSite={graveSite} />
                            </div>
                            <div className={'flx1 p15'}>
                                <GoogleMap
                                    showMap
                                    center={{
                                        lat: graveSite.geo.latitude,
                                        lng: graveSite.geo.longitude,
                                    }}
                                    zoom={15}
                                >
                                    <Marker
                                        position={{
                                            lat: graveSite.geo.latitude,
                                            lng: graveSite.geo.longitude,
                                        }}/>
                                </GoogleMap>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}
