import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Button, CircularProgress, DialogActions, DialogContent } from '@material-ui/core';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import { IGraveSite, IGraveSiteUpsert } from '../../../../@types/model/graveSite/graveSite';
import { IGrave, IGraveUpsert } from '../../../../@types/model/graveSite/grave';
import GraveSiteFunctions from '../../../../store/graveSites/functions';
import GraveForm from '../form/GraveForm';

interface IGraveEditDialogProps {
    graveSite : IGraveSite;
    grave : IGrave | null;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    onClose : () => void;
}

interface IGraveEditDialogState {
    open : boolean;
    files : Array<IFileUpload>;
    grave : IGraveUpsert | null;
    isLoading : boolean;
}

export default class GraveEditDialog extends React.Component<IGraveEditDialogProps, IGraveEditDialogState> {
    constructor(props : IGraveEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            files: [],
            grave: null,
            isLoading: false,
        };
    }

    public componentDidUpdate = (prevProps : IGraveEditDialogProps, prevState : IGraveEditDialogState) => {
        if (!prevProps.grave && this.props.grave) {
            this.setState({
                files: [],
                grave: {
                    ...this.props.grave,
                },
                open: true,
            });
        }
        if (prevProps.grave && !this.props.grave) {
            this.setState({
                open: false,
            });
        }
    }

    public onEditClick = (e : React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            open: true,
        });
    }

    private readonly save = async () => {
        const { graveSite } = this.props;
        const { grave, files } = this.state;

        if (!grave) return;

        this.setState({
            isLoading: true,
        });

        const upsert = {
            id: graveSite.id,
            guid: graveSite.guid,
            date: graveSite.date,
            description: graveSite.description,
            divisionId: graveSite.division?.id,
            representative: graveSite.representative,
            representativeName: graveSite.representativeName,
            representativeContact: graveSite.representativeContact,
            number: graveSite.number,
            geo: graveSite.geo,
            isPrivate: graveSite.isPrivate,
            graves: [
                ...graveSite.graves,
            ],
        } as IGraveSiteUpsert;

        const index = graveSite.graves.findIndex(x => x.id === grave.id);
        upsert.graves[index] = {
            ...grave,
        };

        const result = await GraveSiteFunctions.save(upsert, files);

        if (result != null) {
            this.props.onClose();
        }

        this.setState({
            isLoading: false,
        });

    }
    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        this.save();
    }

    private onChange = (grave : IGraveUpsert) => {
        this.setState({
            grave,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullScreen, fullWidth, graveSite, onClose } = this.props;
        const { open, grave, isLoading } = this.state;

        return (<Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='edit-dialog-title'
                aria-describedby='edit-description'>
                <AppBar position='static'>
                    <Toolbar>
                        <Typography variant='h6' color='inherit' className='flx1'>
                            EDIT GRAVE {grave?.number}
                        </Typography>
                        <IconButton color='inherit' onClick={onClose} aria-label='Close' className='aife'>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {
                    !isLoading &&
                    <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
                        <DialogContent className='fdc flx1 oxh'>
                            <AppBar position='static'>
                                <Toolbar>
                                    <Typography variant='h6' color='inherit' className='flx1'>
                                        SITE INFO
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <div className={'fdr flx1 bocg3 p20'}>
                                <div className={'fdc flx1'}>
                                    <Typography variant='caption' color='textPrimary' className='flx1'>
                                        Division No
                                    </Typography>
                                    <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                        {graveSite.division?.code}
                                    </Typography>
                                    <Typography variant='caption' color='textPrimary' className='flx1'>
                                        Site Description
                                    </Typography>
                                    <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                        {graveSite.description}
                                    </Typography>
                                    <Typography variant='caption' color='textPrimary' className='flx1'>
                                        Region
                                    </Typography>
                                    <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                        {graveSite.number}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1'}>
                                <Typography variant='caption' color='textPrimary' className='flx1'>
                                        Registration Date
                                    </Typography>
                                    <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                        {graveSite.createdOn}
                                    </Typography>
                                    <Typography variant='caption' color='textPrimary' className='flx1'>
                                        ZZ2 Representative
                                    </Typography>
                                    <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                        {graveSite.representativeName}
                                    </Typography>
                                    <Typography variant='caption' color='textPrimary' className='flx1'>
                                        ZZ2 Representative Contact
                                    </Typography>
                                    <Typography variant='subtitle1' color='textPrimary' className='flx1 pb20'>
                                        {graveSite.representativeContact}
                                    </Typography>
                                </div>
                            </div>
                            <div className='hfill wfill'>
                                {
                                    grave &&
                                    <GraveForm
                                        grave={grave}
                                        index={0}
                                        onChange={this.onChange}
                                    />
                                }
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='text' onClick={onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                            <Button className='br25 bcy cp' type='submit' variant='contained'>
                                SAVE
                            </Button>
                        </DialogActions>
                    </form>
                }
                {
                    isLoading &&
                    <div className={'fdc hfill aic jcc h150'}>
                        <div className={'aic jcc'}>
                            <CircularProgress color={'primary'} className={''} />
                        </div>
                    </div>
                }
            </Dialog>
        );
    }
}
