import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { v4 } from 'uuid';
import { Button, DialogActions, DialogContent, FormControl, FormHelperText, Icon, Switch, TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import moment from 'moment';
import { IDivision } from '../../../../@types/model/division';
import { ILatLng } from '../../../../@types/model/base';
import DivisionDropdown from '../../custom/dropdowns/DivisionDropdown';
import LocationInput from '../../custom/input/LocationInput';
import { IFileUpload } from '../../../../@types/viewmodel/file';
import { IGraveSiteUpsert } from '../../../../@types/model/graveSite/graveSite';
import { IGraveUpsert } from '../../../../@types/model/graveSite/grave';
import GraveSiteFunctions from '../../../../store/graveSites/functions';
import GraveForm from '../form/GraveForm';
import ImageFileUpload from '../../custom/file/ImageFileUpload';
import NumberWordDiv from '../../custom/div/NumberWordDiv';

interface IGraveSiteCreateDialogProps {
    onClose? : () => void;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
    disabled? : boolean;
}

interface IGraveSiteCreateDialogState {
    guid : string;
    open : boolean;
    date : moment.Moment;
    description : string;
    division? : IDivision;
    files : Array<IFileUpload>;
    geo : ILatLng;
    isPrivate : boolean;
    siteNo : string;
    representative : string;
    representativeContact : string;
    graves : Array<IGraveUpsert>;
}

export default class GraveSiteCreateDialog extends React.Component<IGraveSiteCreateDialogProps, IGraveSiteCreateDialogState> {
    private readonly maxDate : moment.Moment = moment().utc().startOf('day');
    private readonly maxFiles = 3;
    constructor(props : IGraveSiteCreateDialogProps) {
        super(props);
        this.state = {
            guid: v4(),
            open: false,
            date: moment.utc().startOf('day'),
            description: '',
            files: [],
            geo: {
                latitude: 0,
                longitude: 0,
            },
            isPrivate: false,
            siteNo : '',
            representative: '',
            representativeContact: '',
            graves: [],
        };
    }

    public componentDidUpdate = (prevProps : IGraveSiteCreateDialogProps, prevState : IGraveSiteCreateDialogState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                guid: v4(),
                date: moment.utc().startOf('day'),
                description: '',
                files: [],
                geo: {
                    latitude: 0,
                    longitude: 0,
                },
                isPrivate: false,
                siteNo : '',
                division: undefined,
                representative: '',
                representativeContact: '',
                graves: [{
                    id: 0,
                    applicantName: '',
                    applicantContact: '',
                    dateOfBurial: moment.utc().toISOString(),
                    dateOfDeath: moment.utc().toISOString(),
                    registerDate: moment.utc().toISOString(),
                    nameOfDeceased: '',
                    number: 1,
                    graveSiteId: 0,
                    fileNumber: '',
                    identified: true,
                    guid: v4(),
                } as IGraveUpsert],
            });
        }
    }

    public onCreateClick = () => {
        this.setState({
            open: true,
        });
    }

    public readonly onClose = () => {
        this.setState({
            open: false,
            graves: [],
        });
    }

    public readonly addGrave = () => {
        this.setState({
            graves: [
                ...this.state.graves, {
                    id: 0,
                    applicantName: '',
                    applicantContact: '',
                    dateOfBurial: moment.utc().toISOString(),
                    dateOfDeath: moment.utc().toISOString(),
                    registerDate: moment.utc().toISOString(),
                    nameOfDeceased: '',
                    number: this.state.graves.length + 1,
                    graveSiteId: 0,
                    fileNumber: '',
                    identified: true,
                    guid: v4(),
                } as IGraveUpsert,
            ],
        });
    }

    private readonly save = async () => {
        const { guid, date, division, description, siteNo, representative, representativeContact, isPrivate, geo, graves, files } = this.state;

        if (!guid || !date || !description || !division) return;

        const result = await GraveSiteFunctions.create({
            id: 0,
            guid,
            date: date.toISOString(),
            description,
            divisionId: division.id,
            representative,
            representativeName: representative,
            representativeContact,
            number: siteNo,
            geo,
            isPrivate,
            graves,
        } as IGraveSiteUpsert, files);

        if (result != null) {
            this.onClose();
        }

    }
    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private readonly onDateChange = (momentDate : moment.Moment) => {
        if (momentDate && momentDate?.isAfter(this.maxDate)) return;
        const date = momentDate?.startOf('day');

        this.setState({
            date,
        });
    }

    private readonly onDivisionChange = (division? : IDivision | undefined) => {
        this.setState({
            division,
        });
    }

    private readonly onDescriptionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            description: event.currentTarget.value,
        });
    }

    private readonly onSiteNoChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            siteNo: event.currentTarget.value,
        });
    }

    private readonly onRepresentativeNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            representative: event.currentTarget.value,
        });
    }

    private readonly onContactDetailsChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            representativeContact: event.currentTarget.value,
        });
    }

    private onLatitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, latitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) },
        });
    }

    private onLongitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, longitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) ?? null },
        });
    }

    private onLocationChange = (geo : ILatLng) => {
        this.setState({
            geo,
        });
    }

    private readonly onFileSelected = (files : Array<IFileUpload>) => {
        this.setState({
            files,
        });
    }

    private onPrivateCheckedChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            isPrivate: checked,
        });
    }

    private onChange = (gave : IGraveUpsert, index : number) => {
        const newGraves = [...this.state.graves];
        newGraves.splice(index, 1, gave);

        this.setState({
            graves: newGraves,
        });
    }

    public render = () => {
        const { transition, isLoading, disabled, maxWidth, fullScreen, fullWidth } = this.props;
        const { open, date, division, description, geo, files, isPrivate: privateChecked, siteNo, representative, representativeContact, graves } = this.state;

        return (
            <React.Fragment>
                <Tooltip title='Create'>
                    <div>
                        <IconButton disabled={isLoading || disabled} className={'cy standard-box'} onClick={this.onCreateClick}>
                            <AddIcon className={'cp'}/>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Create-dialog-title'
                    aria-describedby='Create-description'>
                    <AppBar position='static'>
                        <Toolbar>
                            <Typography variant='h6' color='inherit' className='flx1'>
                                ADD NEW GRAVESITE
                            </Typography>
                            <IconButton color='inherit' onClick={this.onClose} aria-label='Close' className='aife'>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
                        <DialogContent className='fdc flx1 oxh'>
                            <div className={'flx1 fdr p5 mb10 pr20 wfill jcsb'} style={{ marginTop: '16px' }}>
                                <div className='pr10 flx1'>
                                    <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            value={date}
                                            maxDate={this.maxDate}
                                            onChange={this.onDateChange}
                                            format={'YYYY-MM-DD'}
                                            label='Grave Site Date'
                                            id='GraveSiteDate'
                                            className={'TextField'}
                                            required
                                            margin={'normal'}
                                            error={!!!date}
                                        />
                                        {
                                            !!!date &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className='flx1 jcsb jcfe aife pr15 pl10 pb3'>
                                    <Typography>
                                        Private
                                    </Typography>
                                    <FormControl>
                                        <Switch
                                            checked={privateChecked}
                                            color='primary'
                                            onChange={this.onPrivateCheckedChange}/>
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'flx1 aifs p5 mb10 pr20 fdr jcsb'}>
                                <div className='pr10 flx1'>
                                    <FormControl fullWidth>
                                        <TextField
                                            label={'Site Number'}
                                            id='siteNo-input'
                                            value={siteNo}
                                            onChange={this.onSiteNoChange}
                                            aria-describedby='siteNo-input-helper-text'
                                            required
                                            error={!siteNo}
                                            type='text'
                                        />
                                        {
                                            !siteNo &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className='pl10 flx1'>
                                    <DivisionDropdown fullWidth required onChange={this.onDivisionChange} value={division} />
                                </div>
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20 '} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        id='description-input'
                                        label={'Description'}
                                        value={description}
                                        onChange={this.onDescriptionChange}
                                        aria-describedby='description-input-helper-text'
                                        required
                                        multiline
                                        error={!description}
                                    />
                                    {
                                        !description &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                    {
                                        !!description &&
                                        <span className={'h24'}/>
                                    }
                                </FormControl>
                            </div>
                            <Typography className='cpd fw500 fs18'>
                                REPRESENTATIVE
                            </Typography>
                            <div className='fdr'>
                                <div className={'flx1 ais mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='lat'
                                            label='Employee Number'
                                            value={representative}
                                            onChange={this.onRepresentativeNameChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            type='string'
                                        />
                                        {
                                            !geo?.latitude && !!geo?.longitude &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais mb10'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='long'
                                            label='Contact Details'
                                            value={representativeContact}
                                            onChange={this.onContactDetailsChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            type='string'
                                        />
                                        {
                                            !!geo?.latitude && !geo?.longitude &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <AppBar position='static'>
                                <Toolbar>
                                    <Typography variant='h6' color='inherit' className='flx1 fwb fs22'>
                                        LOCATION
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <div className='fdc h118'>
                                <div className={'fdr h50 bclg jcsb aic'}>
                                    <span className={'flx1 jcc'}>
                                        <Typography className={'fwb fw16 cw'}>
                                            LATITUDE
                                        </Typography>
                                    </span>
                                    <span className={'flx1 jcc'}>
                                        <Typography className={'fwb fw16 cw'}>
                                            LONGITUDE
                                        </Typography>
                                    </span>
                                </div>
                                <div className={'fdr'}>
                                    <div className={'flx1 ais jcc mb10 pr20'}>
                                        <FormControl>
                                            <TextField
                                                autoComplete='off'
                                                id='lat'
                                                label=''
                                                value={geo?.latitude ?? null}
                                                onChange={this.onLatitudeChange}
                                                margin='normal'
                                                className={'TextField'}
                                                required
                                                error={!geo?.latitude && !!geo?.longitude}
                                                type='number'
                                                inputProps={{ className: 'jcc aic tac' }}
                                            />
                                            {
                                                !geo?.latitude && !!geo?.longitude &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 ais mb10 jcc'}>
                                        <FormControl>
                                            <TextField
                                                autoComplete='off'
                                                id='long'
                                                label=''
                                                value={geo?.longitude}
                                                onChange={this.onLongitudeChange}
                                                margin='normal'
                                                className={'TextField'}
                                                required
                                                error={!!geo?.latitude && !geo?.longitude}
                                                type='number'
                                                inputProps={{ className: 'jcc aic tac' }}
                                            />
                                            {
                                                !!geo?.latitude && !geo?.longitude &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className='h200'>
                                <LocationInput geo={geo} onChange={this.onLocationChange} />
                            </div>
                            <AppBar position='static'>
                                <Toolbar>
                                    <Typography variant='h6' color='inherit' className='flx1'>
                                        GENERAL IMAGES
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <div className='fdr aifs mb10'>
                                <div className={'fdc h256 wfill mt10'}>
                                    <ImageFileUpload
                                        accept='image/*'
                                        value={files}
                                        onFileChange={this.onFileSelected}
                                    />
                                </div>
                            </div>
                            <AppBar position='static'>
                                <Toolbar>
                                    <Typography variant='h6' color='inherit' className='flx1'>
                                        ADD GRAVES
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <div className='p10 hfill wfill'>
                                {
                                    graves.map((grave, index) => {
                                        return (
                                            <GraveForm
                                                grave={grave}
                                                index={index}
                                                key={index}
                                                onChange={this.onChange}
                                            />);
                                    })
                                }
                                <div className='fdr flx1 pl10'>
                                    <NumberWordDiv
                                        number={graves.length + 1}
                                    >
                                        GRAVE
                                    </NumberWordDiv>
                                    <IconButton color='inherit' onClick={this.addGrave} className='aife'>
                                        <Icon className='bcp brh p10'>
                                            <AddIcon className='cw'/>
                                        </Icon>
                                    </IconButton>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} variant='text' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                            <Button className='br25 bcy cp' disabled={isLoading} type='submit' variant='contained'>
                                SAVE
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}
