import { TransitionProps } from '@material-ui/core/transitions/transition';
import Slide from '@material-ui/core/Slide';
import React from 'react';

// tslint:disable-next-line: variable-name
const Up = React.forwardRef<unknown, TransitionProps>((props, ref) => {
    return <Slide direction='up' ref={ref} {...props} />;
});

// tslint:disable-next-line: variable-name
const Down = React.forwardRef<unknown, TransitionProps>((props, ref) => {
    return <Slide direction='down' ref={ref} {...props} />;
});

// tslint:disable-next-line: variable-name
const Left = React.forwardRef<unknown, TransitionProps>((props, ref) => {
    return <Slide direction='left' ref={ref} {...props} />;
});

// tslint:disable-next-line: variable-name
const Right = React.forwardRef<unknown, TransitionProps>((props, ref) => {
    return <Slide direction='right' ref={ref} {...props} />;
});

// tslint:disable-next-line: variable-name
export const Transitions = {
    Up,
    Down,
    Left,
    Right,
};
