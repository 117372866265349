import { Typography } from '@material-ui/core';
import numWords from 'num-words';
import React from 'react';
import { createSelector } from 'reselect';

interface INumberWordDivProps {
    number : number;
}

interface INumberWordDivState {}

export default class NumberWordDiv extends React.PureComponent<INumberWordDivProps, INumberWordDivState> {
    constructor(props : INumberWordDivProps) {
        super(props);
        this.state = {};
    }
    public readonly getNumber = (props : INumberWordDivProps) => props.number;

    private readonly getNumberWord = createSelector(
        [this.getNumber],
        (number) => {
            return numWords(number).toLocaleUpperCase();
        },
    );

    public render = () => {
        const { number, children } = this.props;
        const word = this.getNumberWord(this.props);

        return (
            <div className='fdr flx1 pl10'>
                <div className='fdc aic jcc bcp brh h40 w40 posr'>
                    <span className='fwb fs16 cw posa mt1'>
                        {number}
                    </span>
                </div>
                <Typography className='pl10 flx1 cp fs24 fwm'>
                    {children} {word}
                </Typography>
            </div>
        );
    }
}
