import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import lodash from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import { ACCESS_POINT_TYPE_STRING } from '../../../../appConstants';

interface IAccessPointTypeDropdownProps {
    value : number;

    onChange : (value : number) => void;
}

interface IAccessPointTypeDropdownState {}

export default class AccessPointTypeDropdown extends React.Component<IAccessPointTypeDropdownProps, IAccessPointTypeDropdownState> {
    constructor(props : IAccessPointTypeDropdownProps) {
        super(props);
        this.state = {};
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>) => {
        this.props.onChange(Number(event.target.value));
    }

    public render = () => {
        const { value } = this.props;

        return (<FormControl margin='normal' fullWidth>
        <InputLabel htmlFor='accessPointType'>Access Point Type</InputLabel>
        <Select
            fullWidth
            id='accessPointType'
            value={value}
            onChange={this.onChange}>
            {
                lodash.map(ACCESS_POINT_TYPE_STRING, (n, k) => (
                    <MenuItem key={`${n}_accessPointType`} value={k}>
                        {n}
                    </MenuItem>
                ))
            }
        </Select>
    </FormControl>);
    }
}
