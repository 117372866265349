import React from 'react';
import { IFile } from '../../../../@types/model/base';
import ImageThumbnail from './ImageFileThumbnail';
import VideoThumbnail from './VideoFileThumbnail';
import AudioThumbnail from './AudioFileThumbnail';
import UnknownThumbnail from './UnknownThumbnail';

interface IFileThumbnailProps {
    title? : string;
    index? : number;
    file : IFile;

    onOpenFileCarousel? : (index : number, name ?: string) => void;

    className? : string;
    imageClassName? : string;
    imageWidth? : string;
}

interface IFileThumbnailState {
}

class FileThumbnail extends React.Component<IFileThumbnailProps, IFileThumbnailState> {
    public audio : HTMLAudioElement;

    constructor(props : IFileThumbnailProps) {
        super(props);

        this.state = {
        };

    }

    public onOpenFileCarousel = () => {
        if (!!this.props.onOpenFileCarousel && this.props.index !== undefined) {
            this.props.onOpenFileCarousel(this.props.index, this.props.file.fileName);
        }
    }

    public render() {
        const { file, title, className, imageClassName, imageWidth } = this.props;
        return (
            <div className={`fdr aic mb5 ${!!!className ? '' : className}`}>
                {{
                    0 : <ImageThumbnail width={imageWidth} className={imageClassName} title={title} key={`image_${file.id}`} url={file.url} thumbUrl={!file.thumbUrl ? file.url : file.thumbUrl}
                    onOpenFileCarousel={this.onOpenFileCarousel} />,
                    1 : <VideoThumbnail title={title} key={`video_${file.id}`} url={file.url} thumbUrl={!file.thumbUrl ? file.url : file.thumbUrl}
                    onOpenFileCarousel={this.onOpenFileCarousel} />,
                    2 : <AudioThumbnail title={title} key={`audio_${file.id}`} url={file.url} duration={file.duration!}
                    onOpenFileCarousel={this.onOpenFileCarousel} />,
                    3 : (<div>Unknown</div>),
                    4 : (
                        <embed height='100%' width='100%' type='application/pdf' src={file.url} />
                    ),
                }[file.fileType]}
            </div>
        );
    }
}

export default FileThumbnail;
