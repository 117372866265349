import { Typography } from '@material-ui/core';
import { Title } from '@material-ui/icons';
import React from 'react';

interface IGraveSiteInfoCircleProps {
    title : string;
    backgroundColor : string;
    titleColor : string;
}

interface IGraveSiteInfoCircleState {}

export default class GraveSiteInfoCircle extends React.PureComponent<IGraveSiteInfoCircleProps, IGraveSiteInfoCircleState> {
    constructor(props : IGraveSiteInfoCircleProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { title, backgroundColor, titleColor, children } = this.props;
        return (
        <div className={'fdc jcc aic pl30 pr30'}>
            <Typography className={` ${titleColor} fs20 fwb pb30`}>
                {title}
            </Typography>
            <div className={`brh ${backgroundColor} h147 w147 jcc aic tac`}>
                {
                    children
                }
            </div>
        </div>);
    }
}
