import { Tooltip, IconButton, Icon, Dialog, AppBar, Toolbar, Typography, DialogContent, FormControl, DialogActions, Button, FormHelperText, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { ILatLng } from '../../../../@types/model/base';
import { IDivision } from '../../../../@types/model/division';
import { IGraveSite } from '../../../../@types/model/graveSite/graveSite';
import { IRootState } from '../../../../@types/redux';
import GraveSiteFunctions from '../../../../store/graveSites/functions';
import DivisionDropdown from '../../custom/dropdowns/DivisionDropdown';
import LocationInput from '../../custom/input/LocationInput';

interface IGraveSiteLocationEditDialogProps {
    graveSite : IGraveSite;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IGraveSiteLocationEditDialogState {
    open : boolean;

    geo : ILatLng;
    division? : IDivision;
    identified : boolean;
}

class GraveSiteLocationEditDialog extends React.Component<IGraveSiteLocationEditDialogProps, IGraveSiteLocationEditDialogState> {
    constructor(props : IGraveSiteLocationEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            geo: {
                latitude: 0,
                longitude: 0,
            },
            division: undefined,
            identified: false,
        };
    }

    public componentDidUpdate = (prevProps : IGraveSiteLocationEditDialogProps, prevState : IGraveSiteLocationEditDialogState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                geo: this.props.graveSite.geo,
                division: this.props.graveSite.division ?? undefined,
                identified: this.props.graveSite.identified ?? false,
            });
        }
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private save = async () => {
        const { geo, division, identified } = this.state;

        const site = lodash.cloneDeep(this.props.graveSite);

        if (site) {

            site.geo = geo;
            site.division = division ?? null;
            site.identified = identified ?? null;

            const result = await GraveSiteFunctions.save(site, []);

            if (result) {
                this.onClose();
            }
        }
    }

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    private onLatitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, latitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) },
        });
    }

    private onLongitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, longitude: parseFloat(!event.currentTarget.value ? '0' : event.currentTarget.value) ?? null },
        });
    }

    private onLocationChange = (geo : ILatLng) => {
        this.setState({
            geo,
        });
    }

    private readonly onDivisionChange = (division? : IDivision | undefined) => {
        this.setState({
            division,
        });
    }

    private onIdentifiedChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            identified: checked,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, division, geo, identified } = this.state;
        return (<React.Fragment>
            <Tooltip title='Edit'>
                <div className='pr15'>
                    <IconButton color='secondary' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='incident-info-title'
                aria-describedby='incident-info-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Grave Site
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 hfill'>
                        <div className='fdc flx1'>
                            <div className='fdc ais'>
                                <div className='fdc flx1 mr5 pb15'>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={identified ?? false}
                                                color='primary'
                                                onChange={this.onIdentifiedChange}/>
                                        }
                                        label={`Identified?`}/>
                                </div>
                                <div className='flx1 pb15'>
                                    <DivisionDropdown fullWidth required onChange={this.onDivisionChange} value={division} />
                                </div>
                                <AppBar position='static'>
                                    <Toolbar>
                                        <Typography variant='h6' color='inherit' className='flx1 fwb fs22'>
                                            LOCATION
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                <div className='fdc h118'>
                                <div className={'fdr h50 bclg jcsb aic'}>
                                    <span className={'flx1 jcc'}>
                                        <Typography className={'fwb fw16 cw'}>
                                            LATITUDE
                                        </Typography>
                                    </span>
                                    <span className={'flx1 jcc'}>
                                        <Typography className={'fwb fw16 cw'}>
                                            LONGITUDE
                                        </Typography>
                                    </span>
                                </div>
                                <div className={'fdr'}>
                                    <div className={'flx1 ais jcc mb10 pr20'}>
                                        <FormControl>
                                            <TextField
                                                autoComplete='off'
                                                id='lat'
                                                label=''
                                                value={geo?.latitude ?? null}
                                                onChange={this.onLatitudeChange}
                                                margin='normal'
                                                className={'TextField'}
                                                required
                                                error={!geo?.latitude && !!geo?.longitude}
                                                type='number'
                                                inputProps={{ className: 'jcc aic tac' }}
                                            />
                                            {
                                                !geo?.latitude && !!geo?.longitude &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 ais mb10 jcc'}>
                                        <FormControl>
                                            <TextField
                                                autoComplete='off'
                                                id='long'
                                                label=''
                                                value={geo?.longitude}
                                                onChange={this.onLongitudeChange}
                                                margin='normal'
                                                className={'TextField'}
                                                required
                                                error={!!geo?.latitude && !geo?.longitude}
                                                type='number'
                                                inputProps={{ className: 'jcc aic tac' }}
                                            />
                                            {
                                                !!geo?.latitude && !geo?.longitude &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className='h200'>
                                <LocationInput geo={geo} onChange={this.onLocationChange} />
                            </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading || !geo || !division} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>);
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.graveSites.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(GraveSiteLocationEditDialog);
