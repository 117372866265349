import { createAction } from 'typesafe-actions';
import { IIncident, IVirusIncident } from '../../@types/model/incident';

const incidentActions = {
    setIncidents: createAction('INCIDNET_SET_INCIDENTS', resolve => (incidents : Array<IIncident>) => resolve(incidents)),
    setSelectedIncident: createAction('INCIDNET_SET_SELECTED_INCIDENT', resolve => (incident? : IIncident) => resolve(incident)),
    setEditIncident: createAction('INCIDNET_SET_EDIT_INCIDENT', resolve => (incident? : IIncident) => resolve(incident)),
    setIncidentLoading: createAction('INCIDNET_SET_INCIDENT_LOADING', resolve => (loading : boolean) => resolve(loading)),
    setIncidentSaving: createAction('INCIDNET_SET_INCIDENT_SAVING', resolve => (loading : boolean) => resolve(loading)),
    setCovid19Incidents: createAction('INCIDNET_SET_COVID19_INCIDENTS', resolve => (incidents : Array<IVirusIncident>) => resolve(incidents)),
    setSelectedVirusIncident: createAction('INCIDNET_SET_SELECTED_VIRUS_INCIDENT', resolve => (incident? : IVirusIncident) => resolve(incident)),
    setLoadingCovid19Incidents: createAction('INCIDNET_SET_COVID19_INCIDENT_LOADING', resolve => (loading : boolean) => resolve(loading)),
};

export default incidentActions;
