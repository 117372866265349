import DeleteSvg from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { IFileUpload } from '../../../../@types/viewmodel/file';

interface IImageFileViewProps {
    index : number;
    value : IFileUpload;
    onRemoveFile? : (file : IFileUpload, index : number) => void;
}

interface IImageFileViewState {
    blobString : string;
}

export default class ImageFileView extends React.PureComponent<IImageFileViewProps, IImageFileViewState> {
    constructor(props : IImageFileViewProps) {
        super(props);
        this.state = {
            blobString: '',
        };
    }

    public readonly componentDidMount = () => {
        const { value } = this.props;

        if (value.file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (typeof e.target?.result === 'string') {
                    this.setState({
                        blobString: e.target?.result ?? '',
                    });
                } else {
                    this.setState({
                        blobString: '',
                    });
                }
            };
            reader.readAsDataURL(value.file);
        }
    }

    private readonly onRemoveFile = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        if (this.props.onRemoveFile) {
            this.props.onRemoveFile(this.props.value, this.props.index);
        }
    }

    public render = () => {
        const { value } = this.props;
        const { blobString } = this.state;
        return (
            <div className='fdc flx1 jcc aic'>
                <img src={blobString} height='70%' width='70%' />
                <div className='fdc jcc aic mt10'>
                    <Tooltip title='Remove'>
                        <div>
                            <IconButton value={value.name} onClick={this.onRemoveFile}>
                                <DeleteSvg className='cpr' />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    }
}
