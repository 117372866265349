import * as React from 'react';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { IUserRight } from '../../../../@types/model/right';
import Avatar from '@material-ui/core/Avatar';

interface INavMenuItemProps {
    userRight : IUserRight;
    onClick : (url : string) => void;
    icon : string;
    imgProps? : React.ImgHTMLAttributes<HTMLImageElement>;
}

interface INavMenuItemState {
}

export default class NavMenuItem extends React.Component<INavMenuItemProps, INavMenuItemState> {
    constructor(props : INavMenuItemProps) {
        super(props);

        this.state = {
            open: false,
        };

    }

    public onClick = () => {
        this.props.onClick(this.props.userRight.right?.url ?? '');
    }

    public render() {
        return (
            <ListItem  button onClick={this.onClick}>
                <ListItemIcon>
                    <Avatar src={this.props.icon} imgProps={this.props.imgProps} />
                </ListItemIcon>
                <ListItemText className='cpd fs16' primary={this.props.userRight.right?.name} />
            </ListItem>
        );
    }
}
