import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { IIncident } from '../../../../../@types/model/incident';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import lodash from 'lodash';
import IncidentFunctions from '../../../../../store/incident/functions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import { EnumFileCategory } from '../../../../../@types/model/base';
import { IFileUpload } from '../../../../../@types/viewmodel/file';
import MultiFileDropZone from '../../../custom/file/MultiFileDropZone';

interface IIncidentEvidenceInfoDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IIncidentEvidenceInfoDialogState {
    open : boolean;
    hasEvidence : boolean;
    description : string;
    exhibitNumber : string;
    result : string;
    ownerReceived : boolean;

    incidentFiles : Array<IFileUpload>;
}

export default class IncidentEvidenceInfoDialog extends React.Component<IIncidentEvidenceInfoDialogProps, IIncidentEvidenceInfoDialogState> {
    constructor(props : IIncidentEvidenceInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            hasEvidence: false,
            description: '',
            exhibitNumber: '',
            result: '',
            ownerReceived: false,
            incidentFiles: [],
        };
    }

    public componentDidUpdate = (prevProps : IIncidentEvidenceInfoDialogProps, prevState : IIncidentEvidenceInfoDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                hasEvidence: false,
                description: '',
                exhibitNumber: '',
                result: '',
                ownerReceived: false,
            });
        }
        if (!prevState.open && this.state.open && this.props.incident) {
            this.setState({
                hasEvidence: !this.props.incident.evidence ? false : true,
                description: !this.props.incident.evidence?.description ? '' : this.props.incident.evidence.description,
                exhibitNumber: !this.props.incident.evidence?.exhibitRegisterNumber ? '' : this.props.incident.evidence.exhibitRegisterNumber,
                result: !this.props.incident.evidence?.result ? '' : this.props.incident.evidence.result,
                ownerReceived: !this.props.incident.evidence?.ownerReceived ? false : this.props.incident.evidence.ownerReceived,
            });
        }
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private save = async () => {
        const { hasEvidence, description, exhibitNumber, result, ownerReceived, incidentFiles } = this.state;

        const incident = lodash.cloneDeep(this.props.incident);

        if (hasEvidence && !description) return;

        if (incident) {

            if (hasEvidence) {
                incident.evidence = {
                    ...incident.evidence!,
                    description,
                    exhibitRegisterNumber: exhibitNumber,
                    result,
                    ownerReceived,
                };
            } else {
                incident.evidence = undefined;
            }

            const saveResult = await IncidentFunctions.save(incident, incidentFiles, EnumFileCategory.Evidence);

            if (saveResult) {
                this.onClose();
            }
        }
    }

    private onEditClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({
            open: true,
        });
    }

    /**
     * Stops expansion panel from going crazy
     */
    private onClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    }

    private onHasEvidenceChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            hasEvidence: checked,
        });

        if (!checked) {
            this.setState({
                description: '',
                exhibitNumber: '',
                result: '',
                ownerReceived: false,
            });
        }
    }

    private onOwnerReceivedChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            ownerReceived: checked,
        });
    }

    private onDescriptionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            description: event.currentTarget.value,
        });
    }

    private onExhibitNumberChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            exhibitNumber: event.currentTarget.value,
        });
    }

    private onResultChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            result: event.currentTarget.value,
        });
    }

    private onFileSelected = (file : IFileUpload) => {
        const incidentFiles = this.state.incidentFiles.slice();

        const index = incidentFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            incidentFiles.splice(index, 1, file);
        } else {
            incidentFiles.push(file);
        }

        this.setState({
            incidentFiles,
        });
    }

    private onFileRemove = (file : IFileUpload) => {
        const incidentFiles = this.state.incidentFiles.slice();

        const index = incidentFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            incidentFiles.splice(index, 1);
        }

        this.setState({
            incidentFiles,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, hasEvidence, description, exhibitNumber, result, ownerReceived, incidentFiles } = this.state;
        return (<div onClick={this.onClick}>
            <Tooltip title='Edit'>
                <div>
                    <IconButton color='secondary' type='button' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='evidence-info-title'
                aria-describedby='evidence-info-description'
                className='hfill'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Evidence Info
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 hfill'>
                        <div className='flx1 fdr aifs'>
                            <div className='fdc flx1'>
                                <div className='fdr aic m5'>
                                    <FormControlLabel
                                        labelPlacement='start'
                                        className='cp'
                                        control={
                                            <Switch
                                                checked={hasEvidence}
                                                color='primary'
                                                onChange={this.onHasEvidenceChange}/>
                                        }
                                        label={`Has Evidence?`}/>
                                </div>
                                {
                                    hasEvidence &&
                                    <div className='fdc'>
                                        <div className='fdr aifs flx1'>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel required error={!description} htmlFor='description-input'>Description</InputLabel>
                                                    <Input
                                                        autoFocus
                                                        id='description-input'
                                                        value={description}
                                                        onChange={this.onDescriptionChange}
                                                        aria-describedby='station-input-helper-text'
                                                        required
                                                        error={!description}
                                                    />
                                                    {
                                                        !description &&
                                                        <FormHelperText error>Required</FormHelperText>
                                                    }
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='exhibitNumber-input'>ZZ2 Exhibit Number</InputLabel>
                                                    <Input
                                                        id='exhibitNumber-input'
                                                        value={exhibitNumber}
                                                        onChange={this.onExhibitNumberChange}
                                                        aria-describedby='exhibitNumber-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='result-input'>Result</InputLabel>
                                                    <Input
                                                        id='result-input'
                                                        value={result}
                                                        onChange={this.onResultChange}
                                                        aria-describedby='result-input-helper-text'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aic m5'>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={ownerReceived}
                                                        color='primary'
                                                        onChange={this.onOwnerReceivedChange}/>
                                                }
                                                label={`Owner Received?`}/>
                                        </div>
                                    </div>
                                }
                                <div className='flx1 fdr aifs'>
                                    <div className={'flx1'}>
                                        <MultiFileDropZone accept='audio/*,video/*,image/*,application/pdf' files={incidentFiles}
                                        onFileSelected={this.onFileSelected} onFileRemoved={this.onFileRemove} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading || (hasEvidence && !description)} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>);
    }
}
