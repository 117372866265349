import { createTheme } from '@material-ui/core/styles';
import theme from './theme';

const materialTheme = createTheme({
    typography: {
    },
    palette: theme.colors.material,
});

export default materialTheme;
