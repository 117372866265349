import { createAction } from 'typesafe-actions';
import { INotificationType, IRight, IRole, IUserListView } from '../../@types/model/right';

const rightsActions = {
    setIsLoading: createAction('RIGHT_SET_LOADING', resolve => (isLoading : boolean) => resolve(isLoading)),
    setNotificationTypes: createAction('RIGHT_SET_NOTIFICATION_TYPES', resolve => (types : Array<INotificationType>) => resolve(types)),
    setRoles: createAction('RIGHT_SET_ROLES', resolve => (roles : Array<IRole>) => resolve(roles)),
    setRolesLoading: createAction('RIGHT_SET_ROLES_LOADING', resolve => (isLoading : boolean) => resolve(isLoading)),
    setRights: createAction('RIGHT_SET_RIGHTS', resolve => (rights : Array<IRight>) => resolve(rights)),
    setUserLoading: createAction('RIGHT_SET_USER_LOADING', resolve => (isLoading : boolean) => resolve(isLoading)),
    setUsers: createAction('RIGHT_SET_USERS', resolve => (users : Array<IUserListView>) => resolve(users)),
};

export default rightsActions;
