import axios, { AxiosPromise } from 'axios';
import { IRole } from '../../@types/model/right';

function getList(name? : string, code? : string, isActive? : boolean) : AxiosPromise<Array<IRole>> {
    return axios.get<Array<IRole>>(`${API_URL}/api/Rights/Role/GetList`, {
        params : {
            name,
            code,
            isActive,
        },
    });
}

function save(role : IRole) : AxiosPromise<IRole> {
    return axios.post<IRole>(`${API_URL}/api/Rights/Role/Save`, role, {
        params : {
        },
    });
}

const roleHttpService = {
    getList,
    save,
};

export default roleHttpService;
