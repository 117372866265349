import { ISAPSStation } from '../../../../@types/model/sapsStation';
import React from 'react';
import DataFunctions from '../../../../store/data/functions';
import FormControl from '@material-ui/core/FormControl';
import { IRootState } from '../../../../@types/redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface ISAPSStationDropdownProps {
    value? : ISAPSStation;

    onChange : (value? : ISAPSStation) => void;

    sapsStations : Array<ISAPSStation>;

    required? : boolean;
    autoFocus? : boolean;

    fullWidth? : boolean;

    isLoading : boolean;
}

interface ISAPSStationDropdownState {
}

class SAPSStationDropdown extends React.Component<ISAPSStationDropdownProps, ISAPSStationDropdownState> {
    constructor(props : ISAPSStationDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        await DataFunctions.getAllSAPSStations();
    }

    private onChanged = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : string,
    }) => {
        const result = this.props.sapsStations.find(n => n.name === value.value);

        this.props.onChange(result);
    }

    private getData = (state : ISAPSStationDropdownState, props : ISAPSStationDropdownProps) => props.sapsStations;
    private getValue = (state : ISAPSStationDropdownState, props : ISAPSStationDropdownProps) => props.value;
    private getRequired = (state : ISAPSStationDropdownState, props : ISAPSStationDropdownProps) => props.required;
    private getStationDropdown = createSelector([
        this.getData, this.getRequired,
    ], (stations, required) => {
        const stationsDrop = stations.map(x => ({ label: x.name.toTitleCase(), value: x.name }));

        if (!required) {
            stationsDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return stationsDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired,
    ], (value, required) => {
        if (value) {
            return {
                label: value.name.toTitleCase(),
                value: value.name,
            };
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const { } = this.state;
        const { required,  fullWidth, isLoading } = this.props;

        const stations = this.getStationDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id='saps_select'
                    options={stations}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChanged}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField fullWidth={fullWidth} error={required && !value} {...params} label='SAPS Station' />}
                />
                        {
                            required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                        }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        sapsStations: state.data.sapsStations,
        isLoading: state.data.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(SAPSStationDropdown);
