import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { createSelector } from 'reselect';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AwayClicker from '../AwayClicker';

// tslint:disable-next-line:no-var-requires
const dateRangePicker = require('react-date-range');

interface IDateRangeInputProps {
    label? : string;
    value? : [moment.Moment, moment.Moment];

    onChange? : (value? : [moment.Moment, moment.Moment]) => void;

    required? : boolean;
    autoFocus? : boolean;

    fullWidth? : boolean;

    disabled? : boolean;
    id? : string;
}

interface IDateRangeInputState {
    open : boolean;
}

interface IDateRange {
    selection : {
        startDate : Date,
        endDate : Date,
    };
}

export default class DateRangeInput extends React.PureComponent<IDateRangeInputProps, IDateRangeInputState> {
    private readonly maxDate = new Date();
    constructor(props : IDateRangeInputProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    private readonly getValue = (props : IDateRangeInputProps) => props.value;
    private readonly getLabel = createSelector(
        [this.getValue],
        (value) => {
            if (!value) return null;

            let label = '';
            if (value[0]) {
                label += value[0].format('DD-MM-YYYY');
            }

            if (!value[0].isSame(value[1])) {
                label += ' - ';
                label += value[1].format('DD-MM-YYYY');
            }

            return label;
        },
    );

    private readonly getRange = createSelector(
        [this.getValue],
        (value) => {
            const now = moment.utc().startOf('day').toDate();
            const range = {
                startDate: !value ? now : (value[0]?.toDate() ?? now),
                endDate: !value ? now : (value[1]?.toDate() ?? now),
                key: 'selection',
            };

            return range;
        },
    );

    private readonly onRangeClick = () => {
        this.setState({
            open: true,
        });
    }

    private readonly onAwayClick = () => {
        this.setState({
            open: !this.state.open,
        });
    }

    private onRangeChange = (ranges : IDateRange) => {
        const { startDate, endDate } = ranges.selection;
        const start = moment.utc({ y: startDate.getFullYear(), M: startDate.getMonth(), d: startDate.getDate() }).startOf('day');
        const end = moment.utc({ y: endDate.getFullYear(), M: endDate.getMonth(), d: endDate.getDate() }).startOf('day');
        if (this.props.onChange) this.props.onChange([start, end]);
    }

    public readonly render = () => {
        const { open } = this.state;
        const { value, required, autoFocus, fullWidth, label, disabled, id } = this.props;
        const valueLabel = this.getLabel(this.props);
        const selectedValue = this.getRange(this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required} disabled={disabled}>
                <InputLabel htmlFor={id} className='fs16 lh21 cpla lsp024'>{label ?? 'Filter By date'}</InputLabel>
                <Input
                    autoFocus={autoFocus}
                    value={valueLabel}
                    readOnly
                    onClick={this.onRangeClick}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton onClick={this.onRangeClick}>
                                <DateRangeIcon className='cpd' />
                            </IconButton>
                            {
                                open &&
                                <AwayClicker onClickAway={this.onAwayClick}>
                                    <dateRangePicker.DateRangePicker
                                        maxDate={this.maxDate}
                                        ranges={[selectedValue]}
                                        onChange={this.onRangeChange}
                                        className={'zi2 posa bw1 ml10'}
                                    />
                                </AwayClicker>
                            }
                        </InputAdornment>
                    }
                />
            </FormControl>
        );
    }
}
