import { IIncident, IVirusIncident } from '../../@types/model/incident';
import { IncidentAction } from '../../@types/redux';
import incidentActions from './actions';
import { getType } from 'typesafe-actions';

export interface IIncidentState {
    incidents : Array<IIncident>;
    selectedIncident? : IIncident;
    editIncident? : IIncident;
    isLoading : boolean;
    isSaving : boolean;
    covid19Incidents : Array<IVirusIncident>;
    isLoadingCovid19Incidents : boolean;
    selectedVirusIncident? : IVirusIncident;
}

const initialState = {
    incidents: [],
    isLoading: false,
    isSaving: false,
    covid19Incidents: [],
    isLoadingCovid19Incidents: false,
};

export default function dataReducer(state : IIncidentState = initialState, action : IncidentAction) : IIncidentState {
    switch (action.type) {
        case getType(incidentActions.setIncidents):
            return { ...state, incidents: action.payload };
        case getType(incidentActions.setSelectedIncident):
            return { ...state, selectedIncident: action.payload };
        case getType(incidentActions.setEditIncident):
            return { ...state, editIncident: action.payload };
        case getType(incidentActions.setIncidentLoading):
            return { ...state, isLoading: action.payload };
        case getType(incidentActions.setIncidentSaving):
            return { ...state, isSaving: action.payload };
        case getType(incidentActions.setCovid19Incidents):
            return { ...state, covid19Incidents: action.payload };
        case getType(incidentActions.setSelectedVirusIncident):
            return { ...state, selectedVirusIncident: action.payload };
        case getType(incidentActions.setLoadingCovid19Incidents):
            return { ...state, isLoadingCovid19Incidents: action.payload };
        default:
            return state;
    }
}
