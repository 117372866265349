import { Tooltip, IconButton, Icon, Dialog, AppBar, Toolbar, Typography, DialogContent, FormControl, InputLabel, Input, DialogActions, Button } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { IGraveSite } from '../../../../@types/model/graveSite/graveSite';
import { IRootState } from '../../../../@types/redux';
import GraveSiteFunctions from '../../../../store/graveSites/functions';

interface IIncidentInfoEditDialogProps {
    graveSite : IGraveSite;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IIncidentInfoEditDialogState {
    open : boolean;

    name : string;
    employeeNumber : string;
}

class GraveSiteRepresentativeEditDialog extends React.PureComponent<IIncidentInfoEditDialogProps, IIncidentInfoEditDialogState> {
    constructor(props : IIncidentInfoEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            employeeNumber: '',
        };
    }

    public componentDidUpdate = (prevProps : IIncidentInfoEditDialogProps, prevState : IIncidentInfoEditDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                name: '',
                employeeNumber: '',
            });
        }
        if (!prevState.open && this.state.open && this.props.graveSite) {
            this.setState({
                name: !this.props.graveSite.representativeName ? '' : this.props.graveSite.representativeName,
                employeeNumber: !this.props.graveSite.representative ? '' : this.props.graveSite.representative,
            });
        }
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private save = async () => {
        const { employeeNumber, name } = this.state;

        const site = lodash.cloneDeep(this.props.graveSite);

        if (site) {

            site.representative = employeeNumber;
            site.representativeName = name;

            const result = await GraveSiteFunctions.save(site, []);

            if (result) {
                this.onClose();
            }
        }
    }

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    private onNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    }

    private onEmployeeNumberChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            employeeNumber: event.currentTarget.value,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, graveSite } = this.props;
        const { open, employeeNumber, name } = this.state;
        return (<React.Fragment>
            <Tooltip title='Edit'>
                <div className='pr15'>
                    <IconButton color='secondary' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='incident-info-title'
                aria-describedby='incident-info-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Grave Site
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 hfill'>
                        <div className='fdc flx1'>
                            <div className='fdr ais'>
                                <div className={'flx1 aifs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentInjury-input'>Name</InputLabel>
                                        <Input
                                            id='name-input'
                                            value={name}
                                            onChange={this.onNameChange}
                                            aria-describedby='name-input-helper-text'
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 aifs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='incidentInjury-input'>Employee Number</InputLabel>
                                        <Input
                                            id='employee-number-input'
                                            value={employeeNumber}
                                            onChange={this.onEmployeeNumberChange}
                                            aria-describedby='employee-number-input-helper-text'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading || !name || !employeeNumber} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>);
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.graveSites.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(GraveSiteRepresentativeEditDialog);
