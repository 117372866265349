import * as React from 'react';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography/Typography';
import generalFunctions from '../../store/general/functions';
import authFunctions from '../../store/auth/functions';
import Card from '@material-ui/core/Card/Card';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { CustomChangeEvent } from '../../@types/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLoginButton from '../component/custom/button/GoogleLoginButton';
import { IGoogleLoginError, IGoogleLoginSuccess } from '../component/custom/google/WithGoogleSignIn';

interface ILoginProps {
    isLoggingIn : boolean;
}

interface ILoginState {
    employeeNr : string;

    providerLoaded : boolean;
    providerError : string;
}

class Login extends React.Component<ILoginProps, ILoginState> {
    constructor(props : ILoginProps) {
        super(props);

        this.state = {
            employeeNr: '',
            providerError: '',
            providerLoaded: false,
        };
    }

    private signInSuccess = async (response : IGoogleLoginSuccess) => {
        await authFunctions.authGoogleLogIn(response.code, this.state.employeeNr);
    }

    private signInError = (error : IGoogleLoginError) => {
        generalFunctions.showErrorSnackbar(error.error_description ?? 'Login Error');
    }

    private onEmplyeeChanged = (e : CustomChangeEvent) => {
        this.setState({
            employeeNr: e.currentTarget.value,
        });
    }

    private readonly onScriptLoadSuccess = () => {
        this.setState({
            providerLoaded: true,
        });
    }

    private readonly onScriptLoadError = () => {
        this.setState({
            providerError: 'Error loading google auth',
        });
    }

    public render() {
        const { isLoggingIn } = this.props;
        const { employeeNr, providerError, providerLoaded } = this.state;

        return (
            <GoogleOAuthProvider
                clientId={OAUTH_CLIENT_ID}
                onScriptLoadSuccess={this.onScriptLoadSuccess}
                onScriptLoadError={this.onScriptLoadError}
            >
                <div className={'flx1 fdr'}>
                    {
                        isLoggingIn ? (
                            <div className={'flx1 fdc aic jcc'}>
                                <img height={60} src={`${ASSET_BASE}/assets/images/logo.png`} className={'posr post70'} />
                                <CircularProgress size={80} />
                            </div>
                        ) : (
                            <div className={'flx1 fdc aic pt30'}>
                                <Card className={'fdc p30 w400'}>
                                    <Typography color='inherit' variant='h5'>
                                        To continue, please sign in with Google:
                                    </Typography>
                                    <FormControl>
                                        <TextField
                                            autoFocus
                                            margin='dense'
                                            id='employeeNr'
                                            label='Employee Number'
                                            type='text'
                                            fullWidth
                                            value={employeeNr}
                                            onChange={this.onEmplyeeChanged}
                                        />
                                    </FormControl>
                                    <span className={'mt30'} />
                                    {
                                        !!providerError &&
                                        <Typography>
                                            {
                                                providerError
                                            }
                                        </Typography>
                                    }
                                    {
                                        providerLoaded &&
                                        <GoogleLoginButton
                                            onSuccess={this.signInSuccess}
                                            onError={this.signInError}
                                        />
                                    }
                                </Card >
                            </div>
                            )
                    }
                </div>
            </GoogleOAuthProvider>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoggingIn: state.auth.isLoggingIn,
    };
};

export default connect(
    mapStateToProps,
)(Login);
