import axios from 'axios';
import { IRegion } from '../../../@types/model/region';

export default class RegionHttpService {
    public static getAll() {
        return axios.get<Array<IRegion>>(`${API_URL}/api/MasterData/Region/GetAll`, {
            params : {
            },
        });
    }
    public static save(region : IRegion) {
        return axios.post<IRegion>(`${API_URL}/api/MasterData/Region/Save`, region, {
            params : {
            },
        });
    }
}
