import React, { ChangeEvent } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { IIncident } from '../../../../../@types/model/incident';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DivisionDropdown from '../../../custom/dropdowns/DivisionDropdown';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import IncidentFunctions from '../../../../../store/incident/functions';
import lodash from 'lodash';
import { IDivision } from '../../../../../@types/model/division';
import IncidentCategoryGroupDropdown from '../../../custom/autocomplete/IncidentCategoryGroupDropdown';
import { IIncidentGroup } from '../../../../../@types/model/masterData/incidentGroup';

interface IIncidentGeneralInfoEditDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IIncidentGeneralInfoEditDialogState {
    open : boolean;

    division? : IDivision;
    obFarmNumber : string;
    group : string;
}

export default class IncidentGeneralInfoEditDialog extends React.Component<IIncidentGeneralInfoEditDialogProps, IIncidentGeneralInfoEditDialogState> {
    constructor(props : IIncidentGeneralInfoEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            obFarmNumber: '',
            group: '',
        };
    }

    public componentDidUpdate = (prevProps : IIncidentGeneralInfoEditDialogProps, prevState : IIncidentGeneralInfoEditDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                division: undefined,
                obFarmNumber: '',
                group: '',
            });
        }
        if (!prevState.open && this.state.open && this.props.incident) {
            this.setState({
                division: {
                    code: this.props.incident.divisionCode,
                    description: this.props.incident.division,
                    company: '',
                    dept: '',
                    isActive: true,
                    gl: '',
                    updatedOn: '',
                } as IDivision,
                obFarmNumber: this.props.incident.farmOBNumber ?? '',
                group: this.props.incident.group ?? '',
            });
        }
    }

    private save = async () => {
        const { division, obFarmNumber, group } = this.state;

        const incident = lodash.cloneDeep(this.props.incident);

        if (!division) return;
        if (incident) {

            incident.division = division?.description;
            incident.divisionCode = division?.code;
            incident.farmOBNumber = obFarmNumber;
            incident.group = group;

            const result = await IncidentFunctions.save(incident);

            if (result) {
                this.onClose();
            }
        }
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    private onFarmObNumberChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            obFarmNumber: event.target.value,
        });
    }

    private onDivisionChange = (division? : IDivision | undefined) => {
        this.setState({
            division,
        });
    }

    private onGroupsChange = (group : IIncidentGroup) => {
        this.setState({
            group: group.name,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, obFarmNumber, division, group } = this.state;
        return (<React.Fragment>
            <Tooltip title='Edit'>
                <div>
                    <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='general-info-info-title'
                aria-describedby='general-info-info-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit General Info
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 aifs ais'>
                        <div className='fdr flx1 aifs mb10'>
                            <div className={`flx1 aic p5 mb10 pr20 ${!group ? '' : 'mt4'}`}>
                                <IncidentCategoryGroupDropdown fullWidth required value={group} onChange={this.onGroupsChange} label='Group' />
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'}>
                                <DivisionDropdown fullWidth required onChange={this.onDivisionChange} value={division} />
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='farm-ob-input'>Farm OB Number</InputLabel>
                                    <Input
                                        id='farm-ob-input'
                                        value={obFarmNumber}
                                        onChange={this.onFarmObNumberChange}
                                        aria-describedby='farm-ob-input-helper-text'
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>);
    }
}
