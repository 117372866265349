import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { IIncidentComplainantCapture } from '../../../../@types/viewmodel/incident';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SpokenLanguageDropdown from '../../custom/dropdowns/SpokenLanguageDropdown';

interface IIncidentComplainantCaptureProps {
    value? : IIncidentComplainantCapture;

    onSubmit? : (value : IIncidentComplainantCapture, back? : boolean) => void;
}

interface IIncidentComplainantCaptureState {
    name : string;
    surname : string;
    employeeNr : string;
    address : string;
    tel : string;
    cel : string;
    language : string;
}

export default class IncidentComplainantCapture extends React.Component<IIncidentComplainantCaptureProps, IIncidentComplainantCaptureState> {
    constructor(props : IIncidentComplainantCaptureProps) {
        super(props);
        this.state = {
            name: !props.value ? '' : props.value.name,
            surname: !props.value ? '' : props.value.surname,
            employeeNr: !props.value ? '' : props.value.employeeNr,
            address: !props.value ? '' : props.value.address,
            tel: !props.value ? '' : props.value.tel,
            cel: !props.value ? '' : props.value.cel,
            language: !props.value ? '' : props.value.language,
        };
    }

    private submit = (back? : boolean) => {
        if (!!this.props.onSubmit) {
            this.props.onSubmit({
                name: this.state.name,
                surname: this.state.surname,
                employeeNr: this.state.employeeNr,
                address: this.state.address,
                tel: this.state.tel,
                cel: this.state.cel,
                language: this.state.language,
            }, back);
        }
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.submit();
    }

    private onBack = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.submit(true);
    }

    private onNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    }

    private onSurnameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            surname: event.currentTarget.value,
        });
    }

    private onEmployeeNrChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            employeeNr: event.currentTarget.value,
        });
    }

    private onAddressChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            address: event.currentTarget.value,
        });
    }

    private onTelChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            tel: event.currentTarget.value,
        });
    }

    private onCelChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            cel: event.currentTarget.value,
        });
    }

    private onLanguageChange = (language : string) => {
        this.setState({
            language,
        });
    }

    public render = () => {
        const { name, surname, employeeNr, address, tel, cel, language } = this.state;
        return (
            <form className='fdc flx1 aifs ais' onSubmit={this.onSubmit}>
                <Card className='fdr aifs mb10 sbs mnh242'>
                    <CardContent className='flx1 fdr aifs'>
                        <div className='flx1 fdr aifs'>
                            <div className='fdc flx1'>
                                <div className='fdr aifs'>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel required error={!name} htmlFor='name-input'>Name</InputLabel>
                                            <Input
                                                autoFocus
                                                id='name-input'
                                                value={name}
                                                onChange={this.onNameChange}
                                                aria-describedby='name-input-helper-text'
                                                required
                                                error={!name}
                                            />
                                            {
                                                !name &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel required error={!surname} htmlFor='surname-input'>Surname</InputLabel>
                                            <Input
                                                id='surname-input'
                                                value={surname}
                                                onChange={this.onSurnameChange}
                                                aria-describedby='surname-input-helper-text'
                                                required
                                                error={!surname}
                                            />
                                            {
                                                !surname &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='employeeNr-input'>Employee Nr</InputLabel>
                                            <Input
                                                id='employeeNr-input'
                                                value={employeeNr}
                                                onChange={this.onEmployeeNrChange}
                                                aria-describedby='employeeNr-input-helper-text'
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='fdr aifs'>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='tel-input'>Tel</InputLabel>
                                            <Input
                                                id='tel-input'
                                                value={tel}
                                                onChange={this.onTelChange}
                                                aria-describedby='tel-input-helper-text'
                                                placeholder='0121231234'
                                                inputProps={{
                                                    pattern: '[0-9]{10}',
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='cel-input'>Cel</InputLabel>
                                            <Input
                                                id='cel-input'
                                                value={cel}
                                                onChange={this.onCelChange}
                                                aria-describedby='cel-input-helper-text'
                                                placeholder='0121231234'
                                                inputProps={{
                                                    pattern: '[0-9]{10}',
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    </div>
                                </div>
                                <div className='fdr aifs'>
                                    <div className={'aic p5 mb10 flx2'} style={{ marginTop: '16px' }}>
                                        <FormControl fullWidth>
                                            <InputLabel required error={!address} htmlFor='address-input'>Address</InputLabel>
                                            <Input
                                                id='address-input'
                                                value={address}
                                                onChange={this.onAddressChange}
                                                aria-describedby='address-input-helper-text'
                                                required
                                                error={!address}
                                                multiline
                                                maxRows='5'
                                            />
                                            {
                                                !address &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx2 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                        <SpokenLanguageDropdown fullWidth value={language} onChange={this.onLanguageChange} required />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <div className='fdr mr15 ais'>
                    <span className='flx1' />
                    <div>
                        <Fab className={`nbs bct cp pl40 pr40 mr15`}
                            variant='extended'
                            onClick={this.onBack}
                            size='medium'
                            type='button'>
                            Back
                        </Fab>
                    </div>
                    <div>
                        <Fab className={`${!name || !surname || !address || !language ? 'cg0 bcg1' : 'cp bcy'} pl40 pr40`}
                            variant='extended'
                            size='medium'
                            disabled={!name || !surname || !address || !language}
                            type='submit'>
                            Next
                        </Fab>
                    </div>
                </div>
        </form>
        );
    }
}
