import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { IAccessPointCategory } from '../../../@types/model/masterData/accessPointCategory';

export default class AccessPointCategoryHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel current Person request
     */
    public static cancelGet? : Canceler;

    public static getAll() : AxiosPromise<Array<IAccessPointCategory>> {
        if (AccessPointCategoryHttpService.cancelGet) {
            AccessPointCategoryHttpService.cancelGet();
        }
        return axios.get(`${API_URL}/api/MasterData/AccessPointCategory/GetAll`, {
            cancelToken: new AccessPointCategoryHttpService.CancelToken((c) => {
                AccessPointCategoryHttpService.cancelGet = c;
            }),
            params: {
            },
        });
    }

    public static save(graveSite : IAccessPointCategory) {
        return axios.post<IAccessPointCategory>(`${API_URL}/api/MasterData/AccessPointCategory/Save`, graveSite);
    }

}
