import { dispatch, getState } from '..';
import generalFunctions from '../general/functions';
import dataActions from './actions';
import AccessPointHttpService from '../../service/http/master/accessPointHttpService';
import { IAccessPoint } from '../../@types/model/access';
import { IFileUpload } from '../../@types/model/base';
import SAPSHttpService from '../../service/http/master/sapsHttpService';
import IncidentCategoryHttpService from '../../service/http/master/incidentCategoyHttpService';
import { IIncidentCategory } from '../../@types/model/incident';
import CustomerHttpService from '../../service/http/customerHttpService';
import { IIncidentGroup } from '../../@types/model/masterData/incidentGroup';
import IncidentGroupHttpService from '../../service/http/master/incidentGroupHttpService';

export default class DataFunctions {
    public static async getAllAccessPoints(refresh? : boolean) {
        try {
            const state = getState();
            dispatch(dataActions.setIsLoading(true));

            if (refresh || state.data.accessPoints.length === 0) {
                const result = await AccessPointHttpService.getAll();

                dispatch(dataActions.setAccessPoints(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading Access Points.', e);
        }
        dispatch(dataActions.setIsLoading(false));
    }

    public static async saveAccessPoint(accessPoint : IAccessPoint, file? : IFileUpload) {
        try {
            dispatch(dataActions.setIsLoading(true));

            const result = await AccessPointHttpService.save(accessPoint, file);

            if (result.data) {
                const state = getState();

                const accessPoints = state.data.accessPoints.slice();
                const index = accessPoints.findIndex(n => n.guid === result.data.guid);
                if (index > -1) {
                    accessPoints.splice(index, 1, result.data);
                } else {
                    accessPoints.push(result.data);
                }
                dispatch(dataActions.setAccessPoints(accessPoints));
            }
            dispatch(dataActions.setIsLoading(false));

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving Access Point.', e);
            dispatch(dataActions.setIsLoading(false));
        }
        return null;
    }

    public static async getAllSAPSStations() {
        try {
            const state = getState();
            dispatch(dataActions.setIsLoading(true));

            if (state.data.sapsStations.length === 0) {
                const result = await SAPSHttpService.getStations();

                dispatch(dataActions.setSAPSStations(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading SAPS Stations.', e);
        }
        dispatch(dataActions.setIsLoading(false));
    }

    public static async getAllIncidentCategories(isActive? : boolean, refresh? : boolean) {
        try {
            const state = getState();
            dispatch(dataActions.setLoadingIncidentCategories(true));

            if (state.data.incidentCategories.length === 0 || !!refresh) {
                const result = await IncidentCategoryHttpService.getAll(isActive);

                dispatch(dataActions.setIncidentCategories(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading incident categories.', e);
        }
        dispatch(dataActions.setLoadingIncidentCategories(false));
    }

    public static async getAllIncidentGroups(refresh? : boolean) {
        try {
            const state = getState();
            dispatch(dataActions.setLoadingIncidentGroups(true));

            if (state.data.incidentGroups.length === 0 || !!refresh) {
                const result = await IncidentGroupHttpService.getAll();

                dispatch(dataActions.setIncidentGroups(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading incident categories.', e);
        }
        dispatch(dataActions.setLoadingIncidentGroups(false));
    }

    public static async publishAllIncidentCategories() {
        try {
            dispatch(dataActions.setLoadingIncidentCategories(true));

            await IncidentCategoryHttpService.publishAll();
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while publishing incident groups.', e);
        }
        dispatch(dataActions.setLoadingIncidentCategories(false));
    }

    public static async saveIncidentCategory(incidentCategory : IIncidentCategory) {
        try {
            dispatch(dataActions.setLoadingIncidentCategories(true));

            const result = await IncidentCategoryHttpService.save(incidentCategory);

            if (result.data) {
                const state = getState();

                const incidentCategories = state.data.incidentCategories.slice();
                const index = incidentCategories.findIndex(n => n.guid === result.data.guid);
                if (index > -1) {
                    incidentCategories.splice(index, 1, result.data);
                } else {
                    incidentCategories.push(result.data);
                }
                dispatch(dataActions.setIncidentCategories(incidentCategories));
            }
            dispatch(dataActions.setLoadingIncidentCategories(false));

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving Incident Categories.', e);
            dispatch(dataActions.setLoadingIncidentCategories(false));
        }
        return null;
    }

    public static async getAllDivisions() {
        try {
            const state = getState();
            dispatch(dataActions.setIsLoadingDivisions(true));

            if (state.data.divisions.length === 0) {
                const result = await CustomerHttpService.getDivisions();

                dispatch(dataActions.setDivisions(result.data));
            }
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while loading divisions.', e);
        } finally {
            dispatch(dataActions.setIsLoadingDivisions(false));
        }
    }

    public static async saveIncidentGroup(incidentGroup : IIncidentGroup) {
        try {
            dispatch(dataActions.setLoadingIncidentGroups(true));

            const result = await IncidentGroupHttpService.save(incidentGroup);

            if (result.data) {
                const state = getState();

                const incidentGroups = state.data.incidentGroups.slice();
                const index = incidentGroups.findIndex(n => n.guid === result.data.guid);
                if (index > -1) {
                    incidentGroups.splice(index, 1, result.data);
                } else {
                    incidentGroups.push(result.data);
                }
                dispatch(dataActions.setIncidentGroups(incidentGroups));
            }
            dispatch(dataActions.setLoadingIncidentGroups(false));

            return result.data;
        } catch (e) {
            generalFunctions.showErrorSnackbar('An error occurred while saving Incident Groups.', e);
            dispatch(dataActions.setLoadingIncidentGroups(false));
        }
        return null;
    }
}
