import { Text, View, Image } from '@react-pdf/renderer';
import style from '../../../../style/pdfStyleSheet';
import React from 'react';

interface IPDFIncidentReportTitleProps {
    title : string;
    sub : string;
}

interface IPDFIncidentReportTitleState {}

export default class PDFIncidentReportTitle extends React.PureComponent<IPDFIncidentReportTitleProps, IPDFIncidentReportTitleState> {
    constructor(props : IPDFIncidentReportTitleProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        return (
            <View style={[style.fdr, style.mb10, style.cpl, style.bcg1, style.p10, style.mt10, style.page]} fixed>
                <View style={[style.fdc, style.flx1]}>
                    <Text style={[style.fs18, style.fweb]}>{this.props.title}</Text>
                    <Text style={[style.fs16]}>{this.props.sub}</Text>
                </View>
                <View style={[style.fdc]}>
                    <Image style={[style.h40]} src={`${ASSET_BASE}/assets/images/zz2_logo.png`}/>
                </View>
            </View>
        );
    }
}
