import React from 'react';
import { IIncident } from '../../../../@types/model/incident';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IncidentUpdateEditDialog from '../dialog/edit/UpdateEditDialog';
import moment from 'moment';
import FileViewDialog from '../../custom/dialog/FileViewDialog';
import IncidentUpdateRemoveDialog from '../dialog/edit/UpdateDeleteDialog';

interface IIncidentUpdatesViewProps {
    incident : IIncident;
    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentUpdatesViewState {}

export default class IncidentUpdatesView extends React.PureComponent<IIncidentUpdatesViewProps, IIncidentUpdatesViewState> {
    constructor(props : IIncidentUpdatesViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const {} = this.state;
        const { isEditAdmin, incident, isSaving } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg1'>
                <span className='flx1' />
                <Paper className='fdc flx4' elevation={3}>
                    <AppBar position='static' className='pbr bcpl' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                UPDATES
                            </Typography>
                            <span className='flx1' />
                            {
                                isEditAdmin &&
                                <div className='posa posr10'>
                                    <IncidentUpdateEditDialog index={-1} maxWidth='md' fullWidth incident={incident} isLoading={isSaving}/>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    {
                        !incident.updates?.filter(n => n.isActive).length &&
                        <div className='fdc pt10 mb10 aic jcc'>
                            <Typography className={'flx1 fs16 lh37 fwm'} variant='subtitle1'>
                                None
                            </Typography>
                        </div>
                    }
                    {
                         incident.updates?.filter(n => n.isActive).map((n, i) =>
                         <div key={`updates_${n.id}`} className='fdr bbw1 bocg1'>
                            <div className='fdc flx1'>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Date
                                    </Typography>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Files
                                    </Typography>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Updated By
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 ais fs16 lh37 fwm'} variant='subtitle1'>
                                        {moment.utc(n.date).local().format('YYYY-MM-DD HH:mm')}
                                    </Typography>
                                    <Typography className={'flx1 ais fs16 lh37 fwm'} variant='subtitle1'>
                                        <FileViewDialog fullWidth maxWidth='md' files={n.files.map(x => x.file)} />
                                    </Typography>
                                    <Typography className={'flx1 ais fs16 lh37 fwm'} variant='subtitle1'>
                                        {n.createdByName ?? '-'}
                                    </Typography>
                                </div><div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fs12 lh37 ls065 cg3'} variant='subtitle1'>
                                        Description
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'fdc flx1 ais fs16 lh37 fwm'} variant='subtitle1'>
                                        {
                                            n.comment.split('\n').map((comment, index) => (
                                                <Typography key={index} variant='body1' className='flx1' component='p'>
                                                {
                                                    comment
                                                }
                                                </Typography>
                                            ))
                                        }
                                    </Typography>
                                </div>
                            </div>
                            <Typography className={'fdr pt10 w100 ais jcc fs16 lh37 fwm'} variant='subtitle1'>
                                <IncidentUpdateEditDialog index={i} maxWidth='md' fullWidth incident={incident} isLoading={isSaving} />
                                <IncidentUpdateRemoveDialog index={i} maxWidth='sm' fullWidth incident={incident} isLoading={isSaving} />
                            </Typography>
                         </div>)
                    }
                </Paper>
                <span className='flx1' />
            </div>
        );
    }
}
