import AuthHttpService from '../../service/http/authHttpService';
import GeneralFunctions from '../general/functions';
import AuthActions from './actions';
import { dispatch, getState } from '../index';
import LocalStorageService from '../../service/localStorageService';
import firebase from 'firebase/app';
import 'firebase/messaging';
import userHttpService from '../../service/http/userHttpService';
import { IUserRight, IUserToken } from '../../@types/model/right';
import { IAuthState } from './reducer';
import NotificationService from '../../service/notificationService';
import FileFunctions from '../file/functions';
import fileActions from '../file/actions';
import HttpService from '../../service/http';

export default class AuthFunctions {
    public static async init() {
        dispatch(AuthActions.authSetLoggingIn(true));
        LocalStorageService.onSessionChanged((user) => {
            AuthFunctions.setSession(user);
        });

        AuthFunctions.initSession();
    }

    public static async initSession() {
        HttpService.initializeInterceptor();

        try {
            const token = await LocalStorageService.getLocalStorageToken();

            if (token) {
                const session = await AuthHttpService.refreshSession();
                LocalStorageService.setLocalStorageSession(session);
            } else {
                LocalStorageService.setLocalStorageSession(null);
            }
        } catch (e) {
            if (e) {
                GeneralFunctions.showErrorSnackbar('An error occurred while refreshhing session', e);
                LocalStorageService.setLocalStorageSession(null);
            }
        }
    }

    /**
     * Performs log out request with API then sets the auth state accordingly.
     */
    public static async authLogOut() {
        try {
            dispatch(AuthActions.authSetLoggingIn(true));

            await AuthHttpService.logout();

            await LocalStorageService.setLocalStorageSession(null);
        } catch (e) {
            GeneralFunctions.showErrorSnackbar('An error occurred while logging out.', e);
        }
    }

    public static async authGoogleLogIn(code : string, employeeNr : string) {
        try {
            dispatch(AuthActions.authSetLoggingIn(true));

            const res = await AuthHttpService.googleLogIn(code, employeeNr);

            await LocalStorageService.setLocalStorageSession(res.data);

            return res.data;
        } catch (ex) {
            dispatch(AuthActions.authSetLoggingIn(false));

            if (ex?.response) {
                if (ex.response.status === 400) {
                    GeneralFunctions.showErrorSnackbar(ex.response.data);
                } else if (!!ex && ex.response.status !== 401 && ex.response.status !== 403) {
                    GeneralFunctions.showErrorSnackbar('An error occurred while logging in.', ex);
                }
            }

            return null;
        }
    }

    public static async updateNotificationSubscriptions(notificationTypeIds : Array<number>) {
        try {
            const messaging = firebase.messaging(firebase.app());
            const fcmToken = await messaging.getToken();

            if (fcmToken) {
                const result = await userHttpService.updateUserNotificationSubscriptions(fcmToken, notificationTypeIds);

                const state = getState();
                const session = Object.assign({}, state.auth.session);
                session.user.userNotificationSubscriptions = result.data;

                await LocalStorageService.setLocalStorageSession(session);

                return result.data.map(x => x.notificationTypeId);
            }
            return [];
        } catch (e) {
            GeneralFunctions.showErrorSnackbar('An error occurred while updating subscriptions.', e);
            return [];
        }
    }

    public static getMenuItems(auth : IAuthState) : Array<IUserRight> {
        if (!auth.session) return [];

        if (!auth.session.user.userRights || !auth.session.user.userRights.length) return [];

        const { userRights } = auth.session.user;
        return userRights.filter(n => n.right.isMenu);
    }

    /**
     * Check if current user is associated with a right that corresponds to the destination url.
     * @param url
     */
    public static hasMenuRight(url : string) : boolean {
        const state = getState();

        if (!state.auth.session) return false;

        if (!state.auth.session.user.userRights || !state.auth.session.user.userRights.length) return false;

        const { userRights } = state.auth.session.user;
        return userRights.some(n => n.right.url === url);
    }

    public static async setSession(userToken : IUserToken | null) {
        if (userToken) {
            await this.initApp();
        } else {
            await this.uninitApp();
        }

        dispatch(AuthActions.authSetLoggedIn(!!userToken));
        dispatch(AuthActions.authSetSession(userToken));
        dispatch(AuthActions.authSetLoggingIn(false));
    }

    private static readonly initApp = async () => {
        await NotificationService.subscribePush();

        if (navigator.storage && navigator.storage.persist) {
            try {
                const persistent = await navigator.storage.persist();
                if (!persistent) {
                    GeneralFunctions.showWarningSnackbar('Persistant storage required for file upload.');
                }

                const files = await LocalStorageService.getNotCompletedQueueFiles();
                dispatch(fileActions.setFiles(files));
                FileFunctions.processQueue();
            } catch (ex) {
                GeneralFunctions.showWarningSnackbar('Persistant storage required for file upload.');
            }
        } else {
            GeneralFunctions.showWarningSnackbar('Persistant storage required for file upload.');
        }
    }

    private static readonly uninitApp = async () => {
        await NotificationService.unsubscribePush();
    }
}
