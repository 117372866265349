import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import lodash from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createSelector } from 'reselect';
import TextField from '@material-ui/core/TextField';
import { IIncidentCategory } from '../../../../@types/model/incident';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormHelperText from '@material-ui/core/FormHelperText';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';

interface IIncidentCategoryMultiDropdownProps {
    id? : string;
    value : Array<string>;

    incidentCategories : Array<IIncidentCategory>;
    onChange : (value : Array<IIncidentCategory>) => void;

    required? : boolean;

    fullWidth? : boolean;
    disabled? : boolean;

    group? : string | null;
}

interface IIncidentCategoryMultiDropdownState {}

class IncidentCategoryMultiDropdown extends React.Component<IIncidentCategoryMultiDropdownProps, IIncidentCategoryMultiDropdownState> {
    constructor(props : IIncidentCategoryMultiDropdownProps) {
        super(props);
        this.state = {};
    }

    private onChange = (event : React.ChangeEvent<HTMLSelectElement>, value : Array<{
        label : string,
        value : string,
    }>) => {
        if (this.props.onChange) this.props.onChange(this.props.incidentCategories.filter(x => value.map(z => z.value).includes(x.guid)));
    }

    private getIncidentCategories = (state : IIncidentCategoryMultiDropdownState, props : IIncidentCategoryMultiDropdownProps) => props.incidentCategories;
    private getValue = (state : IIncidentCategoryMultiDropdownState, props : IIncidentCategoryMultiDropdownProps) => props.value;
    private getGroup = (state : IIncidentCategoryMultiDropdownState, props : IIncidentCategoryMultiDropdownProps) => props.group;
    private getDropdown = createSelector([
        this.getIncidentCategories,
        this.getGroup,
    ], (incidentCategories, group) => {
        return lodash
            .chain(incidentCategories)
            .orderBy(x => x.name , 'asc')
            .filter(n => !group || !!n.groups?.includes(group))
            .map(x => ({ label: x.name, value: x.guid }))
            .value();
    });

    private getSelectedValue = createSelector([
        this.getValue,
        this.getIncidentCategories,
    ], (value, incidentCategories) => {
        return incidentCategories.filter(x => value.includes(x.guid)).map(n => ({
            label : n.name,
            value : n.guid,
        }));
    });

    public render = () => {
        const { id, required, fullWidth, disabled } = this.props;
        const dropdowns = this.getDropdown(this.state, this.props);
        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl fullWidth={fullWidth} disabled={disabled} error={required && !value.length}>
                <Autocomplete
                    id={id}
                    multiple
                    fullWidth={fullWidth}
                    disabled={disabled}
                    options={dropdowns}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={false}
                    openOnFocus
                    limitTags={2}
                    disableCloseOnSelect
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                            icon={<CheckBoxOutlineBlankIcon color='primary' fontSize='small' />}
                            checkedIcon={<CheckBoxIcon  color='primary' fontSize='small' />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            />
                            {option.label}
                        </React.Fragment>
                    )}
                    renderInput={params => <TextField {...params} error={required && !value.length} label='Incident Category' InputLabelProps={{
                        className: 'fs16 lh21 cpla lsp024',
                    }} fullWidth={fullWidth} disabled={disabled} />}
                />
                {
                    required && !value?.length &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        incidentCategories: state.data.incidentCategories,
        isLoading: state.data.isLoadingIncidentCategories,
    };
};

export default connect(
    mapStateToProps,
)(IncidentCategoryMultiDropdown);
