import axios, { AxiosPromise } from 'axios';
import { IDivision } from '../../@types/model/division';

export default class CustomerHttpService {
    public static getDivisions() : AxiosPromise<Array<IDivision>> {
        return axios.get(`${API_URL}/api/MasterData/Customer/GetDivisions`, {
            params: {
            },
        });
    }
}
