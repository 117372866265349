import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import IncidentGeneralInfoCapture from '../capture/IncidentGeneralInfoCapture';
import IncidentSAPS from '../capture/IncidentSAPSCapture';
import IncidentComplainantCapture from '../capture/IncidentComplainantCapture';
import IncidentCapture from '../capture/IncidentCapture';
import { IIncidentSAPSCapture, IIncidentGeneralInfoCapture, IIncidentComplainantCapture, IIncidentCapture, IIncidentEvidence, IIncidentSuspects, IIncidentCourtCaseCreate } from '../../../../@types/viewmodel/incident';
import IncidentEvidenceCapture from '../capture/InicentEvidenceCapture';
import IncidentSuspectsCapture from '../capture/IncidentSuspectsCapture';
import IncidentFunctions from '../../../../store/incident/functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { v4 } from 'uuid';
import StandardFab from '../../custom/button/StandardFab';
import IncidentCourtCaseCapture from '../capture/IncidentCourtCase';

interface IIncidentCreateDialogProps {
    onClose? : () => void;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
    disabled? : boolean;
}

interface IIncidentCreateDialogState {
    guid : string;

    open : boolean;

    stepIndex : number;

    generalInfo? : IIncidentGeneralInfoCapture;
    saps? : IIncidentSAPSCapture;
    complainant? : IIncidentComplainantCapture;
    incident? : IIncidentCapture;
    evidence? : IIncidentEvidence;
    courtCase? : IIncidentCourtCaseCreate | null;
    suspects : IIncidentSuspects;

    completedSteps : Array<boolean>;
}

const initSuspects : IIncidentSuspects = {
    isMotorVehicleAccident: false,
    suspectFiles: [],
    suspectKnown: true,
    suspects: [{
        address: '',
        cel: '',
        employeeNr: '',
        name: '',
        surname: '',
        tel: '',
        workplace: '',
        language: '',
        vehicleColour: '',
        vehicleMake: '',
        insuranceCompany: '',
        vehicleOwnership: null,
        vehicleRegistrationNumber: '',
    }],
};

export default class IncidentCreateDialog extends React.Component<IIncidentCreateDialogProps, IIncidentCreateDialogState> {
    private readonly steps = [
        { label: 'GENERAL INFO', icon: 'icon-incident-incident-info' },
        { label: 'SAPS', icon: 'icon-incident-incident-saps' },
        { label: 'COMPLAINANT', icon: 'icon-incident-incident-complainant' },
        { label: 'INCIDENT', icon: 'icon-incident-incident-incident' },
        { label: 'EVIDENCE', icon: 'icon-incident-incident-evidence' },
        { label: 'SUSPECT', icon: 'icon-incident-incident-suspect' },
        { label: 'COURT CASE', icon: 'icon-incident-incident-info' },
    ];

    constructor(props : IIncidentCreateDialogProps) {
        super(props);
        this.state = {
            guid: v4(),
            open: false,
            stepIndex: 0,
            // Seed steps complete array
            completedSteps: this.steps.map(n => false),
            suspects: {
                ...initSuspects,
                suspectFiles: [...initSuspects.suspectFiles],
                suspects: [...initSuspects.suspects],
            },
        };
    }

    public onClose = () => {
        // TODO: Clear state
        this.setState({
            open: false,
            guid: v4(),
            stepIndex: 0,
            // Seed steps complete array
            completedSteps: this.steps.map(n => false),
            generalInfo: undefined,
            saps: undefined,
            complainant: undefined,
            incident: undefined,
            evidence: undefined,
            suspects: {
                ...initSuspects,
                suspectFiles: [...initSuspects.suspectFiles],
                suspects: [...initSuspects.suspects],
            },
        });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    public onCreateClick = () => {
        this.setState({
            open: true,
        });
    }

    private onGeneralSubmit = (generalInfo : IIncidentGeneralInfoCapture) => {
        const completedSteps = this.state.completedSteps.slice();
        completedSteps[this.state.stepIndex] = !!generalInfo.division && !!generalInfo.group;
        this.setState({
            completedSteps,
            generalInfo,
            stepIndex: this.state.stepIndex + 1,
        });
    }

    private onSapsSubmit = (saps : IIncidentSAPSCapture, back? : boolean) => {
        const completedSteps = this.state.completedSteps.slice();
        completedSteps[this.state.stepIndex] = !saps.involved || !!saps.station;

        this.setState({
            completedSteps,
            saps,
            stepIndex: this.state.stepIndex + (back ? -1 : 1),
        });
    }

    private onComplainantSubmit = (complainant : IIncidentComplainantCapture, back? : boolean) => {
        const completedSteps = this.state.completedSteps.slice();
        completedSteps[this.state.stepIndex] = !!complainant.name
                                            && !!complainant.surname
                                            && !!complainant.address;

        this.setState({
            completedSteps,
            complainant,
            stepIndex: this.state.stepIndex + (back ? -1 : 1),
        });
    }

    private onIncidentSubmit = (incident : IIncidentCapture, back? : boolean) => {
        const completedSteps = this.state.completedSteps.slice();
        completedSteps[this.state.stepIndex] = !!incident.incidentPlace
                                            && !!incident.incidentType
                                            && !!incident.incidentDescription
                                            && !!incident.incidentDate
                                            && !!incident.incidentTime;

        this.setState({
            completedSteps,
            incident,
            stepIndex: this.state.stepIndex + (back ? -1 : 1),
            suspects: {
                ...this.state.suspects,
                isMotorVehicleAccident: Object.values(incident.incidentCategories).some(n => n.toLowerCase() === 'motor vehicle accident'),
            },
        });
    }

    private onEvidenceSubmit = (evidence : IIncidentEvidence, back? : boolean) => {
        const completedSteps = this.state.completedSteps.slice();
        completedSteps[this.state.stepIndex] = !evidence.hasEvidance || !!evidence.description;
        this.setState({
            completedSteps,
            evidence,
            stepIndex: this.state.stepIndex + (back ? -1 : 1),
        });
    }

    private onSuspectsSubmit = (suspects : IIncidentSuspects, back? : boolean) => {
        const completedSteps = this.state.completedSteps.slice();
        completedSteps[this.state.stepIndex] = !suspects.suspectKnown
                                            || !suspects.suspects.some(n => !n.name || !n.surname || !n.address);

        this.setState({
            completedSteps,
            suspects,
            stepIndex: this.state.stepIndex + (back ? -1 : 1),
        });
    }

    private onCourtCaseSubmit = (courtCase : IIncidentCourtCaseCreate | null, back? : boolean) => {
        const completedSteps = this.state.completedSteps.slice();
        completedSteps[this.state.stepIndex] = true;

        this.setState({
            completedSteps,
            courtCase,
            stepIndex: this.state.stepIndex + (back ? -1 : 0),
        }, back ? undefined : this.create);
    }

    private create = () => {
        const {
            guid,
            generalInfo,
            saps,
            complainant,
            incident,
            evidence,
            suspects,
            completedSteps,
            courtCase,
        } = this.state;

        if (completedSteps.some(n => !n)) {
            return;
        }

        if (
            !generalInfo ||
            !saps ||
            !complainant ||
            !incident ||
            !evidence ||
            !suspects
        ) {
            return;
        }

        IncidentFunctions.create(guid, generalInfo, saps, complainant, incident, evidence, suspects, courtCase).then((result) => {
            if (result != null) {
                this.setState({
                    guid: v4(),
                    stepIndex: 0,
                    // Seed steps complete array
                    completedSteps: this.steps.map(n => false),
                    generalInfo: undefined,
                    saps: undefined,
                    complainant: undefined,
                    incident: undefined,
                    evidence: undefined,
                    suspects: {
                        ...initSuspects,
                        suspectFiles: [...initSuspects.suspectFiles],
                        suspects: [...initSuspects.suspects],
                    },
                });
            }
        });

    }

    public render = () => {
        const { open, stepIndex, generalInfo, saps, complainant, incident, evidence, suspects, completedSteps, courtCase } = this.state;
        const { fullScreen, transition, disabled, isLoading } = this.props;
        return (
            <React.Fragment>
                <Tooltip title='Create'>
                    <div>
                        <StandardFab aria-label='Create' disabled={isLoading || disabled} onClick={this.onCreateClick}>
                            <AddIcon />
                        </StandardFab>
                    </div>
                </Tooltip>
                <Dialog
                        open={open}
                        onClose={this.onClose}
                        aria-labelledby='incident-create-dialog-title'
                        aria-describedby='incident-create-description'
                        fullWidth={this.props.fullWidth}
                        maxWidth={this.props.maxWidth}
                        TransitionComponent={transition}
                        transitionDuration={500}
                        fullScreen={fullScreen}
                    >
                    <AppBar position='static'>
                        <Toolbar>
                            <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant='h6' color='inherit' className='flx1'>
                                Create Incident
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {
                        !isLoading &&
                        <div className='fdc flx1 p5 bcg1 mh0 sbs'>
                            <Stepper className='bcg1' activeStep={stepIndex}>
                                {
                                    this.steps.map((n, i) => (
                                        <Step active={i === stepIndex} key={n.label} completed={completedSteps[i]}>
                                            <StepLabel StepIconComponent={props => (
                                                <div className={`stepper${props.active ? ' active' : ''}${props.completed ? ' completed' : ''}`}>
                                                    <span className={n.icon} />
                                                </div>
                                            )}
                                            >
                                                <span className={`cp fs20`}>
                                                    {n.label}
                                                </span>
                                            </StepLabel>
                                        </Step>
                                    ))
                                }
                            </Stepper>
                            <div className='fdr flx1 ml24 mr24 oys hidescroll'>
                                {
                                    stepIndex === 0 &&
                                    <IncidentGeneralInfoCapture value={generalInfo} onSubmit={this.onGeneralSubmit} />
                                }
                                {
                                    stepIndex === 1 &&
                                    <IncidentSAPS value={saps} onSubmit={this.onSapsSubmit} />
                                }
                                {
                                    stepIndex === 2 &&
                                    <IncidentComplainantCapture value={complainant} onSubmit={this.onComplainantSubmit} />
                                }
                                {
                                    stepIndex === 3 &&
                                    <IncidentCapture group={generalInfo?.group} value={incident} onSubmit={this.onIncidentSubmit} />
                                }
                                {
                                    stepIndex === 4 &&
                                    <IncidentEvidenceCapture value={evidence} onSubmit={this.onEvidenceSubmit} />
                                }
                                {
                                    stepIndex === 5 &&
                                    <IncidentSuspectsCapture value={suspects} onSubmit={this.onSuspectsSubmit} />
                                }
                                {
                                    stepIndex === 6 &&
                                    <IncidentCourtCaseCapture value={courtCase} suspects={suspects} onSubmit={this.onCourtCaseSubmit} />
                                }
                            </div>
                        </div>
                    }
                    {
                        isLoading &&
                        <div className={'fdc hfill aic jcc'}>
                            <div className={'aic jcc'}>
                                <CircularProgress color={'primary'} className={''} />
                            </div>
                        </div>
                    }
                </Dialog>
            </React.Fragment>
        );
    }
}
