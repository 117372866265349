import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { EnumIncidentCourtCaseAccusedBailApplication, IncidentCourtCaseAccusedBailApplication } from '../../../../../@types/model/incident';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import lodash from 'lodash';

interface IIncidentCourtCaseAccusedBailApplicationAutocompleteProps {
    label ?: string;
    value : EnumIncidentCourtCaseAccusedBailApplication | null;
    disabled ?: boolean;
    required ?: boolean;
    onChange : (value : EnumIncidentCourtCaseAccusedBailApplication | null) => void;
}

interface IIncidentCourtCaseAccusedBailApplicationAutocompleteState {}

export default class IncidentCourtCaseAccusedBailApplicationAutocomplete extends React.PureComponent<IIncidentCourtCaseAccusedBailApplicationAutocompleteProps, IIncidentCourtCaseAccusedBailApplicationAutocompleteState> {
    constructor(props : IIncidentCourtCaseAccusedBailApplicationAutocompleteProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null) => {
        this.props.onChange(value?.value ?? null);
    }

    private readonly getValue = (state : IIncidentCourtCaseAccusedBailApplicationAutocompleteState, props : IIncidentCourtCaseAccusedBailApplicationAutocompleteProps) => props.value;
    private readonly getRequired = (state : IIncidentCourtCaseAccusedBailApplicationAutocompleteState, props : IIncidentCourtCaseAccusedBailApplicationAutocompleteProps) => props.required;

    private getAccusedBailApplicationes = createSelector([], () => {
        return lodash
            .map(EnumIncidentCourtCaseAccusedBailApplication, (n) => EnumIncidentCourtCaseAccusedBailApplication[n])
            .map((n) => Number(n))
            .filter((n) => !isNaN(n));
    });

    private readonly getAccusedBailApplicationesForDropdown = createSelector([
        this.getRequired,
        this.getAccusedBailApplicationes,
    ], (
        required,
        AccusedBailApplicationes,
    ) => {
        const dropdownData : Array<{
            label : string;
            value : number | null;
        }> = lodash.map(
            AccusedBailApplicationes,
            (element) => ({
                label: IncidentCourtCaseAccusedBailApplication[element as EnumIncidentCourtCaseAccusedBailApplication],
                value: element,
            }));

        if (!required) {
            dropdownData.unshift({
                label: 'NONE',
                value: null,
            });
        }
        
        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue,
    ], (
        value,
    ) => {
        if (value != null) {
            return {
                label: IncidentCourtCaseAccusedBailApplication[value],
                value : value
            };
        }

        return {
            label: 'None',
            value: null,
        };
    });

    public render = () => {
        const { required, disabled, label } = this.props;

        const dropdownData = this.getAccusedBailApplicationesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <Autocomplete 
                disabled={disabled}
                options={dropdownData}
                value={value}
                getOptionSelected={(option, val) => option.value === val.value}
                getOptionLabel={option => option.label}
                onChange={this.onChange}
                disableClearable={required}
                openOnFocus
                renderInput={params => <TextField error={required && !value} {...params} fullWidth label={label ?? 'Bail Application'} />}  
            />
        );
    }
}
