import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { IDivision } from '../../../../@types/model/division';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MasterDataFunctions from '../../../../store/masterData/functions';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { IRegion } from '../../../../@types/model/region';
import RegionDropdown from '../../custom/dropdowns/RegionDropdown';
import CustomerDivisionDropdown from '../../custom/dropdowns/CustomerDivisionDropdown';
import { ICustomer } from '../../../../@types/model/customer';
import { ILatLng } from '../../../../@types/model/base';
import LocationInput from '../../custom/input/LocationInput';
import ProvinceDropdown from '../../custom/dropdowns/ProvinceDropdown';
import StandardFab from '../../custom/button/StandardFab';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import DivisionTypeDropdown from '../../custom/autocomplete/DivisionTypeMultiDropdown';
import { IDivisionType } from '../../../../@types/model/masterData/divisionType';
import { IDivisionDivisionType } from '../../../../@types/model/masterData/divisionDivisionType';

interface IEditDivisionDialogProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    division? : IDivision;
}

interface IEditDivisionDialogState {
    open : boolean;
    code : string;
    description : string;
    regionId? : number | null;
    province? : string;
    geo : ILatLng;
    isActive : boolean;

    selectedTypes : Array<number>;
}

export default class EditDivisionDialog extends React.Component<IEditDivisionDialogProps, IEditDivisionDialogState> {
    constructor(props : IEditDivisionDialogProps) {
        super(props);
        this.state = {
            code: '',
            description: '',
            open: false,
            isActive: true,
            geo: {
                latitude: -28.7792625,
                longitude: 25.6727135,
            },
            selectedTypes: [],
        };
    }

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    }

    public componentDidUpdate = (prevProps : IEditDivisionDialogProps, prevState : IEditDivisionDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                code: '',
                regionId: undefined,
                selectedTypes: [],
                isActive: true,
            });
        }

        if (!prevState.open && this.state.open && this.props.division) {
            this.setState({
                code: this.props.division.code,
                regionId: this.props.division.regionId,
                isActive: this.props.division.isActive,
                province: this.props.division.province ?? undefined,
                geo: this.props.division.geo ?? {
                    latitude: -28.7792625,
                    longitude: 25.6727135,
                },
                selectedTypes: this.props.division?.divisionTypes?.filter(x => x.isActive).map(x => x.divisionTypeId) ?? [],
            });
        }
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private save = async () => {
        const { code, description, regionId, isActive, province, geo, selectedTypes } = this.state;

        if (!code) return;

        const division = Object.assign({}, this.props.division);
        if (division) {
            division.code = code;
            division.description = description;
            division.regionId = regionId;
            division.isActive = isActive;
            division.province = province ?? null;
            division.geo = geo;
            division.divisionTypes = selectedTypes.slice().map(divisionTypeId => ({
                divisionTypeId,
            } as IDivisionDivisionType));

            const result = await MasterDataFunctions.saveDivision(division);

            if (result) {
                this.onClose();
            }
        }
    }

    private onIsActiveChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            isActive: checked,
        });
    }

    private onRegionChange = (region? : IRegion) => {
        this.setState({
            regionId: region?.id,
        });
    }

    private onProvinceChange = (province? : string) => {
        this.setState({
            province,
        });
    }

    private onDivisionChange = (division? : ICustomer) => {
        if (division) {
            this.setState({
                code: division.code,
                description: division.description,
            });
        }
    }

    private onLocationChange = (geo : ILatLng) => {
        this.setState({
            geo,
        });
    }

    private onLatitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, latitude: parseFloat(event.currentTarget.value) },
        });
    }

    private onLongitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.currentTarget.value && parseFloat(event.currentTarget.value) === null) return;
        this.setState({
            geo: { ...this.state.geo, longitude: parseFloat(event.currentTarget.value) ?? null },
        });
    }

    private onDivisionTypesChange = (value? : Array<IDivisionType>) => {
        this.setState({
            selectedTypes: value?.map(x => x.id) ?? [],
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, division, isLoading } = this.props;
        const { open, code, isActive, regionId, province, geo, selectedTypes } = this.state;
        return (
            <React.Fragment>
                <Tooltip title={!division ? 'Add' : 'Edit'}>
                    <div>
                        {
                            !division &&
                            <StandardFab disabled={isLoading} onClick={this.onEditClick} aria-label='Add'>
                                <AddIcon />
                            </StandardFab>
                        }
                        {
                            division &&
                            <IconButton color='primary' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                                <Icon>edit</Icon>
                            </IconButton>
                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='division-category-edit-dialog-title'
                    aria-describedby='division-category-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {!division ? 'New' : 'Edit'} Division - {!division ? code : division.description}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 ais hfill'>
                            <div className={'fdr flx1 aifs'}>
                                {
                                    !division &&
                                    <div className={'flx1 aic p5 mb10 pr20'}>
                                        <CustomerDivisionDropdown required fullWidth onChange={this.onDivisionChange} value={code} allowNew />
                                    </div>
                                }
                                <div className={'flx1 aic p5 mb10 pr20'}>
                                    <RegionDropdown fullWidth onChange={this.onRegionChange} value={regionId} />
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 aic p5 mb10 pr20'}>
                                    <DivisionTypeDropdown
                                        fullWidth
                                        value={selectedTypes}
                                        onChange={this.onDivisionTypesChange} label='Types'
                                    />
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 aic p5 mb10 pr20'}>
                                    <ProvinceDropdown fullWidth onChange={this.onProvinceChange} value={province} />
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'}>
                                    <FormControl margin='normal'>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color='primary' checked={isActive} onChange={this.onIsActiveChanged}  />
                                            }
                                            label='IsActive?'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdc'}>
                                <div className='fdr'>
                                    <div className={'flx1 ais mb10 pr20'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete='off'
                                                id='lat'
                                                label='Latitude'
                                                value={geo?.latitude ?? null}
                                                onChange={this.onLatitudeChange}
                                                margin='normal'
                                                className={'TextField'}
                                                required
                                                error={!geo?.latitude && !!geo?.longitude}
                                                type='number'
                                            />
                                            {
                                                !geo?.latitude && !!geo?.longitude &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 ais mb10'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete='off'
                                                id='long'
                                                label='Longitude'
                                                value={geo?.longitude}
                                                onChange={this.onLongitudeChange}
                                                margin='normal'
                                                className={'TextField'}
                                                required
                                                error={!!geo?.latitude && !geo?.longitude}
                                                type='number'
                                            />
                                            {
                                                !!geo?.latitude && !geo?.longitude &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='h220'>
                                    <LocationInput geo={geo} onChange={this.onLocationChange} />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}
