import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { EnumIncidentCourtCaseAccusedBailApplication, EnumIncidentCourtCaseAccusedVerdict, IIncident, IIncidentCourtCaseAccused, vehicleOwnershipType } from '../../../../../@types/model/incident';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import IncidentFunctions from '../../../../../store/incident/functions';
import FormHelperText from '@material-ui/core/FormHelperText';
import IncidentCourtCaseAccusedVerdictAutocomplete from '../../../custom/autocomplete/incident/IncidentCourtCaseVerdictDropdown';
import IncidentCourtCaseAccusedBailApplicationAutocomplete from '../../../custom/autocomplete/incident/IncidentCourtCaseBailApplicationAutocomplete';
import { InputAdornment } from '@material-ui/core';

interface IIncidentCourtCaseAccusedEditDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    index : number;
}

interface IIncidentCourtCaseAccusedEditDialogState {
    open : boolean;

    name : string | null;
    surname : string | null;
    representative : string | null;
    verdict : EnumIncidentCourtCaseAccusedVerdict | null;
    sentence : string | null;
    charges : string | null;

    bailApplication : EnumIncidentCourtCaseAccusedBailApplication | null;
    bailAmount : number | null;
    bailConditions : string | null;
    bailRejectReason : string | null;
}

export default class IncidentCourtCaseAccusedEditDialog extends React.Component<IIncidentCourtCaseAccusedEditDialogProps, IIncidentCourtCaseAccusedEditDialogState> {
    constructor(props : IIncidentCourtCaseAccusedEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: null,
            representative: null,
            sentence: null,
            charges: null,
            surname: null,
            verdict: null,
            bailApplication: null,
            bailAmount: null,
            bailConditions: null,
            bailRejectReason: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IIncidentCourtCaseAccusedEditDialogProps, prevState : IIncidentCourtCaseAccusedEditDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                name: null,
                representative: null,
                sentence: null,
                charges: null,
                surname: null,
                verdict: null,
                bailAmount: null,
                bailApplication: null,
                bailConditions: null,
                bailRejectReason: null,
            });
        }
        if (!prevState.open && this.state.open && this.props.incident && this.props.index > -1) {
            const accused = this.props.incident.courtCase?.incidentCourtCaseAccuseds?.at(this.props.index)!;
            this.setState({
                name: !accused.name ? '' : accused.name,
                surname: !accused.surname ? '' : accused.surname,
                representative: !accused.representative ? '' : accused.representative,
                sentence: !accused.sentence ? '' : accused.sentence,
                charges: !accused.charges ? '' : accused.charges,
                verdict: accused.verdict ?? null,
                bailAmount: accused.bailAmount ?? null,
                bailApplication: accused.bailApplication ?? null,
                bailConditions: accused.bailConditions ?? null,
                bailRejectReason: accused.bailRejectReason ?? null,
            });
        }
    }

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private readonly save = async () => {
        const { index } = this.props;
        const {
            name,
            surname,
            representative,
            sentence,
            charges,
            verdict,
            bailAmount,
            bailApplication,
            bailConditions,
            bailRejectReason,
        } = this.state;

        if (bailApplication === EnumIncidentCourtCaseAccusedBailApplication.Granted) {
            if (!bailAmount) return;
            if (!bailConditions) return;
        }

        if (bailApplication === EnumIncidentCourtCaseAccusedBailApplication.NotGranted) {
            if (!bailRejectReason) return;
        }

        const incident = {
            ...this.props.incident,
            courtCase: {
                ...this.props.incident.courtCase!,
                incidentCourtCaseAccuseds: [
                    ...this.props.incident.courtCase?.incidentCourtCaseAccuseds?.slice() ?? [],
                ],
            },
        };

        if (incident && incident.courtCase?.incidentCourtCaseAccuseds) {
            if (index === -1) {
                incident.courtCase?.incidentCourtCaseAccuseds.push({
                    name,
                    surname,
                    representative,
                    sentence,
                    charges,
                    verdict,
                    bailAmount,
                    bailApplication,
                    bailConditions,
                    bailRejectReason,
                } as IIncidentCourtCaseAccused);
            } else {
                const incidentCourtCaseAccused = incident.courtCase?.incidentCourtCaseAccuseds[index];
                incidentCourtCaseAccused.name = name;
                incidentCourtCaseAccused.surname = surname;
                incidentCourtCaseAccused.representative = representative;
                incidentCourtCaseAccused.sentence = sentence;
                incidentCourtCaseAccused.charges = charges;
                incidentCourtCaseAccused.verdict = verdict;
                incidentCourtCaseAccused.bailAmount = bailAmount;
                incidentCourtCaseAccused.bailApplication = bailApplication;
                incidentCourtCaseAccused.bailConditions = bailConditions;
                incidentCourtCaseAccused.bailRejectReason = bailRejectReason;
            }

            const result = await IncidentFunctions.save(incident);

            if (result) {
                this.onClose();
            }
        }
    }

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({
            open: true,
        });
    }

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    }

    private readonly onSurnameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            surname: event.currentTarget.value,
        });
    }

    private readonly onRepresentativeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            representative: event.currentTarget.value,
        });
    }

    private readonly onSentenceChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            sentence: event.currentTarget.value,
        });
    }

    private readonly onChargesChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            charges: event.currentTarget.value,
        });
    }

    private readonly onBailAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            bailAmount: Number(event.currentTarget.value),
        });
    }

    private readonly onBailRejectReasonChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            bailRejectReason: event.currentTarget.value,
        });
    }

    private readonly onBailConditionsChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            bailConditions: event.currentTarget.value,
        });
    }

    private readonly onVerdictChange = (verdict : EnumIncidentCourtCaseAccusedVerdict | null) => {
        this.setState({
            verdict,
        });
    }

    private readonly onBailApplicationChange = (bailApplication : EnumIncidentCourtCaseAccusedBailApplication | null) => {
        this.setState({
            bailApplication,
            bailAmount: null,
            bailConditions: null,
            bailRejectReason: null,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, index } = this.props;
        const {
            open,
            name,
            surname,
            representative,
            sentence,
            charges,
            verdict,
            bailAmount,
            bailApplication,
            bailConditions,
            bailRejectReason,
        } = this.state;
        return (
        <React.Fragment>
            <Tooltip title={index === -1 ? 'Add' : 'Edit'}>
                <div>
                    <IconButton color={index === -1 ? 'secondary' : 'primary'} size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>{index === -1 ? 'add' : 'edit'}</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='accused-info-title'
                aria-describedby='accused-info-description'
                className='hfill'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Accused Info
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 hfill'>
                    <div className='fdc'>
                        <div className='fdr aifs flx1 mb20'>
                            <div className={'flx1 aic pr10'}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='name-input'>Name</InputLabel>
                                    <Input
                                        autoFocus
                                        fullWidth
                                        id='name-input'
                                        value={name ?? ''}
                                        onChange={this.onNameChange}
                                        aria-describedby='name-input-helper-text'
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 aic pl10 pr10'}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='description-input'>Surname</InputLabel>
                                    <Input
                                        fullWidth
                                        id='surname-input'
                                        value={surname ?? ''}
                                        onChange={this.onSurnameChange}
                                        aria-describedby='surname-input-helper-text'
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 aic pl10 pr10'}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='representative-input'>Representative</InputLabel>
                                    <Input
                                        id='representative-input'
                                        value={representative ?? ''}
                                        onChange={this.onRepresentativeChange}
                                        aria-describedby='representative-input-helper-text'
                                        fullWidth
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 aic pl10'}>
                                <FormControl fullWidth>
                                    <IncidentCourtCaseAccusedVerdictAutocomplete
                                        value={verdict}
                                        onChange={this.onVerdictChange}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='fdr aifs flx1 mb20'>
                            <div className={'flx1 aic pr10'}>
                                <FormControl fullWidth>
                                    <IncidentCourtCaseAccusedBailApplicationAutocomplete
                                        value={bailApplication}
                                        onChange={this.onBailApplicationChange}
                                    />
                                </FormControl>
                            </div>
                            {
                                bailApplication === EnumIncidentCourtCaseAccusedBailApplication.Granted &&
                                <div className={'flx1 aic pl10 pr10'}>
                                    <FormControl fullWidth>
                                        <InputLabel required error={!bailAmount} htmlFor='bailAmount-input'>Bail Amount</InputLabel>
                                        <Input
                                            id='bailAmount-input'
                                            value={bailAmount ?? ''}
                                            onChange={this.onBailAmountChange}
                                            aria-describedby='bailAmount-input-helper-text'
                                            fullWidth
                                            type='number'
                                            required
                                            error={!bailAmount}
                                            startAdornment={(
                                                <InputAdornment position='start'>
                                                    R
                                                </InputAdornment>
                                            )}
                                        />
                                        {
                                            !bailAmount &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            }
                            {
                                bailApplication === EnumIncidentCourtCaseAccusedBailApplication.NotGranted &&
                                <div className={'flx1 aic pl10 pr10'}>
                                    <FormControl fullWidth>
                                        <InputLabel required error={!bailRejectReason} htmlFor='bailRejectReason-input'>Bail Reject Reason</InputLabel>
                                        <Input
                                            id='bailRejectReason-input'
                                            value={bailRejectReason ?? ''}
                                            onChange={this.onBailRejectReasonChange}
                                            aria-describedby='bailRejectReason-input-helper-text'
                                            fullWidth
                                            required
                                            error={!bailRejectReason}
                                        />
                                        {
                                            !bailRejectReason &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            }
                        </div>
                        {
                                bailApplication === EnumIncidentCourtCaseAccusedBailApplication.Granted &&
                                <div className='fdr aifs flx1 mb20'>
                                    <div className={'flx1 aic'}>
                                        <FormControl fullWidth>
                                            <InputLabel required error={!bailConditions} htmlFor='bailConditions-input'>Bail Conditions</InputLabel>
                                            <Input
                                                id='bailConditions-input'
                                                value={bailConditions ?? ''}
                                                onChange={this.onBailConditionsChange}
                                                aria-describedby='bailConditions-input-helper-text'
                                                fullWidth
                                                multiline
                                                required
                                                error={!bailConditions}
                                            />
                                            {
                                                !bailConditions &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                        }
                        <div className='fdr aifs flx1 mb20'>
                            <div className={'flx1 aic'}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='charges-input'>Charges</InputLabel>
                                    <Input
                                        id='charges-input'
                                        value={charges ?? ''}
                                        onChange={this.onChargesChange}
                                        aria-describedby='charges-input-helper-text'
                                        fullWidth
                                        multiline
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='fdr aifs flx1 mb20'>
                            <div className={'flx1 aic'}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='sentence-input'>Sentence</InputLabel>
                                    <Input
                                        id='sentence-input'
                                        value={sentence ?? ''}
                                        onChange={this.onSentenceChange}
                                        aria-describedby='sentence-input-helper-text'
                                        fullWidth
                                        multiline
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>);
    }
}
