import React from 'react';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createSelector } from 'reselect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { IAccessPoint } from '../../../../@types/model/access';
import DataFunctions from '../../../../store/data/functions';

interface IAccessPointCodeDropdownProps {
    value? : IAccessPoint;

    onChange : (value? : IAccessPoint) => void;

    accessPoints : Array<IAccessPoint>;

    required? : boolean;
    autoFocus? : boolean;

    width? : string | number;
    fullWidth? : boolean;
    isLoading : boolean;
    label? : string;
}

interface IAccessPointCodeDropdownState {
}

class AccessPointCodeDropdown extends React.Component<IAccessPointCodeDropdownProps, IAccessPointCodeDropdownState> {
    constructor(props : IAccessPointCodeDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        await DataFunctions.getAllAccessPoints();
    }

    private onChanged = (event : React.ChangeEvent<HTMLSelectElement>, value : {
        label : string,
        value : string,
    }) => {
        const result = this.props.accessPoints.find(n => n.code === value?.value);

        this.props.onChange(result);
    }

    private getData = (state : IAccessPointCodeDropdownState, props : IAccessPointCodeDropdownProps) => props.accessPoints;
    private getValue = (state : IAccessPointCodeDropdownState, props : IAccessPointCodeDropdownProps) => props.value;
    
    private getAccessPointDropdowns = createSelector([
        this.getData,
    ], (accessPoints) => {
        return accessPoints.filter(x => x.isActive && x.isControlled).map(x => ({ label: x.code.toTitleCase(), value: x.code }));
    });

    private getSelectedValue = createSelector([
        this.getValue,
    ], (value) => {
        if (value) {
            return {
                label: value.code.toTitleCase(),
                value: value.code,
            };
        }
        return null;
    });

    public render = () => {
        const {  } = this.state;
        const { required, isLoading, autoFocus, width, fullWidth, label } = this.props;
        const accesPointDropdowns = this.getAccessPointDropdowns(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required} >
                <Autocomplete
                    id='access_point_select'
                    options={accesPointDropdowns}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChanged}
                    disableClearable={required}
                    openOnFocus
                    style={{ width: width ? width : undefined }}
                     renderInput={params => <TextField autoFocus={autoFocus} {...params}
                        fullWidth={fullWidth} label={label ?? 'Gate Code'}
                        error={required && !value}
                        InputLabelProps={{
                            className: 'fs16 lh21 cpla lsp024',
                        }}
                    />}
                />
                    {
                        required && !value &&
                        <FormHelperText error>Required</FormHelperText>
                    }
                        {
                        isLoading &&
                        <LinearProgress className='wfill posb0 posa' />
                    }
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        accessPoints: state.data.accessPoints,
        isLoading: state.data.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(AccessPointCodeDropdown);
