import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { IIncident } from '../../../../@types/model/incident';
import { Button, DialogActions, Typography } from '@material-ui/core';
import IncidentFunctions from '../../../../store/incident/functions';

interface IIncidentApprovalDialogProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value : IIncident;

    title : string;

    disapprove? : boolean;

    isLoading : boolean;
}

interface IIncidentApprovalDialogState {
    open : boolean;

}

export default class IncidentApprovalDialog extends React.PureComponent<IIncidentApprovalDialogProps, IIncidentApprovalDialogState> {
    constructor(props : IIncidentApprovalDialogProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    public onClick = () => {
        this.setState({
            open: true,
        });
    }

    public onClose = () => {
        this.setState({
            open: false,
        });
    }

    private onOkClick = () => {
        IncidentFunctions.setApproval([this.props.value.guid], !this.props.disapprove);
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, title, disapprove, value, isLoading } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment>
                <Tooltip title={disapprove ? 'Disapprove' : ''}>
                    <div>
                        <IconButton color='primary' onClick={this.onClick} aria-label='tooltip'>
                            <Icon className={disapprove ? 'cpr' : 'cpd'}>{disapprove ? 'close' : 'check'}</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='incident-approve-dialog-title'
                    aria-describedby='incident-approve-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h6' color='inherit' className=''>
                                {title} - {value.number}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill'>
                        <Typography color='inherit' className=''>
                            Are you sure you want to {disapprove ? 'disapprove' : 'approve'} incident?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={this.onOkClick} type='button' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}
