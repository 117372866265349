import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { IIncident, IIncidentSuspect, vehicleOwnershipType } from '../../../../../@types/model/incident';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import lodash from 'lodash';
import IncidentFunctions from '../../../../../store/incident/functions';
import FormHelperText from '@material-ui/core/FormHelperText';
import SpokenLanguageDropdown from '../../../custom/dropdowns/SpokenLanguageDropdown';
import VehicleOwnershipDropdown from '../../../custom/dropdowns/VehicleOwnershipDropdown';
import MultiFileDropZone from '../../../custom/file/MultiFileDropZone';
import { IFileUpload } from '../../../../../@types/viewmodel/file';
import FileFunctions from '../../../../../store/file/functions';
import { EnumFileCategory } from '../../../../../@types/model/base';

interface IIncidentSuspectEditDialogProps {
    incident : IIncident;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    index : number;
}

interface IIncidentSuspectEditDialogState {
    open : boolean;
    isMotorVehicleAccident : boolean;

    name : string;
    surname : string;
    workplace : string;
    employeeNr : string;
    address : string;
    tel : string;
    cel : string;
    language : string;
    vehicleOwnership : vehicleOwnershipType | null;
    vehicleRegistrationNumber : string | null;
    vehicleMake : string | null;
    vehicleColour : string | null;
    insuranceCompany : string | null;

    incidentFiles : Array<IFileUpload>;
}

export default class IncidentSuspectEditDialog extends React.Component<IIncidentSuspectEditDialogProps, IIncidentSuspectEditDialogState> {
    constructor(props : IIncidentSuspectEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            surname: '',
            workplace: '',
            employeeNr: '',
            address: '',
            tel: '',
            cel: '',
            language: '',
            vehicleColour: '',
            vehicleMake: '',
            insuranceCompany: '',
            vehicleOwnership: null,
            vehicleRegistrationNumber: '',
            isMotorVehicleAccident: false,
            incidentFiles: [],
        };
    }

    public componentDidUpdate = (prevProps : IIncidentSuspectEditDialogProps, prevState : IIncidentSuspectEditDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                name: '',
                surname: '',
                workplace: '',
                employeeNr: '',
                address: '',
                tel: '',
                cel: '',
                language: '',
                vehicleColour: '',
                vehicleMake: '',
                insuranceCompany: '',
                vehicleOwnership: null,
                vehicleRegistrationNumber: '',
                isMotorVehicleAccident : this.props.incident.incidentCategories.some(n => n.categoryName.toLowerCase() === 'motor vehicle accident'),
            });
        }
        if (!prevState.open && this.state.open && this.props.incident && this.props.index > -1) {
            const suspect = this.props.incident.suspects[this.props.index];
            this.setState({
                name: !suspect.name ? '' : suspect.name,
                surname: !suspect.surname ? '' : suspect.surname,
                workplace: !suspect.workplace ? '' : suspect.workplace,
                employeeNr: !suspect.employeeNr ? '' : suspect.employeeNr,
                address: !suspect.address ? '' : suspect.address,
                tel: !suspect.tel ? '' : suspect.tel,
                cel: !suspect.cel ? '' : suspect.cel,
                language: !suspect.language ? '' : suspect.language,
                vehicleColour: !suspect.vehicleColour ? '' : suspect.vehicleColour,
                vehicleMake: !suspect.vehicleMake ? '' : suspect.vehicleMake,
                insuranceCompany: !suspect.insuranceCompany ? '' : suspect.insuranceCompany,
                vehicleOwnership: !suspect.vehicleOwnership ? null : suspect.vehicleOwnership,
                vehicleRegistrationNumber: !suspect.vehicleRegistrationNumber ? '' : suspect.vehicleRegistrationNumber,
                isMotorVehicleAccident : this.props.incident.incidentCategories.some(n => n.categoryName.toLowerCase() === 'motor vehicle accident'),
            });
        }
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    }

    private save = async () => {
        const { index } = this.props;
        const { name, surname, workplace, employeeNr, address, tel, cel, language,
            vehicleColour, vehicleMake, vehicleOwnership, vehicleRegistrationNumber, insuranceCompany, incidentFiles } = this.state;

        const incident = lodash.cloneDeep(this.props.incident);

        if (incident) {
            if (index === -1) {
                incident.suspects.push({
                    name,
                    surname,
                    workplace,
                    employeeNr,
                    address,
                    tel,
                    cel,
                    language,
                    isActive: true,
                    vehicleColour,
                    vehicleMake,
                    vehicleOwnership,
                    vehicleRegistrationNumber,
                    insuranceCompany,
                } as IIncidentSuspect);
            } else {
                const suspect = incident.suspects[index];
                suspect.address = address;
                suspect.name = name;
                suspect.surname = surname;
                suspect.workplace = workplace;
                suspect.employeeNr = employeeNr;
                suspect.tel = tel;
                suspect.cel = cel;
                suspect.language = language;
                suspect.vehicleColour = vehicleColour;
                suspect.vehicleMake = vehicleMake;
                suspect.vehicleOwnership = vehicleOwnership;
                suspect.vehicleRegistrationNumber = vehicleRegistrationNumber;
                suspect.insuranceCompany = insuranceCompany;
            }
            const result = await IncidentFunctions.save(incident, incidentFiles, EnumFileCategory.Suspect);

            if (result) {
                this.onClose();
            }
        }
    }

    private onEditClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({
            open: true,
        });
    }

    private onNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    }

    private onSurnameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            surname: event.currentTarget.value,
        });
    }

    private onEmployeeNrChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            employeeNr: event.currentTarget.value,
        });
    }

    private onAddressChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            address: event.currentTarget.value,
        });
    }

    private onTelChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            tel: event.currentTarget.value,
        });
    }

    private onCelChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            cel: event.currentTarget.value,
        });
    }

    private onWorkplaceChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            workplace: event.currentTarget.value,
        });
    }

    private onLanguageChange = (language : string) => {
        this.setState({
            language,
        });
    }

    private onVehicleOwnershipChange = (vehicleOwnership : vehicleOwnershipType) => {
        this.setState({
            vehicleOwnership,
        });
    }

    private onVehicleRegistrationNumberChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            vehicleRegistrationNumber: event.currentTarget.value,
        });
    }

    private onVehicleMakeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            vehicleMake: event.currentTarget.value,
        });
    }

    private onVehicleColourChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            vehicleColour: event.currentTarget.value,
        });
    }

    private onInsuranceCompanyChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            insuranceCompany: event.currentTarget.value,
        });
    }

    private onFileSelected = (file : IFileUpload) => {
        const incidentFiles = this.state.incidentFiles.slice();

        const index = incidentFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            incidentFiles.splice(index, 1, file);
        } else {
            incidentFiles.push(file);
        }

        this.setState({
            incidentFiles,
        });
    }

    private onFileRemove = (file : IFileUpload) => {
        const incidentFiles = this.state.incidentFiles.slice();

        const index = incidentFiles.findIndex(n => n.name === file.name);

        if (index > -1) {
            incidentFiles.splice(index, 1);
        }

        this.setState({
            incidentFiles,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, index } = this.props;
        const { open, name, surname, workplace, employeeNr, address, tel, cel, language,
            vehicleColour, vehicleMake, vehicleOwnership, vehicleRegistrationNumber, insuranceCompany, incidentFiles } = this.state;
        return (
        <React.Fragment>
            <Tooltip title={index === -1 ? 'Add' : 'Edit'}>
                <div>
                    <IconButton color={index === -1 ? 'secondary' : 'primary'} size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                        <Icon>{index === -1 ? 'add' : 'edit'}</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='suspect-info-title'
                aria-describedby='suspect-info-description'
                className='hfill'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Suspect Info
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 hfill'>
                    <div className='fdc'>
                        <div className='fdr aifs flx1'>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                    <InputLabel required error={!name} htmlFor='description-input'>Name</InputLabel>
                                    <Input
                                        autoFocus
                                        fullWidth
                                        id='name-input'
                                        value={name}
                                        onChange={this.onNameChange}
                                        aria-describedby='name-input-helper-text'
                                        required
                                        error={!name}
                                    />
                                    {
                                        !name &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                    <InputLabel required error={!surname} htmlFor='description-input'>Surname</InputLabel>
                                    <Input
                                         fullWidth
                                        id='surname-input'
                                        value={surname}
                                        onChange={this.onSurnameChange}
                                        aria-describedby='surname-input-helper-text'
                                        required
                                        error={!surname}
                                    />
                                    {
                                        !surname &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='employeeNr-input'>Employee Nr/ ID Nr</InputLabel>
                                    <Input
                                        fullWidth
                                        id='employeeNr-input'
                                        value={employeeNr}
                                        onChange={this.onEmployeeNrChange}
                                        aria-describedby='employeeNr-input-helper-text'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='fdr aifs flx1'>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='workplace-input'>Workplace</InputLabel>
                                    <Input
                                        fullWidth
                                        id='workplace-input'
                                        value={workplace}
                                        onChange={this.onWorkplaceChange}
                                        aria-describedby='workplace-input-helper-text'
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='tel-input'>Tel</InputLabel>
                                    <Input
                                        inputProps={{
                                            pattern: '[0-9]{10}',
                                        }}
                                        placeholder='0121231234'
                                        fullWidth
                                        id='tel-input'
                                        value={tel}
                                        onChange={this.onTelChange}
                                        aria-describedby='tel-input-helper-text'
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='cel-input'>Cel</InputLabel>
                                    <Input
                                        inputProps={{
                                            pattern: '[0-9]{10}',
                                        }}
                                        placeholder='0121231234' fullWidth
                                        id='cel-input'
                                        value={cel}
                                        onChange={this.onCelChange}
                                        aria-describedby='cel-input-helper-text'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='fdr aifs flx1'>
                            <div className={'flx1 aifs p5 mb10'} style={{ marginTop: '16px' }}>
                                <FormControl fullWidth>
                                    <InputLabel required error={!address} htmlFor='address-input'>Address</InputLabel>
                                    <Input
                                        id='address-input'
                                        value={address}
                                        onChange={this.onAddressChange}
                                        aria-describedby='address-input-helper-text'
                                        required
                                        error={!address}
                                        multiline
                                        maxRows='5'
                                    />
                                    {
                                        !address &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div className={'flx1 aifs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                <SpokenLanguageDropdown fullWidth value={language} onChange={this.onLanguageChange} required />
                            </div>
                            <div className={'flx1 aifs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                {
                                    this.state.isMotorVehicleAccident &&
                                    <VehicleOwnershipDropdown fullWidth value={vehicleOwnership} onChange={this.onVehicleOwnershipChange} required />
                                }
                            </div>
                        </div>
                        <div className='fdr aifs flx1'>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                {
                                    vehicleOwnership &&
                                    <FormControl fullWidth>
                                        <InputLabel required error={!vehicleRegistrationNumber} htmlFor='vehicleRegistrationNumber-input'>
                                            {
                                                vehicleOwnership === 'Company' &&
                                                'Fleet Nr'
                                            }
                                            {
                                                vehicleOwnership === 'Private' &&
                                                'Vehicle Registration Nr'
                                            }
                                        </InputLabel>
                                        <Input
                                            fullWidth
                                            className=''
                                            id='vehicleRegistrationNumber-input'
                                            value={vehicleRegistrationNumber}
                                            onChange={this.onVehicleRegistrationNumberChange}
                                            aria-describedby='vehicleRegistrationNumber-input-helper-text'
                                            required
                                            error={!vehicleRegistrationNumber}
                                        />
                                        {
                                            !vehicleRegistrationNumber &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                }
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                {
                                    vehicleOwnership === 'Private' &&
                                    <FormControl fullWidth>
                                        <InputLabel required error={!vehicleMake} htmlFor='vehicleMake-input'>
                                            Vehicle Make
                                        </InputLabel>
                                        <Input
                                            fullWidth
                                            className=''
                                            id='vehicleMake-input'
                                            value={vehicleMake}
                                            onChange={this.onVehicleMakeChange}
                                            aria-describedby='vehicleMake-input-helper-text'
                                            required
                                            error={!vehicleMake}
                                        />
                                        {
                                            !vehicleMake &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                }
                            </div>
                            <div className={'flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                {
                                    vehicleOwnership === 'Private' &&
                                    <FormControl fullWidth>
                                        <InputLabel required error={!vehicleColour} htmlFor='vehicleColour-input'>
                                            Vehicle Colour
                                        </InputLabel>
                                        <Input
                                            className=''
                                            id='vehicleColour-input'
                                            value={vehicleColour}
                                            onChange={this.onVehicleColourChange}
                                            aria-describedby='vehicleColour-input-helper-text'
                                            required
                                            error={!vehicleColour}
                                            fullWidth
                                        />
                                        {
                                            !vehicleColour &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                }
                            </div>
                        </div>
                        <div className='fdr aifs flx1'>
                            <div className={'flx1 aifs p5 mb10 flx2'} style={{ marginTop: '16px' }}>
                                {
                                    vehicleOwnership === 'Private' &&
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor='insuranceCompany-input'>
                                            Insurance Company
                                        </InputLabel>
                                        <Input
                                            className=''
                                            id='insuranceCompany-input'
                                            value={insuranceCompany}
                                            onChange={this.onInsuranceCompanyChange}
                                            aria-describedby='insuranceCompany-input-helper-text'
                                            fullWidth
                                        />
                                    </FormControl>
                                }
                            </div>
                            <div className={'flx1 aifs p5 mb10 flx2'} style={{ marginTop: '16px' }}>
                            </div>
                            <div className={'flx1 aifs p5 mb10 flx2'} style={{ marginTop: '16px' }}>
                            </div>
                        </div>
                        <div className='flx1 fdr aifs'>
                            <div className={'flx1'}>
                                <MultiFileDropZone accept='audio/*,video/*,image/*,application/pdf' files={incidentFiles}
                                onFileSelected={this.onFileSelected} onFileRemoved={this.onFileRemove} />
                            </div>
                        </div>
                    </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading || (!name || !surname || !address)} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>);
    }
}
