import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

interface IImageThumbnailProps {
    title? : string;
    url : string;
    thumbUrl : string;

    className? : string;
    width? : string;

    onOpenFileCarousel? : () => void;
}

interface IImageThumbnailState {
}

class ImageThumbnail extends React.Component<IImageThumbnailProps, IImageThumbnailState> {
    public image : HTMLImageElement;

    constructor(props : IImageThumbnailProps) {
        super(props);

        this.state = {
        };
    }

    public render() {
        const { className = 'w100 h100', width = 'w100' } = this.props;
        return (
            <Card className={`fdc jcc aic m10 ${width}`}>
                <CardActionArea onClick={this.props.onOpenFileCarousel}>
                    <CardMedia component='img' className={className} image={this.props.thumbUrl} />
                    {
                        this.props.title &&
                        <CardContent>
                            <Typography variant='subtitle1' align='center'>
                                {this.props.title}
                            </Typography>
                        </CardContent>
                    }
                </CardActionArea>
            </Card>
        );
    }
}

export default ImageThumbnail;
